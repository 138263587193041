// import React from 'react'


import React, { useState, useEffect, useRef } from "react";
import { ToastsStore } from "react-toasts";
import Layout from '../../components/global/layout'
import { useSelector } from 'react-redux';
import { Link, useHistory, useParams } from "react-router-dom";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import methodModel from "../../methods/methods";
import stateModel from "../../models/states.model";
import { planType } from "../../models/type.model";
import "./style.scss"
import ApiKey from "../../methods/ApiKey";

const PlanView = () => {
  const [features, setFeatures] = useState([])
  const [currencys, setCurrencys] = useState([])

  const defaultvalue = () => {
    let keys = { ...planType }
    Object.keys(planType).map(itm => {
      keys[itm] = ''
    })
    keys.status = 'active'
    return keys
  }
  const { id, copy } = useParams()
  const [form, setform] = useState(planType);
  const [checkedItems, setCheckedItems] = useState([]);
  const [startIndex, setStartIndex] = useState(-1);
  const [enterIndex, setEnterIndex] = useState(-1);
  const [selectedItem, setSelectedItem] = useState('');
  const [pricing, setPricing] = useState([]);
  const history = useHistory()
  const [submitted, setSubmitted] = useState(false)
  const user = useSelector((state) => state.user);
  const dragItem = useRef();
  const dragItems = useRef();
  const dragOverItem = useRef();
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([])
  let fetrs = [];
  useEffect(() => {
    setform({ ...form, feature: fetrs })

  }, [])
  const formValidation = [
    // { key: 'feature', required: true },
    { key: 'status', required: true },
    { key: 'recommended', required: true },
  ]
  const getData = (p = {}) => {
    setLoader(true)

    ApiClient.get('features', { status: 'active' }).then(res => {
      if (res.success) {
        const fltr = res?.data?.filter((itm) => itm?.status == 'active')
        setData(fltr)

      }
      setLoader(false)
    })
    if (id) {
      ApiClient.get('plan', { id: id }).then((res) => {
        if (res.success) {
          setform(res?.data)
        }
      })
    }

  }

  useEffect(() => {
    getData()
  }, [])
 
  const handleSubmit = (e) => {
    e.preventDefault()
    setSubmitted(true)


    let method = 'post'
    let url = 'plan'
    let value = {
      name: form.name, price: form.price, id: form.id, features: fetrs
    }

    if (value.id) {
      method = 'put'
      url = 'plan'
      delete value.password
    } else {
      delete value.id
    }

    setLoader(true)
    ApiClient.allApi(url, value, method).then(res => {
      if (res.success) {
        ToastsStore.success(res.message)
        // document.getElementById('closeuserModal').click()
        // modalClosed()
        history.goBack()
      }

      setLoader(false)
    })
  }




  return <>
    <Layout>

      <form onSubmit={handleSubmit}>
        <div className='text-right pb-3'>
          <Link to="/subscription/plans"><i className="fa fa-arrow-left" title='Back' aria-hidden="true"></i></Link>
        </div>

        <div className="pprofile1">

          <div className="d-flex justify-content-between">
            <h3 className="ViewUser border-none mb-3">{form && form.id && copy == 'false' ? 'Edit' : 'View'} Plan</h3>
            <div className=' '>
              {
                user.role == 'admin' || permissions?.user_edit ? <>
                  <a className="linkclass mx-2" title="Edit" onClick={() => history.push('/subscription/editplan/' + form
                    .id)}><i className="fa fa-pen  mr-2"></i>Edit</a>
                </> : null
              }
              {/* {
              user?.role == 'admin' || permissions.user_delete ? <>
                <a className="linkclass mx-2" title="Delete" onClick={() => deleteItem(data.id)}><i className="fa fa-trash"></i></a>

              </> : null} */}
            </div>
          </div>
          {
            loader ? <div className="text-center py-4">
              <img src="/assets/img/loader.gif" className="pageLoader" />
            </div> :

              <div className="form-row">
                <div className="col-md-6 mb-3">
                  <label>Name<span className="star">*</span></label>
                  <input
                    type="text"
                    className="form-control"
                    value={form.name}
                    disabled
                    onChange={e => {
                      setform({ ...form, name: e.target.value })
                      console.log(form)
                    }}
                    required
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <label>Price<span className="star">*</span></label>
                  <input
                    type="number"
                    className="form-control"
                    value={form.price}
                    disabled
                    onChange={e => {
                      setform({ ...form, price: e.target.value })
                      console.log(form)
                    }}
                    required
                  />
                </div>
                <div className="col-md-6 mb-3 d-flex">
                  <label>Image<span className="star">*</span></label>
                 <img style={{width:'19%'}} src={`${ApiKey.api}images/users/` + form?.image} alt="" />
                </div>
                <div className="col-md-12 mb-3">
                  <hr className="borderHr" />
                  <h5 className="monthTerm">Features<span className="star">*</span></h5>
                  {
                    form?.features?.map((itm) => {
                      return (


                        <div className="row">
                          {
                            itm?.isChecked ?
                              <label className="ml-3" >{itm?.name}</label> : null
                          }


                        </div>
                      )
                    })
                  }
                </div>
                <div className="col-md-6 mb-3">
                  <label>Description<span className="star">*</span></label>
                  <p>{form?.description}</p>
                </div>


              </div>
          }
        </div>
      </form>
    </Layout>
  </>
}

export default PlanView