import React, { useEffect, useState } from 'react'
import Layout from '../../components/global/layout'
import DateRangePicker from 'react-bootstrap-daterangepicker';
// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:
// import 'bootstrap/dist/css/bootstrap.css';
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import ApiClient from '../../methods/api/apiClient'
import { useSelector } from 'react-redux'
import { ToastsStore } from 'react-toasts'
import Swal from 'sweetalert2'
import methodModel from '../../methods/methods'
import Pagination from "react-pagination-js";
import dateFormat, { masks } from "dateformat";
import DatePicker from "react-datepicker";

function ReschduleAppoint() {
    const [loaging, setLoader] = useState(true)
    const user = useSelector((state) => state.user)
    const Searchstate = useSelector((state) => state.search)
    const [form, setform] = useState({ date: '', time: '', id: '' })
    const [sort, setsort] = useState({ name: 'name asc', createdAt: '', updatedAt: '', meeting_status: '', date: 'date asc', time: '', category: '' })
    const [formData, setFormData] = useState({ email: '', ic_number: '' })
    const [submitted, setSubmitted] = useState(false)
    const [inviteForm, setInviteForm] = useState({ email: '' })
    const [emailErr, setEmailErr] = useState('')
    const [icErr, setIcErr] = useState('')
    const history = useHistory()
    const [data, setData] = useState([])
    const [filters, setFilter] = useState({ page: 1, count: 7, search: '', sortBy: 'date asc', meeting_status: '', start_date: "", end_date: "", passed: false, upcoming: false, isDeleted: false })
    const [tableCols, setTableCols] = useState([])
    const [total, setTotal] = useState()
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    // const GetCode = (e) => {
    //     window.open(`https://zoom.us/oauth/authorize?response_type=code&client_id=VA3DVHVCSie6QIooYnQA&redirect_uri=https://therapyadmin.jcsoftwaresolution.in/recent`)
    // }

    const GetCode = (e) => {
        window.open(`https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=683567546759-lsa6ijcmcotg05r1rrt3b38dumi8tebc.apps.googleusercontent.com&redirect_uri=https://admin.incogtherapy.com/recent&scope=https://www.googleapis.com/auth/calendar.events&access_type=offline`)
    }

    const pageChange = (e) => {
        setFilter({ ...filters, page: e })
        getData({ page: e })
    }
    const getData = (p = {}) => {
        setLoader(true)
        let filter = { ...filters, ...p }
        ApiClient.get('admin/reschedulelist', filter).then(res => {
            if (res.success) {
                setData(res.data)
                setTotal(res.total)
            }
            setLoader(false)
        })


    }

    useEffect(() => {
        if (user && user.loggedIn) {
            setFilter({ ...filters, search: Searchstate.data })
            getData({ search: Searchstate.data, page: 1 })
        }
    }, [Searchstate])
    // const getData = () => {
    //     setLoader(true)
    //     ApiClient.get('features?page=1&count=10&search&sortBy&meeting_status&role_type=patient',).then((res) => {
    //         if (res.success) {
    //             setData(res.data)
    //             setLoader(false)
    //         }
    //     })
    // }
    useEffect(() => {
        getData()
    }, [])

    const DateRange = (start, end) => {
        console.log(start)
        getData({ start_date: dateFormat(start, "yyyy-mm-dd"), end_date: dateFormat(end, "yyyy-mm-dd"), up: false, passed: false, meeting_status: '' })
    }

    const reset = () => {
        let filter = {
            status: '',
            count: 10,
            search: '',
            page: 1,
            start_date: "",
            end_date: "", upcoming: false, passed: false
        }
        setDateRange([null, null])
        setFilter({ ...filters, ...filter })
        getData({ ...filter })
    }


    const modalClosed = () => {
        setFilter({ ...filters, page: 1 })
        getData({ page: 1 })
    }
    const timeSlots = [
        '09-10 AM',
        '10-11 AM',
        '11-12 PM',
        '12-01 PM',
        '14-15 PM',
        '15-16 PM',
        '16-17 PM',
        '18-19 PM',
    ];
    const DeleteFeature = (id) => {

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                // loader(true)
                ApiClient.delete('delete', { model: 'features', id: id }).then((res) => {
                    if (res.success) {
                        getData()
                        ToastsStore.success(res.message)
                        setLoader(false)
                    }
                    // loader(false)
                })
            }
        })
    }




    const openModal = (itm = {}) => {
        setSubmitted(false)
        console.log(itm)
        if (itm.id) {
            setform({ ...form, name: form.name, role_type: form.role_type })
        }
        setEmailErr('')
        setIcErr('')
        setform({ role_type: '', name: '', ...itm })
        setFormData({ ...formData, ...itm })

        document.getElementById("openuserModal").click()
    }



    const sortDataTable1 = (e) => {
        console.log(e)
        setFilter({ ...filters, sortBy: e })
        if (e == 'name asc' || e == 'name desc') setsort({ ...sort, name: e })
        if (e == 'email asc' || e == 'email desc') setsort({ ...sort, email: e })
        if (e == 'date asc' || e == 'date desc') setsort({ ...sort, date: e })
        if (e == 'updatedAt asc' || e == 'updatedAt desc') setsort({ ...sort, updatedAt: e })
        // if (e == 'approved' || e == 'pending') setsort({ ...sort, meeting_status: e })
        getData({ sortBy: e })
    }
    const sortDataTable2 = (e) => {
        setFilter({ ...filters, meeting_status: e })

        // if (e == 'approved' || e == 'pending') setsort({ ...sort, meeting_status: e })
        getData({ meeting_status: e })
    }
    const sortDataTable4 = (e) => {
        setFilter({ ...filters, sortBy: e })

        // if (e == 'approved' || e == 'pending') setsort({ ...sort, meeting_status: e })
        getData({ sortBy: e })
    }

    const sortDataTable3 = (e) => {
        setFilter({ ...filters, category: e })

        if (e == 'silver' || e == 'gold' || e == 'platinum') setsort({ ...sort, category: e })
        getData({ category: e })
    }

    const GetUpcoming = (e = {}) => {
        getData(e)
        setFilter({ ...filters, upcoming: e.upcoming, passed: e.passed })
        console.log(filters)
    }

    const ChangeStatus = (id, meeting_status) => {
        if (window.confirm(`Do you want to ${meeting_status == 'accept' ? 'accept' : 'reject'}`)) {
            ApiClient.put('appointment', { id: id, meeting_status: meeting_status }).then((res) => {
                if (res.success) {
                    getData()
                }
            })
        }
    }
    const handleCountChange = (count) => {
        setFilter({ ...filters, count: count, page: 1 });
        getData({ count: count, page: 1 });
    };


    const Terminate = (id) => {
        ApiClient.put('reschedule', { id: id, meeting_status: "terminated" }).then((res) => {
            if (res.success) {
                ToastsStore.success(res.message)
                getData()
            }
        })
    }

    return (
        <>
            <Layout>
                <div className="d-flex justify-content-between mb-3 main_title">
                    <h3 className="hedding">
                        Reschduled  Appoinments
                    </h3>

                    <article className="d-flex">
                        {
                            filters ?
                                <button className="btn btn-primary  mr-2" type="button" id="" data-toggle="" aria-haspopup="true" aria-expanded="false" onClick={() => {
                                    reset()
                                }}>
                                    Reset
                                </button> : null
                        }

                        <div className='mr-2'>
                            <DatePicker
                                //    showIcon
                                className="form-control"
                                monthsShown={2}
                                shouldCloseOnSelect={true}
                                selectsRange={true}
                                placeholderText="Select Date Range"
                                startDate={startDate}
                                endDate={endDate}
                                onChange={(update) => {
                                    setDateRange([update[0], update[1]])
                                    getData({ start_date: dateFormat(update[0], "yyyy-mm-dd"), end_date: dateFormat(update[1], "yyyy-mm-dd"), up: false, passed: false, status: '' })
                                }}
                                // isClearable
                                // minDate={new Date()}
                                withPortal
                                dateFormat={"dd/MM/yyyy"}
                            />
                        </div>

                        {/* <DateRangePicker
                            onCallback={(start, end, label) => {
                                DateRange(start?._d, end?._d)
                                setFilter({ ...filters, start_date: dateFormat(start?._d, "yyyy-mm-dd"), end_date: dateFormat(end?._d, 'yyyy-mm-dd') })

                            }}
                            initialSettings={{ startDate: Date.now(), endDate: Date.now() }}
                        >
                            <button className="btn btn-primary dropdown-toggle mr-2" type="button" id="dropdownMenuColumns" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Date Range: {""} {filters?.start_date} {filters?.start_date ? "To" : null} {filters?.end_date}
                            </button>
                        </DateRangePicker> */}

                        <div className="dropdown addDropdown mr-2">
                            <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuColumns" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {filters?.passed ? "Passed" : filters?.upcoming ? "Upcoming" : 'All'} {" "}
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuColumns">

                                <a className='dropdown-item' onClick={() => {
                                    setFilter({ ...filters, upcoming: true, passed: false })
                                    getData({ ...filters, upcoming: true, passed: false })
                                }}>Upcoming</a>
                                <a className='dropdown-item' onClick={() => {
                                    setFilter({ ...filters, passed: true, upcoming: false })
                                    getData({ ...filters, passed: true, upcoming: false })
                                }}>Passed</a>
                            </div>
                        </div>

                        <div className="dropdown addDropdown mr-2">
                            <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuColumns" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Status: {" "}
                                {/* {
                                    !sort.meeting_status ? 'All' : `${sort.meeting_status == 'accept' ? 'accepted' : sort.meeting_status == 'reject' ? "rejected" : "pending"}`
                                } */}
                                {
                                    !sort.meeting_status ? "All" : methodModel.capitalizeName(filters.meeting_status)
                                }
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuColumns">
                                <a className='dropdown-item' onClick={() => sortDataTable2(sort.meeting_status = '')}>All</a>
                                <a className='dropdown-item' onClick={() => sortDataTable2(sort.meeting_status = 'done')}>Done</a>
                                <a className='dropdown-item' onClick={() => sortDataTable2(sort.meeting_status = 'terminated')}>Terminated</a>
                                <a className='dropdown-item' onClick={() => sortDataTable2(sort.meeting_status = 'pending')}>Pending</a>
                                {/* <a className='dropdown-item' onClick={() => {
                                    setFilter({ ...filters, meeting_status: 'Deleted' })
                                    getData({ isDeleted: true, meeting_status: 'Deleted' })
                                }}>Deleted</a> */}

                            </div>
                        </div>
                        {/* <div className="dropdown addDropdown mr-2">
                            <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuColumns" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Time Slot:
                                {
                                    !sort.time ? 'All' : `${sort.time}`   
                                }
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuColumns">
                            <a className='dropdown-item' onClick={() => {
                                                sortDataTable4(sort.time = '')
                                            setsort({...sort,time:''})}}>All</a>
                                {
                                    timeSlots?.map((itm)=>{
                                        return(
                                            <>
                                           
                                            <a className='dropdown-item' onClick={() => {
                                                sortDataTable4(sort.time = itm)
                                                setsort({...sort,time:itm})}}>{itm}</a>
                                                </>
                                        )
                                    })
                                }
                               
                                
                            </div>
                        </div> */}
                    </article>


                </div>
                <div className="table-responsive">
                    <table className="table mb-0">
                        <thead className="theadclss">
                            <tr className="tblclas">

                                <th scope='col'>Name
                                    <i class="material-icons  pointer hjik mr-2" onClick={() => sortDataTable1(sort.name == 'name asc' ? 'name desc' : 'name asc')} title="Sort">heighticon</i>
                                </th>
                                {/* <th scope='col'>Category
                                <i class="material-icons  cursor:pointer hjik mr-2" onClick={() => sortDataTable1(sort.email == 'email asc' ? 'email desc' : 'email asc')} title="Sort">heighticon</i>
                            </th> */}
                                <th scope='col' >Date
                                    <i class="material-icons  pointer hjik mr-2" onClick={() => sortDataTable1(sort.date == 'date asc' ? 'date desc' : 'date asc')} title="Sort">heighticon</i>
                                </th>
                                <th scope='col' >Time
                                </th>
                                <th scope='col'>Meeting Status
                                </th>
                                <th scope='col' className="nowrap">Action</th>
                            </tr>
                        </thead>
                        <tbody>

                            {!loaging && data && data.map((itm, i) => {

                                return <tr key={i}>

                                    <td onClick={() => {
                                        history.push(`/appointment/${itm?.appointment_details[0]?._id}?reId=${itm?._id}`)
                                    }} key={itm.key} className={`nowrap pointer`}>
                                        {methodModel?.capitalizeName(itm?.appointment_details[0]?.name)}
                                    </td>
                                    <td className={`nowrap pointer`}>{dateFormat(itm.date, "dd-mmm-yyyy")}</td>
                                    <td className={`nowrap pointer`}>{itm.time} </td>
                                    <td>
                                        <div style={{ color: 'white' }} ss key={itm.key} className={itm.meeting_status}>
                                            {itm.meeting_status}
                                        </div>
                                    </td>

                                    <td className="nowrap">
                                        <><span onClick={() => {
                                            localStorage.setItem("user_id", user?.id)
                                            history.push("/chat")
                                            localStorage.setItem("chatWithId", itm?.user_details[0]?._id)
                                        }}><i class="fa ml-2 pointer fa-comment-alt"></i></span>
                                            <span className='ml-2' style={{ cursor: "pointer" }}>
                                                <>
                                                    <span onClick={() => {
                                                        GetCode()
                                                        localStorage.setItem("meetingwith", itm?.user_details[0]?._id)
                                                    }}><i class="fa ml-2 pointer fa-video"></i></span>
                                                </> </span></>
                                        {
                                            itm?.meeting_status != "terminated" ? <i title='Terminate' onClick={() => {
                                                Terminate(itm?._id)
                                            }} class="fa fa-window-close pointer ml-2" aria-hidden="true"></i> : null
                                        }
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>

                    {loaging ? <div className="text-center py-4">
                        <img src="/assets/img/loader.gif" className="pageLoader" />
                    </div> : <></>}
                </div>
                {!loaging && data.length == 0 ? <div className="py-3 text-center">No Data</div> : <></>}

                {
                    !loaging && total > filters.count ? <div className='d-flex justify-content-between align-items-center flex-wrap'>
                        {/* <Pagination
                        activePage={filters.page}
                        itemsCountPerPage={filters.count}
                        totalItemsCount={total}
                        pageRangeDisplayed={5}
                        onChange={pageChange}
                    /> */}
                        <div className='d-flex align-items-center justify-content-center' >
                            <span>Show: </span>
                            <select
                                className="form-control ml-2"
                                onChange={(e) => handleCountChange(parseInt(e.target.value))}
                                value={filters.count}
                            >
                                <option value={7}>7</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={30}>30</option>
                                <option value={40}>40</option>
                            </select>
                        </div>
                        <Pagination
                            currentPage={filters.page}
                            totalSize={total}
                            sizePerPage={filters.count}
                            changeCurrentPage={pageChange}
                        />
                    </div> : <></>
                }


                <button type="button" id='OpenModel' class="btn btn-primary d-none" data-toggle="modal" data-target="#exampleModal">
                    Launch demo modal
                </button>


                <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <form
                            // onSubmit=
                            // {HandleSubmit}
                            >


                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Reschedule Appointment</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">

                                    <div className="col-md-12 mb-3">
                                        <label>
                                            Select Date<span className="star">*</span>
                                        </label>
                                        <input
                                            type="date"
                                            minLength={"14/12/2023"}
                                            className="form-control"
                                            value={form.date}
                                            onChange={(e) =>
                                                setform({ ...form, date: e.target.value })
                                            }
                                            required
                                        />
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label>Time Slot<span className="star">*</span></label>
                                        <select className="form-control"
                                            value={form.time}

                                            onChange={e => { setform({ ...form, time: e.target.value, }); }}

                                            required
                                        >
                                            <option value="">Select Time</option>
                                            <option value="9-10 AM">9-10 AM</option>
                                            <option value="10-11 AM">10-11 AM</option>
                                            <option value="11-12 PM">11-12 PM</option>
                                            <option value="12-13 PM">12-13 PM</option>
                                            <option value="14-15 PM">14-15 PM</option>
                                            <option value="15-16 PM">15-16 PM</option>

                                            <option value="17-18 PM">17-18 PM</option>
                                            <option value="18-19 PM">18-19 PM</option>
                                            <option value="19-20 PM">19-20 PM</option>
                                            <option value="20-21 PM">20-21 PM</option>
                                            <option value="21-22 PM">21-22 PM</option>
                                            <option value="22-23 PM">22-23 PM</option>
                                            <option value="23-24 AM">23-24 AM</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                    <button type="submit" class="btn btn-primary">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default ReschduleAppoint
