export const list = [
    // { id: 'admin', name: 'Web Admin' },
    { id: 'sub_admin', name: 'Sub Admin' },
    { id: 'user', name: 'User' },
]
export const roleType = {
    id: '', name: '', status: 'active', permissions: {
     
        category_add: false,
        category_delete: false,
        category_edit: false,
        category_get: false,
        content_delete: false,
        content_edit: false,
        content_get: false,
        deleteCategory: false,
        editCategory: false,
        readDashboard: false,
        user_add: false,
        user_get: false,
        user_delete: false,
        user_edit: false

    }
}
const find = (id) => {
    let ext = list.find(itm => itm.id == id)
    return ext
}

const name = (id) => {
    let ext = list.find(itm => itm.id == id)
    return ext ? ext.name : id
}


const rolesModel = { list, find, name }
export default rolesModel