import React, { useEffect, useRef, useState } from 'react'
import "./style.css"
import loader from '../../methods/loader';
import Layout from '../../components/global/layout';
import { useSelector } from 'react-redux';
import { ConnectSocket, SocketURL } from './socket';
import axios from 'axios';
import methodModel from '../../methods/methods';
import ApiClient from '../../methods/api/apiClient';
import moment from "moment";
import ImageView from "react-single-image-viewer";
import "react-single-image-viewer/dist/index.css";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ApiKey from '../../methods/ApiKey';

const chat = (p) => {
    let user = useSelector(state => state.user)
    const chatRef = useRef([]);
    const [id, setId] = useState("")
    const [filters, setFilter] = useState({ search: "" });
    const [isScrolled, setIsScrolled] = useState(false);
    const [chatList, setChatList] = useState([]);
    const history = useHistory()
    const [chat, setChat] = useState([]);
    const [roomId, setRoomId] = useState("");
    const [chatWith, setChatWith] = useState("");
    const [activeData, setActiveData] = useState([])
    const [chatMsg, setChatMsg] = useState("");
    const [isImage, setImage] = useState(false);
    const chatID = localStorage.getItem('chatWithId')
    const [Question, setQuestion] = useState([])
    const [timeRemaining, setTimeRemaining] = useState('');
    const [AppointTime, SetAppointTime] = useState([])
    const [resheduleAppointTime, SetResheduleAppointTime] = useState([])
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];
    const totalAppointment = [...resheduleAppointTime, ...AppointTime]
    const now = new Date();
    let appointmentStart
    let appointmentEnd

    console.log(totalAppointment, "totalAppointment--------")

    useEffect(() => {
        const id = localStorage.getItem("chatWithId")
        setId(id)
    }, [id])

    const handleUserId = (id) => {
        localStorage.setItem("chatWithId", id)
    }

    // useEffect(() => {
    //     ConnectSocket.emit("read-message", { user_id: localStorage.getItem("chatWithId")})
    //   }, [user?.id])

    const chatWithData = (id) => {
        ApiClient.get(`user/detail`, { id: id }).then(res => {
            if (res.success) {
                setChatWith(res.data)
            }
        })
    };

    const getDay = (time) => {
        let today = new Date();

        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        let todayform = moment(today).format("DD-MM-YYYY");
        let Yesterdayform = moment(yesterday).format("DD-MM-YYYY");
        let dateform = moment(time).format("DD-MM-YYYY");

        let date =
            todayform == dateform
                ? "Today"
                : Yesterdayform == dateform
                    ? "Yesterday"
                    : dateform;

        return date;
    };


    useEffect(() => {
        if (id) {
            chatWithData(id)
        }
    }, [id])

    const bootemel = useRef();
    const scrollToBottom = () => {
        bootemel?.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [chat]);

    const handleReceiveMessage = (newdata) => {
        const data = newdata.data;
        let payload = {
            sender_name: data?.fullName,
            sender_image: data?.image,
            chat_file: data?._doc?.chat_file,
            content: data?._doc?.content,
            createdAt: data?._doc?.createdAt,
            invitation_id: data?._doc?.invitation_id,
            media: data?._doc?.media,
            room_id: data?._doc?.room_id,
            sender: data?._doc?.sender,
            type: data?._doc?.type,
            updatedAt: data?._doc?.updatedAt,
            _id: data?._doc?._id,
        };
        if (payload?.room_id === localStorage.getItem("activeRoom")) {
            getChatList()
            setChat(prevChat => [...prevChat, payload]);
        } else {
            console.log('Room IDs do not match. Not adding to chat.');
        }
    };

 
 

    const upcomingAppointment = totalAppointment?.find((appointment) => {
        const [startHour, endHour] = appointment.time.split('-');
        const cleanedEndHour = endHour.replace(/[^\d]/g, '');
        appointmentStart = new Date(appointment.date + 'T' + parseInt(startHour) + ':00:00');
        appointmentEnd = new Date(appointment.date + 'T' + parseInt(cleanedEndHour) + ':00:00');

        return now >= appointmentStart && now <= appointmentEnd;
    });

    const calculateTimeRemaining = () => {
        if (upcomingAppointment) {
            const timeDiff = appointmentEnd - now;

            const hours = Math.floor(timeDiff / (1000 * 60 * 60));
            const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
            setTimeRemaining(`${hours}h ${minutes}m ${seconds}s`);
        } else {
            // router.push('/myappointment')
            setTimeRemaining('No Ongoing Appointment');
        }
    };
    useEffect(() => {
        let interval;
        interval = setInterval(() => {
            calculateTimeRemaining();
        }, 1000)

        return () => clearInterval(interval);
    }, [timeRemaining,upcomingAppointment]);
    // useEffect(()=>{
    //     calculateTimeRemaining()
    //     console.log(timeRemaining)
    // },[upcomingAppointment])
    const getAcceptData = (p = {}) => {
        // loader(true) 
        // let filter = { ...filters, ...p }
        ApiClient.get('user/all/appointments', { user_id: chatID }).then(res => {
            if (res.success) {
                // loader(false)
                SetAppointTime(res?.data?.appointment_details)
                SetResheduleAppointTime(res?.data?.reschedule_details)
            }

        })
    }

    //   const getResheduleAcceptData = (p = {}) => {
    //     loader(true)
    //     // let filter = { ...filters, ...p }
    //     ApiClient.get('user/reschedulelist', { sortBy: "date asc" }).then(res => {
    //       if (res.success) {
    //         loader(false)
    //         SetResheduleAppointTime(res?.data)
    //       }

    //     })
    //   }

    useEffect(() => {
        getAcceptData()
        // getResheduleAcceptData()
    }, [chatID])

    useEffect(() => {
        ConnectSocket.on('receive-message', handleReceiveMessage);
    }, []);

    const userMessage = (roomuid) => {
        axios
            .get(
                `${SocketURL}chat/user/message/all?room_id=${roomuid}`
            )
            .then((res) => {
                if (res?.data.success) {
                    setChat(res.data.data.data);
                    chatRef.current = res.data.data.data;
                }
            });
    };

    useEffect(() => {
        ConnectSocket.emit("user-online", { user_id: user?.id });
        ConnectSocket.on(`user-online`, (data) => {
            console.log("user-online", data)
            const newdata = data?.data?.user_id;
            //   setIsOnline(true);
            //   setStatus(newdata);
        });
    }, []);

    const isURL = (text) => {
        const containsDocuments = text.includes("documents/");
        return containsDocuments;
    };

    const uploadImage = (e) => {
        let files = e.target.files;
        let file = files.item(0);
        const fileExtension = file?.name?.split(".").pop().toLowerCase();
        let isTrue = imageExtensions.includes(fileExtension);


        let url = isTrue
            ? "upload/image?modelName=users"
            : "single/documents";

        setImage(true);
        ApiClient.postFormData(url, { file: file }).then((res) => {
            if (res?.success) {
                let image = res.data.fullpath;
                // setFileName(file?.name)
                const payload = {
                    roomId: roomId,
                    type: "TEXT",
                    sender: user.id,
                    content: isTrue ? `images/users/${image}` : `documents/${res?.data?.imagePath}`,
                    // proposal_id: proposaldata?._id,
                    // campaign_id: proposaldata?.campaign_id,
                    // chat_file:fileName
                };
                ConnectSocket.emit(`send-message`, payload);
                setChatMsg("");
            }
            setImage(false);
        });
    };

    const joinRoom = (jId) => {
        const payload = {
            room_id: jId,
            user_id: user.id,
        };
        ConnectSocket.emit("join-room", payload);
        ConnectSocket.emit("read-message", { user_id: id })
        ConnectSocket.on("read-message", (data) => {
            console.log(data, "gyhu")
        })
    };

    useEffect(() => {
        ConnectSocket.on("all-messages-read", (data) => {
            getChatList()
        })
    }, [])


    const questionData = (id) => {
        ApiClient.get(`userQuestionDetail`, { user_id: id }).then(res => {
            if (res.success) {
                setQuestion(res.data)
            }
        })
    };

    useEffect(() => {
        if (id) {
            questionData(id)
        }
    }, [id])

    const getData = () => {
        if (id) {
            const payload = {
                chat_by: user?.id,
                chat_with: id,
            };
            // loader(true);

            axios.post(`${SocketURL}chat/user/join-group`, payload).then((res) => {
                if (res?.data?.success) {
                    const data = res.data;
                    setRoomId(res.data.data.room_id);
                    userMessage(data.data.room_id);
                    joinRoom(data.data.room_id);
                    ConnectSocket.emit("read-all-messages", { room_id: data.data.room_id })
                    localStorage.setItem("activeRoom", data.data.room_id)
                    // loader(false);
                }
            });
        }
    };

    useEffect(() => {
        getData()
    }, [id]);

    const filter = (p = {}) => {
        setFilter({ ...filters, ...p })
        getChatList({ ...p });
    };

    const handelSubmit = (e) => {
        e.preventDefault();

        if (!chatMsg) {
            return
        }

        const payload = {
            roomId: roomId,
            type: "TEXT",
            sender: user.id,
            content: chatMsg,
            // proposal_id: proposaldata?._id,
            // campaign_id: proposaldata?.campaign_id,
            // chat_file:fileName
        };
        ConnectSocket.emit(`send-message`, payload)
        setChatMsg("");
    };


    const getChatList = (p = {}) => {
        loader(true)
        console.log(p, "bjhbjji")
        let url = `${SocketURL}chat/user/recent-chats/all?user_id=${user?.id}&login_user_id=${user?.id}&search=${!p?.search ? "" : p?.search}`
        axios.get(url).then((res) => {
            if (res.data.success) {
                setChatList(res?.data?.data?.data)
            }
        });
        loader(false)
    };

    useEffect(() => {
        getChatList()
    }, [chatMsg, id])

    return <>
        <Layout>
            {/* chats section start */}
            <section className='p80'>
            <i className="fa fa-arrow-left pointer" title='Back' aria-hidden="true" onClick={()=>{
                history.push('/appointment')
            }}></i>
                <div className='container p-0'>
                    <div className='chatsingle'>
                        <div className=" clearfix">
                            <div className="people-list" id="people-list">
                                <div className="search">
                                    <input type="text" placeholder="search"
                                        value={filters?.search}
                                        onChange={(e) =>
                                            filter({ search: e.target.value })}
                                    />
                                    <i className="fa fa-search"></i>
                                </div><ul className="list">
                                    {chatList?.map((itm, index) => {
                                        return <li
                                            className={activeData?.room_members?.[0]?.user_id == itm?.room_members[0]?.user_id || chatWith?.id == itm?.room_members[0]?.user_id ? "clearfix active_chat" : "clearfix new_chats"}
                                            key={index}
                                            onClick={() => {
                                                setActiveData(itm)
                                                chatWithData(itm?.room_members[0]?.user_id)
                                                handleUserId(itm?.room_members[0]?.user_id)
                                                questionData(itm?.room_members[0]?.user_id)
                                                ConnectSocket.emit("read-all-messages", { room_id: itm?.room_id })
                                                localStorage.setItem("activeRoom", itm?.room_id)
                                                userMessage(itm?.room_id);
                                                setRoomId(itm?.room_id)
                                                joinRoom(itm?.room_id);
                                            }}
                                        >
                                            <img src={methodModel.userImg(itm?.room_members[0]?.user_image)} alt="avatar" />
                                            <div className="about">
                                                <div className="name">  {methodModel?.capitalizeName(
                                                    itm && itm?.room_members && itm?.room_members[0]?.user_name
                                                )
                                                }
                                                    {itm?.unread_count && itm?.unread_count > 0 ? <span className='unread_chat'>{itm?.unread_count}</span> : ''}
                                                </div>
                                                <div className="status">
                                                    <span>{itm?.last_message?.content.includes('images/users') || itm?.last_message?.content.includes('documents/') ? "" : itm?.last_message?.content?.substring(0, 20)}</span>
                                                </div>
                                            </div>
                                        </li>
                                    })}
                                </ul>
                            </div>

                            {localStorage.getItem("chatWithId") ? <div className="chat">
                                <div className="chat-header clearfix">
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div className='d-flex'>
                                            <img src={methodModel.userImg(chatWith?.image)} alt="avatar" style={{ height: '50px' }} />
                                            <div className="chat-about">
                                                <div className="chat-with">{methodModel?.capitalizeName(chatWith?.fullName)}</div>
                                                <div className="chat-num-messages">{activeData?.room_members?.[0]?.isOnline ? "online" : "offline"}</div>
                                            </div>
                                        </div>
                                        <div className='timer_chat'>
                                            <p><i className='fa fa-clock mr-2'></i>{timeRemaining}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="chat-history">
                                    <div className="cleint_question_wrap">
                                        <div className="chating_ques">
                                            <ul className='patient_ans'>
                                                <li className='clearfix '>
                                                    <div className='message-data align-right'>
                                                        {/* <span className="message-data-time" >{getDay(item.updatedAt)}</span> &nbsp; &nbsp; */}
                                                        <span className="message-data-name" >{user?.fullName}</span> <i className="fa fa-circle me_right"></i>
                                                    </div>

                                                    <div className='message other-message float-right '>
                                                        {Question?.map((item) => {
                                                            return (
                                                                <div className='cleint_questn' key={item._id}>
                                                                    <h3 >{item.user_question}</h3>
                                                                    <ul className='patient_ans'>
                                                                        {typeof (item.user_answer) !== 'string' ? item.user_answer.map((answer) => (
                                                                            <li key={answer.id}>{answer.option}</li>
                                                                        )) : <li>{item.user_answer}</li>}
                                                                    </ul>
                                                                </div>
                                                            )
                                                        })}

                                                    </div>

                                                </li>
                                            </ul>
                                        </div>
                                    </div>


                                    <div className='admin_msg'>
                                        <div className='text-center'>
                                            <p className='text-black'>Patient connect you soon..</p>
                                        </div>
                                    </div>

                                    <ul className='patient_ans'>
                                        {user?.id && chat.map((itm) => {
                                            const fileExtension = itm?.content?.split(".").pop().toLowerCase()
                                            let isImage = imageExtensions.includes(fileExtension);
                                            let isTrue = user?.id == itm?.sender
                                            const date = new Date(itm.updatedAt);
                                            const hours = ('0' + date.getHours()).slice(-2);
                                            const minutes = ('0' + date.getMinutes()).slice(-2);
                                            const timeWithoutSeconds = hours + ':' + minutes;
                                            return (<li className="clearfix">
                                                <div className={isTrue ? "message-data align-right" : "message-data"} ref={bootemel}>
                                                    <span className="message-data-time" >{getDay(itm.updatedAt)} {timeWithoutSeconds}</span> &nbsp; &nbsp;
                                                    <span className="message-data-name" >{itm?.sender_name}</span> <i className="fa fa-circle me"></i>

                                                </div>
                                                <div className={isTrue ? "message other-message float-right" : "message my-message"}>
                                                    {isImage ? (
                                                        <ImageView width={"50px"} height={"50px"} src={`${ApiKey?.api}${itm?.content}`} />
                                                    ) : isURL(itm.content) ? (
                                                        <div className="pdf_btn">
                                                            <div className="pdf_inner_layout ">
                                                                <span className="pdficon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65" fill="none">
                                                                        <path d="M43.3332 0H21.6665C18.6873 0 16.2498 2.4375 16.2498 5.41667V48.75C16.2498 51.7292 18.6873 54.1667 21.6665 54.1667H54.1665C57.1457 54.1667 59.5832 51.7292 59.5832 48.75V16.25L43.3332 0ZM54.1665 48.75H21.6665V5.41667H40.6248V18.9583H54.1665V48.75ZM10.8332 10.8333V59.5833H54.1665V65H10.8332C7.854 65 5.4165 62.5625 5.4165 59.5833V10.8333H10.8332ZM27.0832 27.0833V32.5H48.7498V27.0833H27.0832ZM27.0832 37.9167V43.3333H40.6248V37.9167H27.0832Z" fill="black" />
                                                                    </svg>
                                                                </span>
                                                            </div>

                                                            <div className="hoverdonload ">
                                                                {isURL(itm.content) ? (
                                                                    <a
                                                                        href={`${ApiKey?.api}${itm.content}`}
                                                                        download="document.pdf"
                                                                    >
                                                                        {" "}
                                                                        <i className="fa fa-download"></i>{" "}

                                                                    </a>
                                                                ) : null}
                                                            </div>

                                                        </div>
                                                    ) : (
                                                        itm.content
                                                    )}
                                                </div>
                                            </li>)
                                        })}
                                    </ul>
                                    {isImage && <div className="image_loader">
                                        <div class="loader"></div>
                                    </div>}
                                </div>

                                <div className="chat-message ">
                                    <div className='d-flex align-items-center w-100'>
                                        <div className='position-relative w-100'>
                                            <textarea
                                                type="text"
                                                className='textrea_list w-100 mr-3'
                                                value={chatMsg}
                                                onChange={(e) => setChatMsg(e.target.value)}

                                                onKeyDown={(e) => {
                                                    e.key === 'Enter' && handelSubmit(e)
                                                    // e.key === 'Enter' && setChangeCategory('')
                                                }}
                                                disabled={chat?.length > 0 && chat[0]?.rooms_details?.blocked_admin ? true : false} >
                                            </textarea>

                                            <label className="pointer_upload">
                                                <input
                                                    id="bannerImage"
                                                    type="file"
                                                    className="d-none"
                                                    accept="file/*"
                                                    onChange={(e) => {
                                                        uploadImage(e);
                                                    }}
                                                    disabled={chat?.length > 0 && chat[0]?.rooms_details?.blocked_admin ? true : false}
                                                />
                                                <i class="fa fa-paperclip" aria-hidden="true"></i>

                                            </label>
                                        </div>

                                        <div className='sendmsgebtn'>
                                            <button className='btn btn-transparent p-0'
                                                onClick={(e) => handelSubmit(e)}
                                                disabled={chat?.length > 0 && chat[0]?.rooms_details?.blocked_admin ? true : false}
                                            ><i className='fa fa-paper-plane icon_blue' ></i></button>

                                        </div>
                                    </div>

                                </div>

                            </div> :
                                // <div className="col-lg-8">
                                <div className="h600 text-center d-flex justify-content-center align-items-center">

                                    <i className="fa fa-comments mr-2"></i>
                                    No chat Active
                                </div>
                                // </div>
                            }

                        </div>



                    </div>
                </div>
            </section>



        </Layout>
    </>;
};

export default chat;
