import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Layout from '../../components/global/layout';
import ApiClient from '../../methods/api/apiClient';
import datepipeModel from '../../models/datepipemodel';
// import Pagination from "react-js-pagination";
import Pagination from "react-pagination-js";
import './style.scss';
import dateFormat, { masks } from "dateformat";
import AddEdit from './ViewTestimonials';
import { ToastsStore } from 'react-toasts';
import ViewModal from './AddEditCategory';
import loader from '../../methods/loader';
import methodModel from '../../methods/methods';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Swal from 'sweetalert2';

const Testimonials = (p) => {
    let user = useSelector(state => state.user)
    const [sort, setsort] = useState({ name: 'name asc', createdAt: '', updatedAt: '', status: '', category: '', cat_type: '' })
    const [filters, setFilter] = useState({ })
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [loading, setLoader] = useState(true)
    const [form, setform] = useState({ name: '', cat_type: '', description: '' })
    const [type, setType] = useState()
    const searchState = useSelector((state) => state.search);
    const history = useHistory()
    const [permissions, setpermissions] = useState({})

    const getdata = () => {
        ApiClient.get('user/detail', { id: user?.id }).then((res) => {
            if (res?.success) {
                console.log(res)
                setpermissions(res?.permissions)
            }
        })
    }
    useEffect(() => {
        // getdata()
        // console.log(permissions)
    }, [])
    useEffect(() => {
        if (user && user.loggedIn) {
            let type = methodModel.getPrams('type')
            // getType(type)
        }
    }, [])

    useEffect(() => {
        if (user && user.loggedIn) {
            let type = methodModel.getPrams('type')
            setFilter({ ...filters, search: searchState.data, type })
            getData({ search: searchState.data, page: 1, type: type })
        }
    }, [searchState])

    const getType = (id) => {
        loader(true)
        ApiClient.get("category/type", { id: id }).then(res => {
            if (res.success) {
                setType(res.data)
            }
            loader(false)
        })
    }

    const getData = (p = {}) => {
        setLoader(true)
        let filter = { ...filters, ...p }
        ApiClient.get('testimonialslist', filter).then(res => {
            if (res.success) {
                setData(res.data)
                setTotal(res.total)
            }
            setLoader(false)
        })
    }

    const ChnageStatus = (id, status) => {

        if (window.confirm(`Do You Want To ${status ? "Disable" : "Activate"}`)) {

            ApiClient.put('testimonial', { id: id, is_top_therapist: status ? false : true }).then((res) => {
                if (res.success) {

                    getData()
                }
            })
        }
    }

    const sortDataTable1 = (e) => {
        setFilter({ ...filters, sortBy: e })
        if (e == 'name asc' || e == 'name desc') setsort({ ...sort, name: e })
        if (e == 'cat_type asc' || e == 'cat_type desc') setsort({ ...sort, cat_type: e })
        if (e == 'email asc' || e == 'email desc') setsort({ ...sort, email: e })
        if (e == 'createdAt asc' || e == 'createdAt desc') setsort({ ...sort, createdAt: e })
        if (e == 'updatedAt asc' || e == 'updatedAt desc') setsort({ ...sort, updatedAt: e })
        if (e == 'active' || e == 'deactive') setsort({ ...sort, status: e })

        getData({ sortBy: e })
    }



    const pageChange = (e) => {
        setFilter({ ...filters, page: e })
        getData({ page: e })
    }

    const openModal = (itm = {}) => {
        setform({ name: '', cat_type: '', description: '' })
        if (itm) {
            setform({ ...form, ...itm, })
        }
        document.getElementById("openEditModal").click()
    }

    const openViewModal = (itm = '') => {
        setform({ ...form, ...itm })
        document.getElementById("openEditModal").click()
    }

    const clear = () => {
        setFilter({ ...filters, search: '', page: 1 })
        getData({ search: '', page: 1 })
    }

    const deleteItem = (id) => {

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                // loader(true)
                ApiClient.delete('testimonial', { model: 'testimonial', id: id }).then(res => {
                    if (res.success) {
                        ToastsStore.success(res.message)
                        clear()
                    }
                    // loader(false)
                })
            }
        })
    }



    const sortDataTable2 = (e) => {
        setFilter({ ...filters, status: e })

        if (e == 'active' || e == 'deactive') setsort({ ...sort, status: e })
        getData({ status: e })
    }

    const modalClosed = () => {
        setFilter({ ...filters, page: 1 })
        getData()
    }
    const ChangeFilter = (e) => {
        setFilter({ ...filters, cat_type: e, page: 1 })
        getData({ cat_type: e, page: 1 })
    }

    const handleCountChange = (count) => {
        setFilter({ ...filters, count: count, page: 1 });
        getData({ count: count, page: 1 });
    };

    return (
        <Layout>
            <div className='text-right mb-3'>


            </div>
            <div className="mb-3 d-flex justify-content-between main_title">
                <h3 className="hedding text-capitalize">

                    {type && type.name} Testimonials
                </h3>
                <article className="d-flex">
                    {
                        user.role === 'admin' || permissions?.category_add ?
                            <button className="btn btn-primary mr-2" onClick={() => history.push('/testimonials/add')}>Add Testimonial</button> : null
                    }
                    <div className="dropdown addDropdown mr-2">

                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a className={filters.category == '' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeFilter('')}>All</a>



                            <a className={filters.cat_type == 'FAQ' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeFilter("FAQ")} >FAQ</a>
                            <a className={filters.cat_type == 'Questions' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeFilter("Questions")} >Question</a>


                        </div>
                    </div>


                    {/* <div className="dropdown addDropdown mr-2">

                        <button className="btn btn-primary dropdown-toggle ml-2" type="button" id="dropdownMenuColumns" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {
                                !sort.status ? 'All' : `${sort.status == 'active' ? 'Active' : 'Inactive'}`
                            }
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuColumns">
                            <a className='dropdown-item' onClick={() => sortDataTable2(sort.status = '')} >All</a>
                            <a className='dropdown-item' onClick={() => sortDataTable2(sort.status = 'active')} >Active</a>
                            <a className='dropdown-item' onClick={() => sortDataTable2(sort.status = 'deactive')}>Inactive</a>
                        </div>
                    </div> */}


                </article>
            </div>
            <div className="table-responsive">
                <table className="table mb-0">
                    <thead className="theadclss">
                        <tr className="tblclas">
                            <th scope="col" > Name
                                <i class="material-icons  pointer hjik mr-2" onClick={() => sortDataTable1(sort.name == 'name asc' ? 'name desc' : 'name asc')} title="Sort">heighticon</i></th>
                            <th scope="col" >Place
                            </th>
                           

                           
                            <th scope="col" >Content
                            </th>

                            <th scope="col" >Status
                            </th>
                           
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!loading && data && data.map((itm, i) => {
                            return <tr key={i}>
                                <td onClick={()=>{
                                    history.push('/testimonials/view/'+itm?._id)
                                }}>{methodModel.capitalizeName(itm.name)}</td>
                                <td>{itm?.place}</td>
                                <td>{itm?.content?.substr(0,20)}...</td>
                            



                                <td > <div key={itm.key} onClick={() => {
                                    ChnageStatus(itm._id, itm.is_top_therapist)
                                }} className={`${itm.is_top_therapist? 'activeItm' : 'deactiveItm'} `}>
                                    {itm.is_top_therapist?"Active":"Inactive"}
                                </div></td>
                                <td >
                                    {
                                        user.role == 'admin' || permissions?.category_edit ?
                                            <a className="linkclass mx-2" title="Edit" onClick={() => history.push('/testimonials/edit/' + itm._id)} ><i className="fa fa-pen"></i></a> : null
                                    }
                                    {
                                        user.role == 'admin' || permissions?.category_delete ?
                                            <a className="linkclass mx-2" title="Delete" onClick={() => {
                                                deleteItem(itm._id)
                                            }} ><i className="fa fa-trash"></i></a> : null
                                    }
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>

                {loading ? <div className="text-center py-4">
                    <img src="/assets/img/loader.gif" className="pageLoader" />
                </div> : <></>}
            </div>

            {!loading && total == 0 ? <div className="py-3 text-center">No Data</div> : <></>}

            {
                !loading && total > filters.count ? <div className='d-flex justify-content-between align-items-center flex-wrap'>
                    {/* <Pagination
                        activePage={filters.page}
                        itemsCountPerPage={filters.count}
                        totalItemsCount={total}
                        pageRangeDisplayed={5}
                        onChange={pageChange}
                    /> */}
                    <div className='d-flex align-items-center justify-content-center' >
                        <span>Show: </span>
                        <select
                            className="form-control ml-2"
                            onChange={(e) => handleCountChange(parseInt(e.target.value))}
                            value={filters.count}
                        >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                            <option value={40}>40</option>
                        </select>
                    </div>
                    <Pagination
                        currentPage={filters.page}
                        totalSize={total}
                        sizePerPage={filters.count}
                        changeCurrentPage={pageChange}
                    />
                </div> : <></>
            }
            {/* <AddEdit form={form} setform={setform} modalClosed={modalClosed} /> */}
            {/* <ViewModal form={form} setform={setform} /> */}
        </Layout>
    );
};







export default Testimonials;
