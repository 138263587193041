import React, { useEffect, useState } from 'react'
import Layout from '../../components/global/layout'
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import ApiClient from '../../methods/api/apiClient'

function ViewContent() {

    const [data, setData] = useState([])
    const [loading, setLoader] = useState(true)
    const { slug } = useParams()


    const getData = () => {
        setLoader(true)
        ApiClient.get(`content?page_name=${slug}&status=active`).then((res) => {
            if (res.success) {
                setData(res.data)
                setLoader(false)

            }
        })
    }

    useEffect(() => {
        getData()

    }, [])
    return (
        <>
            <Layout>
                <div className='text-right'><Link to="/contentpage"><i className="fa fa-arrow-left pb-3" title='Back' aria-hidden="true"></i></Link></div>


                <div className=" pprofile1">
                    <h3 className='ViewUser'>View  Content</h3>

                    <div className='content-inner-details'>
                        <div className='row'>
                            <div className='col-2'>
                                <div className='head-bold'>
                                    <p> Title:</p>
                                </div>
                            </div>
                            <div className='col-10'>
                                <div className='head-dtls'>
                                    <p>{data?.title}</p>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-2'>
                                <div className='head-bold'>
                                    <p> Meta Title:</p>
                                </div>
                            </div>
                            <div className='col-10'>
                                <div className='head-dtls'>
                                    <p>{data?.meta_title}</p>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-2'>
                                <div className='head-bold'>
                                    <p> Page Name:</p>
                                </div>
                            </div>
                            <div className='col-10'>
                                <div className='head-dtls'>
                                    <p>{data?.page_name}</p>
                                </div>
                            </div>
                        </div>



                        <div className='row'>
                            <div className='col-2'>
                                <div className='head-bold'>
                                    <p> Meta Description:</p>
                                </div>
                            </div>
                            <div className='col-10'>
                                <div className='head-dtls'>
                                    <p>  {data?.meta_description?.replaceAll('</p>', " ").replaceAll('<p>', " ")}</p>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-2'>
                                <div className='head-bold'>
                                    <p> Meta Keywords:</p>
                                </div>
                            </div>
                            <div className='col-10'>
                                <div className='head-dtls'>
                                    <p>  {data?.meta_keyword}</p>
                                </div>
                            </div>
                        </div>

                        <div className='row '>
                            <div className='col-2'>
                                <div className='head-bold'>
                                    <p> Description:</p>
                                </div>
                            </div>
                            <div className='col-10' dangerouslySetInnerHTML={{ __html: data?.description }}>

                            </div>
                        </div>



                    </div>

                </div>



                {loading ? <div className="text-center py-4">
                    <img src="/assets/img/loader.gif" className="pageLoader" />
                </div> : <></>}
            </Layout>
        </>
    )
}

export default ViewContent
