import React, { useEffect, useState } from 'react';
import { roleType } from '../../models/roles.model';
import Layout from '../../components/global/layout';
import {
  Link,
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import methodModel from '../../methods/methods';
import ApiClient from '../../methods/api/apiClient';
import { ToastsStore } from 'react-toasts';
import loader from '../../methods/loader';

function AddEdit() {
  const { id } = useParams();
  const [form, setform] = useState(roleType);
  const [submitted, setSubmitted] = useState(false);
  const [sub_ad, setsub_ad] = useState(false);
  const [loading, setLoader] = useState(false)
  const history = useHistory();
  const [permisnId, setpermisnId] = useState('')
  const getData = () => {
    setLoader(true)
    ApiClient.get('user/detail', { id: id }).then((res) => {
      if (res.success) {
        setLoader(false)
        setform({
          ...form,
          // firstName: res?.data?.firstName,
          // lastName: res?.data?.lastName,
          fullName: res?.data?.fullName,
          email: res?.data?.email,
          role: res?.data?.role,
          id: res?.data?.id
        });
        if (res?.permissions) {
          setpermisnId(res?.permissions?.id)
          setform({
            ...form, fullName: res?.data?.fullName,

            email: res?.data?.email,
            role: res?.data?.role,
            id: res?.data?.id, permissions: {
              ...form.permissions, category_add: res?.permissions?.category_add,
              category_edit: res?.permissions?.category_edit,
              category_get: res?.permissions?.category_get,
              category_delete: res?.permissions?.category_delete,
              faq_get: res?.permissions?.faq_get,
              faq_add: res?.permissions?.faq_add,
              faq_delete: res?.permissions?.faq_delete,
              faq_edit: res?.permissions?.faq_edit,
              user_delete: res?.permissions?.user_delete,
              user_edit: res?.permissions?.user_edit,
              user_add: res?.permissions?.user_add,
              user_get: res?.permissions?.user_get,

              content_edit: res?.permissions?.content_edit,

              content_get: res?.permissions?.content_get,
              question_add: res?.permissions?.question_add,
              question_get: res?.permissions?.question_get,
              question_delete: res?.permissions?.question_delete,
              question_edit: res?.permissions?.question_edit,
            }
          })
        }
        if (sub_ad) {
          setform({
            ...form,
            permissions: {
              ...form.permissions,
              permissions: res?.permissions,
            },
          });

        }
      }
    });
  };
  useEffect(() => {

    if (id) {
      getData();
    }
  }, []);
  useEffect(() => {
    if (form.role == 'sub_admin') {
      setsub_ad(true);
      console.log(sub_ad);
    } else {
      setsub_ad(false);
    }
  }, [form]);


  const handleSubmit = (e) => {
    e.preventDefault()
    setSubmitted(true)


    let method = 'post'
    let url = 'admin/add-user'
    let value = {
      fullName:form?.fullName, email: form.email, id: form.id, role: form.role
    }
    // if (form.role == 'sub_admin') {
    //   value = {
    //     fullName: form?.fullName,

    //     email: form.email,
    //     permissions: {

    //       category_add: form?.permissions?.category_add,
    //       category_edit: form?.permissions?.category_edit,
    //       category_get: form?.permissions?.category_get,
    //       category_delete: form?.permissions?.category_delete,

    //       user_delete: form?.permissions?.user_delete,
    //       user_edit: form?.permissions?.user_edit,
    //       user_add: form?.permissions?.user_add,
    //       user_get: form?.permissions?.user_get,
    //       faq_get: form?.permissions?.faq_get,
    //       faq_add: form?.permissions?.faq_add,
    //       faq_delete: form?.permissions?.faq_delete,
    //       faq_edit: form?.permissions?.faq_edit,
    //       content_edit: form?.permissions?.content_edit,

    //       content_get: form?.permissions?.content_edit,
    //       question_add: form?.permissions?.question_add,
    //       question_get: form?.permissions?.question_get,
    //       question_delete: form?.permissions?.question_delete,
    //       question_edit: form?.permissions?.question_edit,
    //     },
    //     role: form.role,
    //   };
    // }

    if (form.id) {
      method = 'put'
      url = 'admin/edit-user'
      value = {
        fullName:form?.fullName, email: form.email, id: form.id,
      }
      delete value.password
    } else {
      delete value.id
    }

    // if (form.id && form.role == 'sub_admin') {
    //   value = {
    //     firstName: form.firstName,
    //     lastName: form.lastName,
    //     email: form.email,
    //     id: form.id,
    //     permissions: {
    //       id: permisnId,
    //       category_add: form?.permissions?.category_add,
    //       category_edit: form?.permissions?.category_edit,
    //       category_get: form?.permissions?.category_get,
    //       category_delete: form?.permissions?.category_delete,
    //       faq_get: form?.permissions?.faq_get,
    //       faq_add: form?.permissions?.faq_add,
    //       faq_delete: form?.permissions?.faq_delete,
    //       faq_edit: form?.permissions?.faq_edit,
    //       user_delete: form?.permissions?.user_delete,
    //       user_edit: form?.permissions?.user_edit,
    //       user_add: form?.permissions?.user_add,
    //       user_get: form?.permissions?.user_get,

    //       content_edit: form?.permissions?.content_edit,

    //       content_get: form?.permissions?.content_get,
    //       question_add: form?.permissions?.question_add,
    //       question_get: form?.permissions?.question_get,
    //       question_delete: form?.permissions?.question_delete,
    //       question_edit: form?.permissions?.question_edit,
    //     },
    //     role: form.role,
    //   };
    // }
    setLoader(true)
    ApiClient.allApi(url, value, method).then(res => {
      if (res.success) {
        setLoader(false)
        ToastsStore.success(res.message)
        history.goBack()
      } else {
        setTimeout(() => {
          setLoader(false)
        }, (3000));
      }


    })
  }
  const LocalStrg = () => {
    let arr = []


    arr.push({ name: form?.fullName })
    console.log(arr)
    localStorage.setItem('item', JSON.stringify(arr))
  }

  useEffect(() => {

    console.log(form);
  }, [form]);
  //  For Handling Dashboard permission
  const hanldealldashboardpermission = (check) => {
    let value = true;
    if (check) {
      value = true;
    } else {
      value = false;
    }
    setform({
      ...form,
      permissions: { ...form.permissions, readDashboard: value },
    });
  };
  //  For Handling Cutomer Permission

  const HandleAll = (check) => {
    let value = check ? true : false;
    let permissions = form.permissions;
    Object.keys(permissions).map((itm) => {
      permissions[itm] = value;
    });
    setform({
      ...form,
      permissions: {

        readDashboard: value,
        category_add: value,
        category_edit: value,
        category_get: value,
        category_delete: value,
        deleteCategory: value,
        editCategory: value,
        user_delete: value,
        user_edit: value,
        user_add: value,
        user_get: value,
        faq_get: value,
        faq_add: value,
        faq_delete: value,
        faq_edit: value,
        content_edit: value,
        content_delete: value,
        content_get: value,
        question_add: value,
        question_get: value,
        question_delete: value,
        question_edit: value,
        // addFAQ:value,
        // editFAQ:value,
        // readFAQ:value,
        // deleteFAQ:value,
        // readPayment:value,
        // editPayment:value,
        // deletePayment:value,
        // addPayment:value,
        // addQuestion:value,
        // readQuestion:value,
        // editQuestion:value,
        // deleteQuestion:value
      },
    });
  };

  const isAllChecked = () => {
    let value = true;
    let permissions = form.permissions;
    Object.keys(permissions).map((itm) => {
      if (!permissions[itm]) value = false;
    });
    return value;
  };

  const HandleAllRead = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        readDashboard: value,
        category_add: value,
        category_edit: value,
        category_get: value,
        category_delete: value,
        deleteCategory: value,
        editCategory: value,
        user_delete: value,
        user_edit: value,
        user_add: value,
        user_get: value,
        faq_get: value,
        faq_add: value,
        faq_delete: value,
        faq_edit: value,
        content_edit: value,
        content_delete: value,
        content_get: value,
        question_add: value,
        question_get: value,
        question_delete: value,
        question_edit: value,
      },
    });
  };

  const HandleAllAdd = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        permissions: {
          ...form.permissions,
          readDashboard: value,
          category_add: value,
          category_edit: value,
          category_get: value,
          category_delete: value,
          deleteCategory: value,
          editCategory: value,
          user_delete: value,
          user_edit: value,
          user_add: value,
          user_get: value,
          faq_get: value,
          faq_add: value,
          faq_delete: value,
          faq_edit: value,
          content_edit: value,
          content_delete: value,
          content_get: value,
          question_add: value,
          question_get: value,
          question_delete: value,
          question_edit: value,
        },
      },
    });
  };
  const HandleallEdit = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        permissions: {
          ...form.permissions,
          editContent: value,
          editCategory: value,
          editRole: value,
        },
      },
    });
  };
  const HandleAllDelete = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        permissions: {
          ...form.permissions,
          deleteContent: value,
          deleteCategory: value,
          deleteRole: value,
        },
      },
    });
  };
  const HandleRefreshAll = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: { ...form.permissions, refreshBooking: value },
    });
  };

  const handleAllContent = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,

        content_edit: value,
        content_get: value,

      },
    });
  };
  const handleAllPayment = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        readPayment: value,
        addPayment: value,
        deletePayment: value,
        editPayment: value,
      },
    });
  };
  handleAllPayment;
  const handleAllFAQ = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        faq_get: value,
        faq_add: value,
        faq_delete: value,
        faq_edit: value,
      },
    });
  };
  const HandleAllQuest = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        question_add: value,
        question_get: value,
        question_delete: value,
        question_edit: value,
      },
    });
  };

  const HandleCategoryPer = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        category_add: value,
        category_delete: value,
        category_get: value,
        category_edit: value,
      },
    });
  };
  const handleRolesPer = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        user_add: value,
        user_delete: value,
        user_edit: value,
        user_get: value,
      },
    });
  };
  return (
    <>
      <Layout>     <form onSubmit={handleSubmit}>
        <div className="pprofile1">
          <h3 className="ViewUser mb-3" onClick={LocalStrg}>

            {form && form.id ? 'Edit' : 'Add'} User
          </h3>
          {loading ? <div className="text-center py-4">
            <img src="/assets/img/loader.gif" className="pageLoader" />
          </div> :
            <div className="form-row">
              <div className="col-md-6 mb-3">
                <label>
                  Full Name<span className="star">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={form.fullName}
                  onChange={(e) =>
                    setform({ ...form, fullName: e.target.value })
                  }
                  required
                />
              </div>

              <div className="col-md-6 mb-3">
                <label>
                  Email Address<span className="star">*</span>
                </label>
                <input
                  type="email"
                  className="form-control"
                  disabled={id ? true : false}
                  value={form.email}
                  onChange={(e) => setform({ ...form, email: e.target.value })}
                  required
                />
              </div>
              <div className="col-md-6 mb-3">
                <label>
                  Role<span className="star">*</span>
                </label>
                <select disabled={form?.id ? true : false}
                  className="form-control"
                  value={form && form.role}
                  onChange={(e) => {
                    setform({ ...form, role: e.target.value });

                    if (form.role == 'sub-admin') {
                      setsub_ad(true);
                    }
                  }}
                  // disabled={form.id ? true : false}
                  required
                >
                  <option value="">Select Role</option>
                  <option value="patient">Patient</option>
                  {/* <option value="sub_admin">Sub-Admin</option> */}
                  {/* <option value="doctor">Doctor</option> */}
                </select>
              </div>
              {sub_ad ? (
                <div className="col-md-12 mb-3">
                  <h5 className="mb-0 mt-4">
                    <b>Permissions</b>
                  </h5>
                  {/* roles */}
                  {/* 1st */}

                  <div class="table-responsive">
                    <div class="table_section tablepadding">
                      <table class="table table-striped">
                        <thead class="table_head roleTable">
                          <tr class="heading_row">
                            <th scope="col" class="table_data"></th>
                            <th scope="col" class="table_data">
                              <input
                                type="checkbox"
                                className="mr-2"
                                onChange={(e) => HandleAll(e.target.checked)}
                                checked={isAllChecked()}
                              />
                              All
                            </th>
                            <th scope="col" class="table_data">
                              {/* <input
                            type="checkbox" className='mr-2'  onChange={e=>HandleAllRead(e.target.checked)} checked={form.permissions.readAdmins&&form.permissions.readBooking&&form.permissions.readCategory&&form.permissions.readCities&&form.permissions.readContent&&form.permissions.readCountries&&form.permissions.readCountries&&form.permissions.readCoupons&&form.permissions.readCurrency&&form.permissions.readCustomer&&form.permissions.readDashboard&&form.permissions.readEmailTemplate&&form.permissions.readPlan&&form.permissions.readPlanFeatures&&form.permissions.readRegion&&form.permissions.readResellerCategory&&form.permissions.readRole&&form.permissions.readTypes&&form.permissions.readContent}/> */}
                              Read
                            </th>
                            <th scope="col" class="table_data">
                              {/* <input
                            type="checkbox" className='mr-2'  onChange={e=>HandleAllAdd(e.target.checked)} checked={form.permissions.addAdmins&&form.permissions.addBooking&&form.permissions.addCategory&&form.permissions.addCities&&form.permissions.addContent&&form.permissions.addCountries&&form.permissions.addCoupons&&form.permissions.addCurrency&&form.permissions.addCustomer&&form.permissions.addEmailTemplate&&form.permissions.addPlan&&form.permissions.addPlanFeatures&&form.permissions.addRegion&&form.permissions.addResellerCategory&&form.permissions.addRole&&form.permissions.addTypes&&form.permissions.addCategory} /> */}
                              Add
                            </th>
                            <th scope="col" class="table_data">
                              {/* <input
                            type="checkbox" className='mr-2'  onChange={e=>HandleallEdit(e.target.checked)} checked={form.permissions.editAdmins&&form.permissions.editBooking&&form.permissions.editCategory&&form.permissions.editCities&&form.permissions.editContinents&&form.permissions.editCountries&&form.permissions.editCoupons&&form.permissions.editCurrency&&form.permissions.editCustomer&&form.permissions.editEmailTemplate&&form.permissions.editPlan&&form.permissions.editPlanFeatures&&form.permissions.editRegion&&form.permissions.editResellerCategory&&form.permissions.editRole&&form.permissions.editTypes} /> */}
                              Edit
                            </th>
                            <th scope="col" class="table_data">
                              {/* <input
                            type="checkbox" className='mr-2'  onChange={e=>HandleAllDelete(e.target.checked)} checked={form.permissions.deleteAdmins&&form.permissions.deleteBooking&&form.permissions.deleteCategory&&form.permissions.deleteCities&&form.permissions.deleteContinents&&form.permissions.deleteCountries&&form.permissions.deleteCoupons&&form.permissions.deleteCurrency&&form.permissions.deleteCustomer&&form.permissions.deletePlan&&form.permissions.deletePlanFeatures&&form.permissions.deleteRegion&&form.permissions.deleteResellerCategory&&form.permissions.deleteRole&&form.permissions.deleteTypes} /> */}
                              Delete
                            </th>
                            {/* <th scope="col" class="table_data"> <input
                            type="checkbox" className='mr-2'  onChange={e=>HandleRefreshAll(e.target.checked)} checked={form.permissions.refreshBooking}/>Refresh</th> */}
                          </tr>
                        </thead>
                        <tbody className="roleTable">


                          <tr>
                            <td>
                              <b>Manage Category</b>
                            </td>
                            <td>
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  HandleCategoryPer(e.target.checked)
                                }
                                checked={
                                  form.permissions.category_add &&
                                  form.permissions.category_delete &&
                                  form.permissions.category_edit &&
                                  form.permissions.category_get
                                }
                              />
                            </td>
                            <td>
                              <label>
                                <input
                                  type="checkbox"
                                  checked={form.permissions.category_get}
                                  onChange={(e) => {
                                    setform({
                                      ...form,
                                      permissions: {
                                        ...form.permissions,
                                        category_get: e.target.checked,
                                      },
                                    });
                                  }}
                                />{' '}
                              </label>
                            </td>
                            <td>
                              <label>
                                <input
                                  type="checkbox"
                                  checked={form.permissions.category_add}
                                  onChange={(e) => {
                                    setform({
                                      ...form,
                                      permissions: {
                                        ...form.permissions,
                                        category_add: e.target.checked,
                                      },
                                    });
                                  }}
                                />{' '}
                              </label>
                            </td>
                            <td>
                              <label>
                                <input
                                  type="checkbox"
                                  checked={form?.permissions?.category_edit}
                                  onChange={(e) => {
                                    setform({
                                      ...form,
                                      permissions: {
                                        ...form.permissions,
                                        category_edit: e.target.checked,
                                      },
                                    });
                                  }}
                                />{' '}
                              </label>
                            </td>
                            <td>
                              <label>
                                <input
                                  type="checkbox"
                                  checked={form.permissions.category_delete}
                                  onChange={(e) => {
                                    setform({
                                      ...form,
                                      permissions: {
                                        ...form.permissions,
                                        category_delete: e.target.checked,
                                      },
                                    });
                                  }}
                                />{' '}
                              </label>
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>
                              <b>User Management</b>
                            </td>
                            <td>
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  handleRolesPer(e.target.checked)
                                }
                                checked={
                                  form.permissions.user_get &&
                                  form.permissions.user_add &&
                                  form.permissions.user_edit &&
                                  form.permissions.user_delete
                                }
                              />
                            </td>
                            <td>
                              <label>
                                <input
                                  type="checkbox"
                                  checked={
                                    form.permissions?.user_get
                                  }
                                  onChange={(e) => {
                                    setform({
                                      ...form,
                                      permissions: {
                                        ...form.permissions,
                                        user_get: e.target.checked,
                                      },
                                    });
                                  }}
                                />{' '}
                              </label>
                            </td>
                            <td>
                              <label>
                                <input
                                  type="checkbox"
                                  checked={form.permissions.user_add}
                                  onChange={(e) => {
                                    setform({
                                      ...form,
                                      permissions: {
                                        ...form.permissions,
                                        user_add: e.target.checked,
                                      },
                                    });
                                  }}
                                />{' '}
                              </label>
                            </td>
                            <td>
                              <label>
                                <input
                                  type="checkbox"
                                  checked={form.permissions.user_edit}
                                  onChange={(e) => {
                                    setform({
                                      ...form,
                                      permissions: {
                                        ...form.permissions,
                                        user_edit: e.target.checked,
                                      },
                                    });
                                  }}
                                />{' '}
                              </label>
                            </td>
                            <td>
                              <label>
                                <input
                                  type="checkbox"
                                  checked={form.permissions.user_delete}
                                  onChange={(e) => {
                                    setform({
                                      ...form,
                                      permissions: {
                                        ...form.permissions,
                                        user_delete: e.target.checked,
                                      },
                                    });
                                  }}
                                />{' '}
                              </label>
                            </td>
                            <td></td>
                          </tr>

                          <tr>
                            <td>
                              <b>Content Management</b>
                            </td>
                            <td>
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  handleAllContent(e.target.checked)
                                }
                                checked={


                                  form.permissions.content_get &&
                                  form.permissions.content_edit
                                }
                              />
                            </td>
                            <td>
                              <label>
                                <input
                                  type="checkbox"
                                  checked={form.permissions.content_get}
                                  onChange={(e) => {
                                    setform({
                                      ...form,
                                      permissions: {
                                        ...form.permissions,
                                        content_get: e.target.checked,
                                      },
                                    });
                                  }}
                                />
                              </label>
                            </td>
                            <td>
                              <label>

                              </label>
                            </td>
                            <td>
                              <label>
                                <input
                                  type="checkbox"
                                  checked={form.permissions.content_edit}
                                  onChange={(e) => {
                                    setform({
                                      ...form,
                                      permissions: {
                                        ...form.permissions,
                                        content_edit: e.target.checked,
                                      },
                                    });
                                  }}
                                />{' '}
                              </label>
                            </td>

                            <td></td>
                          </tr>

                          <tr>
                            <td>
                              <b>FAQ Management</b>
                            </td>
                            <td>
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  handleAllFAQ(e.target.checked)
                                }
                                checked={
                                  form.permissions.faq_add &&
                                  form.permissions.faq_edit &&
                                  form.permissions.faq_delete &&
                                  form.permissions.faq_get
                                }
                              />
                            </td>
                            <td>
                              <label>
                                <input
                                  type="checkbox"
                                  checked={form?.permissions?.faq_get}
                                  onChange={(e) => {
                                    setform({
                                      ...form,
                                      permissions: {
                                        ...form.permissions,
                                        faq_get: e.target.checked,
                                      },
                                    });
                                  }}
                                />
                              </label>
                            </td>
                            <td>
                              <label>
                                <input
                                  type="checkbox"
                                  checked={form?.permissions?.faq_add}
                                  onChange={(e) => {
                                    setform({
                                      ...form,
                                      permissions: {
                                        ...form.permissions,
                                        faq_add: e.target.checked,
                                      },
                                    });
                                  }}
                                />{' '}
                              </label>
                            </td>
                            <td>
                              <label>
                                <input
                                  type="checkbox"
                                  checked={form?.permissions?.faq_edit}
                                  onChange={(e) => {
                                    setform({
                                      ...form,
                                      permissions: {
                                        ...form.permissions,
                                        faq_edit: e.target.checked,
                                      },
                                    });
                                  }}
                                />{' '}
                              </label>
                            </td>
                            <td>
                              <label>
                                <input
                                  type="checkbox"
                                  checked={form?.permissions?.faq_delete}
                                  onChange={(e) => {
                                    setform({
                                      ...form,
                                      permissions: {
                                        ...form.permissions,
                                        faq_delete: e.target.checked,
                                      },
                                    });
                                  }}
                                />{' '}
                              </label>
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>
                              <b>Question Management</b>
                            </td>
                            <td>
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  HandleAllQuest(e.target.checked)
                                }
                                checked={
                                  form.permissions.question_get &&
                                  form.permissions.question_add &&
                                  form.permissions.question_delete &&
                                  form.permissions.question_edit
                                }
                              />
                            </td>
                            <td>
                              <label>
                                <input
                                  type="checkbox"
                                  checked={form.permissions.question_get}
                                  onChange={(e) => {
                                    setform({
                                      ...form,
                                      permissions: {
                                        ...form.permissions,
                                        question_get: e.target.checked,
                                      },
                                    });
                                  }}
                                />
                              </label>
                            </td>
                            <td>
                              <label>
                                <input
                                  type="checkbox"
                                  checked={form.permissions.question_add}
                                  onChange={(e) => {
                                    setform({
                                      ...form,
                                      permissions: {
                                        ...form.permissions,
                                        question_add: e.target.checked,
                                      },
                                    });
                                  }}
                                />{' '}
                              </label>
                            </td>
                            <td>
                              <label>
                                <input
                                  type="checkbox"
                                  checked={form.permissions.question_edit}
                                  onChange={(e) => {
                                    setform({
                                      ...form,
                                      permissions: {
                                        ...form.permissions,
                                        question_edit: e.target.checked,
                                      },
                                    });
                                  }}
                                />{' '}
                              </label>
                            </td>
                            <td>
                              <label>
                                <input
                                  type="checkbox"
                                  checked={form.permissions.question_delete}
                                  onChange={(e) => {
                                    setform({
                                      ...form,
                                      permissions: {
                                        ...form.permissions,
                                        question_delete: e.target.checked,
                                      },
                                    });
                                  }}
                                />{' '}
                              </label>
                            </td>
                            <td></td>
                          </tr>
                          {/* <tr>
                            <td>
                              <b>Payment Management</b>
                            </td>
                            <td>
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  handleAllPayment(e.target.checked)
                                }
                                checked={
                                  form.permissions.readPayment &&
                                  form.permissions.addPayment &&
                                  form.permissions.deletePayment &&
                                  form.permissions.editPayment
                                }
                              />
                            </td>
                            <td>
                              <label>
                                <input
                                  type="checkbox"
                                  checked={form.permissions.readPayment}
                                  onChange={(e) => {
                                    setform({
                                      ...form,
                                      permissions: {
                                        ...form.permissions,
                                        readPayment: e.target.checked,
                                      },
                                    });
                                  }}
                                />
                              </label>
                            </td>
                            <td>
                              <label>
                                <input
                                  type="checkbox"
                                  checked={form.permissions.addPayment}
                                  onChange={(e) => {
                                    setform({
                                      ...form,
                                      permissions: {
                                        ...form.permissions,
                                        addPayment: e.target.checked,
                                      },
                                    });
                                  }}
                                />{' '}
                              </label>
                            </td>
                            <td>
                              <label>
                                <input
                                  type="checkbox"
                                  checked={form.permissions.editPayment}
                                  onChange={(e) => {
                                    setform({
                                      ...form,
                                      permissions: {
                                        ...form.permissions,
                                        editPayment: e.target.checked,
                                      },
                                    });
                                  }}
                                />{' '}
                              </label>
                            </td>
                            <td>
                              <label>
                                <input
                                  type="checkbox"
                                  checked={form.permissions.deletePayment}
                                  onChange={(e) => {
                                    setform({
                                      ...form,
                                      permissions: {
                                        ...form.permissions,
                                        deletePayment: e.target.checked,
                                      },
                                    });
                                  }}
                                />{' '}
                              </label>
                            </td>
                            <td></td>
                          </tr> */}
                          {/* <tr>
                          <td><b>Types</b></td>
                          <td><input type="checkbox" onChange={e=>handleTypesPer(e.target.checked)}  checked={form.permissions.addTypes&&form.permissions.editTypes&&form.permissions.readTypes&&form.permissions.deleteTypes}/></td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.readTypes}
                                onChange={(e) =>
                                  setpermission('readTypes', e.target.checked)
                                }
                              />{' '}

                            </label>
                          </td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.addTypes}
                                onChange={(e) =>
                                  setpermission('addTypes', e.target.checked)
                                }
                              />{' '}

                            </label>
                          </td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.editTypes}
                                onChange={(e) =>
                                  setpermission('editTypes', e.target.checked)
                                }
                              />{' '}

                            </label>
                          </td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.deleteTypes}
                                onChange={(e) =>
                                  setpermission('deleteTypes', e.target.checked)
                                }
                              />{' '}

                            </label>
                          </td>
                          <td></td>
                        </tr> */}

                          {/* <tr>
                          <td><b>Category</b></td>
                          <td><input type="checkbox" onChange={(e) => handleallCategorycheckbox(e.target.checked)} checked={form.permissions.readCategory&&form.permissions.addCategory&&form.permissions.editCategory&&form.permissions.deleteCategory} /></td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.readCategory}
                                onChange={(e) =>
                                  setpermission('readCategory', e.target.checked)
                                }
                              />{' '}

                            </label>
                          </td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.addCategory}
                                onChange={(e) =>
                                  setpermission('addCategory', e.target.checked)
                                }
                              />{' '}

                            </label>
                          </td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.editCategory}
                                onChange={(e) =>
                                  setpermission('editCategory', e.target.checked)
                                }
                              />{' '}

                            </label>
                          </td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.deleteCategory}
                                onChange={(e) =>
                                  setpermission(
                                    'deleteCategory',
                                    e.target.checked
                                  )
                                }
                              />{' '}

                            </label>
                          </td>
                          <td></td>
                        </tr> */}

                          {/* <tr>
                          <td><b>Reseller Category</b></td>
                          <td><input type="checkbox" onChange={e=>handleResellCateg(e.target.checked)} checked={form.permissions.addResellerCategory&&form.permissions.readResellerCategory&&form.permissions.editResellerCategory&&form.permissions.deleteResellerCategory} /></td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.readResellerCategory}
                                onChange={(e) =>
                                  setpermission(
                                    'readResellerCategory',
                                    e.target.checked
                                  )
                                }
                              />{' '}

                            </label>
                          </td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.addResellerCategory}
                                onChange={(e) =>
                                  setpermission(
                                    'addResellerCategory',
                                    e.target.checked
                                  )
                                }
                              />{' '}

                            </label>
                          </td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.editResellerCategory}
                                onChange={(e) =>
                                  setpermission(
                                    'editResellerCategory',
                                    e.target.checked
                                  )
                                }
                              />{' '}

                            </label>
                          </td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.deleteResellerCategory}
                                onChange={(e) =>
                                  setpermission(
                                    'deleteResellerCategory',
                                    e.target.checked
                                  )
                                }
                              />{' '}

                            </label>
                          </td>
                          <td></td>
                        </tr> */}

                          {/* <tr>
                          <td><b>Plan Features</b></td>
                          <td><input type="checkbox" onChange={e=>handlePlanFeature(e.target.checked)}  checked={form.permissions.addPlanFeatures&&form.permissions.readPlanFeatures&&form.permissions.editPlanFeatures&&form.permissions.deletePlanFeatures}/></td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.readPlanFeatures}
                                onChange={(e) =>
                                  setpermission('readPlanFeatures', e.target.checked)
                                }
                              />{' '}

                            </label>
                          </td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.addPlanFeatures}
                                onChange={(e) =>
                                  setpermission('addPlanFeatures', e.target.checked)
                                }
                              />{' '}

                            </label>
                          </td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.editPlanFeatures}
                                onChange={(e) =>
                                  setpermission('editPlanFeatures', e.target.checked)
                                }
                              />{' '}

                            </label>
                          </td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.deletePlanFeatures}
                                onChange={(e) =>
                                  setpermission(
                                    'deletePlanFeatures',
                                    e.target.checked
                                  )
                                }
                              />{' '}

                            </label>
                          </td>
                          <td></td>
                        </tr> */}

                          {/* <tr>
                          <td><b>Plan</b></td>
                          <td><input type="checkbox" onChange={e=>handlePlanPre(e.target.checked)} checked={form.permissions.readPlan&&form.permissions.addPlan&&form.permissions.editPlan&&form.permissions.deletePlan} /></td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.readPlan}
                                onChange={(e) =>
                                  setpermission('readPlan', e.target.checked)
                                }
                              />{' '}

                            </label>
                          </td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.addPlan}
                                onChange={(e) =>
                                  setpermission('addPlan', e.target.checked)
                                }
                              />{' '}

                            </label>
                          </td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.editPlan}
                                onChange={(e) =>
                                  setpermission('editPlan', e.target.checked)
                                }
                              />{' '}

                            </label>
                          </td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.deletePlan}
                                onChange={(e) =>
                                  setpermission(
                                    'deletePlan',
                                    e.target.checked
                                  )
                                }
                              />{' '}

                            </label>
                          </td>
                          <td></td>
                        </tr> */}

                          {/* <tr>
                          <td><b>Coupons</b></td>
                          <td><input type="checkbox" onChange={e=>handleCoupons(e.target.checked)} checked={form.permissions.readCoupons&&form.permissions.addCoupons&&form.permissions.editCoupons&&form.permissions.deleteCoupons} /></td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.readCoupons}
                                onChange={(e) =>
                                  setpermission('readCoupons', e.target.checked)
                                }
                              />{' '}

                            </label>
                          </td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.addCoupons}
                                onChange={(e) =>
                                  setpermission('addCoupons', e.target.checked)
                                }
                              />{' '}

                            </label>
                          </td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.editCoupons}
                                onChange={(e) =>
                                  setpermission('editCoupons', e.target.checked)
                                }
                              />{' '}

                            </label>
                          </td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.deleteCoupons}
                                onChange={(e) =>
                                  setpermission(
                                    'deleteCoupons',
                                    e.target.checked
                                  )
                                }
                              />{' '}

                            </label>
                          </td>
                          <td></td>
                        </tr> */}

                          {/* <tr>
                          <td><b>Currency</b></td>
                          <td><input type="checkbox" onChange={e=>handleCurrencyPre(e.target.checked)} checked={form.permissions.readCurrency&&form.permissions.addCurrency&&form.permissions.editCurrency&&form.permissions.deleteCurrency} /></td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.readCurrency}
                                onChange={(e) =>
                                  setpermission('readCurrency', e.target.checked)
                                }
                              />{' '}

                            </label>
                          </td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.addCurrency}
                                onChange={(e) =>
                                  setpermission('addCurrency', e.target.checked)
                                }
                              />{' '}

                            </label>
                          </td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.editCurrency}
                                onChange={(e) =>
                                  setpermission('editCurrency', e.target.checked)
                                }
                              />{' '}

                            </label>
                          </td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.deleteCurrency}
                                onChange={(e) =>
                                  setpermission(
                                    'deleteCurrency',
                                    e.target.checked
                                  )
                                }
                              />{' '}

                            </label>
                          </td>
                          <td></td>
                        </tr> */}

                          {/* <tr>
                          <td><b>Booking System</b></td>
                          <td><input type="checkbox" onChange={e=>handleBookingPre(e.target.checked)} checked={form.permissions.addBooking&&form.permissions.editBooking&&form.permissions.readBooking&&form.permissions.deleteBooking&&form.permissions.refreshBooking} /></td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.readBooking}
                                onChange={(e) =>
                                  setpermission('readBooking', e.target.checked)
                                }
                              />{' '}

                            </label>
                          </td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.addBooking}
                                onChange={(e) =>
                                  setpermission('addBooking', e.target.checked)
                                }
                              />{' '}

                            </label>
                          </td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.editBooking}
                                onChange={(e) =>
                                  setpermission('editBooking', e.target.checked)
                                }
                              />{' '}

                            </label>
                          </td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.deleteBooking}
                                onChange={(e) =>
                                  setpermission(
                                    'deleteBooking',
                                    e.target.checked
                                  )
                                }
                              />{' '}

                            </label>
                          </td>
                          <td>
                            <label>
                              <span className='mr-2'>
                                <input
                                  type="checkbox"
                                  checked={form.permissions.refreshBooking}
                                  onChange={(e) =>
                                    setpermission(
                                      'refreshBooking',
                                      e.target.checked
                                    )
                                  }
                                />
                              </span>

                            </label>
                          </td>
                        </tr> */}

                          {/* <tr>
                          <td><b>Continents</b></td>
                          <td><input type="checkbox" onChange={e=>HandleContinent(e.target.checked)}  checked={form.permissions.readContinents&&form.permissions.addContinents&&form.permissions.editContinents&&form.permissions.deleteContinents}/></td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.readContinents}
                                onChange={(e) =>
                                  setpermission('readContinents', e.target.checked)
                                }
                              />{' '}

                            </label>
                          </td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.addContinents}
                                onChange={(e) =>
                                  setpermission('addContinents', e.target.checked)
                                }
                              />{' '}

                            </label>
                          </td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.editContinents}
                                onChange={(e) =>
                                  setpermission('editContinents', e.target.checked)
                                }
                              />{' '}

                            </label>
                          </td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.deleteContinents}
                                onChange={(e) =>
                                  setpermission(
                                    'deleteContinents',
                                    e.target.checked
                                  )
                                }
                              />{' '}

                            </label>
                          </td>
                          <td></td>
                        </tr> */}

                          {/* <tr>
                          <td><b>Countries</b></td>
                          <td><input type="checkbox"  onChange={e=>handleCountriuesPre(e.target.checked)} checked={form.permissions.readCountries&&form.permissions.addCountries&&form.permissions.editCountries&&form.permissions.deleteCountries}/></td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.readCountries}
                                onChange={(e) =>
                                  setpermission('readCountries', e.target.checked)
                                }
                              />{' '}

                            </label>
                          </td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.addCountries}
                                onChange={(e) =>
                                  setpermission('addCountries', e.target.checked)
                                }
                              />{' '}

                            </label>
                          </td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.editCountries}
                                onChange={(e) =>
                                  setpermission('editCountries', e.target.checked)
                                }
                              />{' '}

                            </label>
                          </td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.deleteCountries}
                                onChange={(e) =>
                                  setpermission(
                                    'deleteCountries',
                                    e.target.checked
                                  )
                                }
                              />{' '}

                            </label>
                          </td>
                          <td></td>
                        </tr> */}

                          {/* <tr>
                          <td><b>Region</b></td>
                          <td><input type="checkbox" onChange={e=>HandleRegionPre(e.target.checked)}  checked={form.permissions.readRegion&&form.permissions.addRegion&&form.permissions.editRegion&&form.permissions.deleteRegion}/></td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.readRegion}
                                onChange={(e) =>
                                  setpermission('readRegion', e.target.checked)
                                }
                              />{' '}

                            </label>
                          </td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.addRegion}
                                onChange={(e) =>
                                  setpermission('addRegion', e.target.checked)
                                }
                              />{' '}

                            </label>
                          </td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.editRegion}
                                onChange={(e) =>
                                  setpermission('editRegion', e.target.checked)
                                }
                              />{' '}

                            </label>
                          </td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.deleteRegion}
                                onChange={(e) =>
                                  setpermission(
                                    'deleteRegion',
                                    e.target.checked
                                  )
                                }
                              />{' '}

                            </label>
                          </td>
                          <td></td>
                        </tr> */}
                          {/* <tr>
                          <td><b>Cities</b></td>
                          <td><input type="checkbox" onChange={e=>HandleCitiesPre(e.target.checked)} checked={form.permissions.readCities&&form.permissions.addCities&&form.permissions.editCities&&form.permissions.deleteCities} /></td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.readCities}
                                onChange={(e) =>
                                  setpermission('readCities', e.target.checked)
                                }
                              />{' '}

                            </label>
                          </td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.addCities}
                                onChange={(e) =>
                                  setpermission('addCities', e.target.checked)
                                }
                              />{' '}

                            </label>
                          </td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.editCities}
                                onChange={(e) =>
                                  setpermission('editCities', e.target.checked)
                                }
                              />{' '}

                            </label>
                          </td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.deleteCities}
                                onChange={(e) =>
                                  setpermission(
                                    'deleteCities',
                                    e.target.checked
                                  )
                                }
                              />{' '}

                            </label>
                          </td>
                          <td></td>
                        </tr> */}
                          {/* <tr>
                          <td><b>Email Template</b></td>
                          <td><input type="checkbox" onChange={e=>handleEmailPre(e.target.checked)} checked={form.permissions.readEmailTemplate&&form.permissions.editEmailTemplate&&form.permissions.addEmailTemplate} /></td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.readEmailTemplate}
                                onChange={(e) =>
                                  setpermission('readEmailTemplate', e.target.checked)
                                }
                              />{' '}

                            </label>
                          </td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.addEmailTemplate}
                                onChange={(e) =>
                                  setpermission('addEmailTemplate', e.target.checked)
                                }
                              />{' '}

                            </label>
                          </td>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                checked={form.permissions.editEmailTemplate}
                                onChange={(e) =>
                                  setpermission('editEmailTemplate', e.target.checked)
                                }
                              />{' '}

                            </label>
                          </td>
                          <td></td>
                          <td></td>
                        </tr> */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>}

          <div className="text-right">
            <Link to="/allusers" className="btn btn-secondary discard mr-2">
              Back
            </Link>
            <button type="submit" className="btn btn-primary ">
              Save
            </button>
          </div>
        </div>

      </form>
      </Layout>
    </>
  );
}

export default AddEdit;
