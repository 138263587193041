// import React from 'react'


// function AddEditPlan() {
//   return (
//    <>
//    <Layout>
//     <div className="d-flex justify-content-between mb-3">
//     <h3 className="ViewUser mb-3">
//               Add Plan
//             </h3>
//     </div>
//    </Layout>
//    </>
//   )
// }

// export default AddEditPlan
import React, { useState, useEffect, useRef } from "react";
import { ToastsStore } from "react-toasts";

import Layout from '../../components/global/layout'
import { useSelector } from 'react-redux';


import { Link, useHistory, useParams } from "react-router-dom";



// import requiredModel from "../../models/required.model";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import methodModel from "../../methods/methods";
import stateModel from "../../models/states.model";
import { planType } from "../../models/type.model";
import { Editor } from "@tinymce/tinymce-react";

const CatView = () => {
  const [features, setFeatures] = useState([])
  const [currencys, setCurrencys] = useState([])

  const defaultvalue = () => {
    let keys = { ...planType }
    Object.keys(planType).map(itm => {
      keys[itm] = ''
    })
    keys.status = 'active'
    return keys
  }
  const { id, copy } = useParams()
  const [form, setform] = useState(planType);
  const [checkedItems, setCheckedItems] = useState([]);
  const [startIndex, setStartIndex] = useState(-1);
  const [enterIndex, setEnterIndex] = useState(-1);
  const [selectedItem, setSelectedItem] = useState('');
  const [pricing, setPricing] = useState([]);
  const history = useHistory()
  const [submitted, setSubmitted] = useState(false)
  const user = useSelector((state) => state.user);
  const dragItem = useRef();
  const dragItems = useRef();
  const dragOverItem = useRef();
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([])
  let fetrs = [];
  useEffect(() => {
    setform({ ...form, feature: fetrs })

  }, [])
  const formValidation = [
    // { key: 'feature', required: true },
    { key: 'status', required: true },
    { key: 'recommended', required: true },
  ]
  const getData = (p = {}) => {
    setLoader(true)


    if (id) {
      ApiClient.get('category', { id: id }).then((res) => {
        if (res.success) {
          setform(res?.data)
        }
      })
    }

  }

  useEffect(() => {
    getData()
  }, [])
  // const trailPeriodDays = [
  //   { id: 1, name: '1' },
  //   { id: 2, name: '2' },
  //   { id: 3, name: '3' },
  //   { id: 4, name: '4' },
  //   { id: 5, name: '5' },
  //   { id: 6, name: '6' },
  //   { id: 7, name: '7' },
  //   { id: 8, name: '8' },
  //   { id: 9, name: '9' },
  //   { id: 10, name: '10' },
  //   { id: 11, name: '11' },
  //   { id: 12, name: '12' },
  //   { id: 13, name: '13' },
  //   { id: 14, name: '14' },
  //   { id: 15, name: '15' },
  //   { id: 16, name: '16' },
  //   { id: 17, name: '17' },
  //   { id: 18, name: '18' },
  //   { id: 19, name: '19' },
  //   { id: 20, name: '20' },
  //   { id: 21, name: '21' },
  //   { id: 22, name: '22' },
  //   { id: 23, name: '23' },
  //   { id: 24, name: '24' },
  //   { id: 25, name: '25' },
  //   { id: 26, name: '26' },
  //   { id: 27, name: '27' },
  //   { id: 28, name: '28' },
  //   { id: 29, name: '29' },
  //   { id: 30, name: '30' },
  // ]

  // const selectfeatures = (value, key, index) => {
  //   if (checkedItems.includes(value)) {
  //     const updatedCheckedItems = checkedItems.filter(item => item !== value);
  //     setCheckedItems(updatedCheckedItems);
  //   }
  //   else {
  //     setCheckedItems([...checkedItems, value]);
  //   }

  //   let checked = features[key][index].checked
  //   features[key][index].checked = checked ? false : true
  // }

  const handleSubmit = (e) => {
    e.preventDefault()

    let value = {
      id: form.id,
      name: form.name,
      description: form.description,
      cat_type: form.cat_type
    }

    if (form.translate) value.translate = form.translate
    if (form.descriptionTranslation) value.descriptionTranslation = form.descriptionTranslation

    let method = 'post'
    if (value.id) {
      method = 'put'
    } else {
      delete value.id
    }


    ApiClient.allApi('category', value, method).then(res => {
      if (res.success) {
        ToastsStore.success(res.message)
        history.goBack()


      }

    })
  }




  return <>
    <Layout>
      <form onSubmit={handleSubmit}>
        <div className="pprofile1">
          <h3 className="ViewUser mb-3">{id ? 'Edit' : 'Add'} Category</h3>
          <div className="form-row">
            <div className="col-md-5 mb-1">
              <label>Name<span className='star'>*</span></label>
              <input placeholder="Enter Name Here" type="text" className="form-control textcpitlize" value={form.name} onChange={e => setform({ ...form, name: e.target.value })} required />
            </div>
            <div className="col-md-6 mb-3">
              <label>Type<span className="star">*</span></label>
              <select className="form-control"
                value={form.cat_type}

                onChange={e => { setform({ ...form, cat_type: e.target.value, }); }}

                required
              >
                <option value="">Select Type</option>
                <option value="Questions">Questions</option>
                <option value="FAQ">FAQ</option>
              </select>
            </div>
            <div className="col-md-8 mb-3">
              <label>Description <span className="text-danger">*</span></label>

              <Editor
              apiKey='9d1tq05iap0fc1t1elmkniuvs8towqjzitfjo4e0rcwic2h6'
                value={form.description ? form.description : ''}
                onEditorChange={(e) => {
                  setform({ ...form, description: e })
                }}
                required
                init={{
                  height: 200,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],
                  toolbar: 'undo redo | formatselect | ' +
                    'bold italic backcolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
              />



            </div>


          </div>

          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => {
              history.goBack()
              setform({ name: '', cat_type: '', description: '' })
            }}>Close</button>
            <button type="submit" className="btn btn-primary">{form && form.id ? 'Update' : 'Create'}</button>
          </div>
        </div>
      </form>
    </Layout>
  </>
}

export default CatView