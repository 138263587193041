import React, { useEffect, useState } from 'react'
import Layout from '../../components/global/layout'
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import ApiClient from '../../methods/api/apiClient'
import datepipeModel from '../../models/datepipemodel'
import dateFormat from 'dateformat'
import methodModel from '../../methods/methods'

function ViewPayment() {
    const { id } = useParams()
    const [data, setdata] = useState([])
    const [loader, setloader] = useState(true)
    useEffect(() => {
        setloader(true)
        ApiClient.get('transaction', { id: id }).then((res) => {
            setdata(res?.data)


        })



        setTimeout(() => {

            setloader(false)
        }, 500);
    }, [])
    console.log(data)
    return (
        <Layout>
            <div className='text-right mb-3'><Link to="/payments"><i className="fa fa-arrow-left" title='Back' aria-hidden="true"></i></Link></div>
            <h3 className='ViewUser'>View Payment</h3>

            <div className="viewmainlists ">

                {
                    loader ? <div className="text-center py-4">
                        <img src="/assets/img/loader.gif" className="pageLoader" />
                    </div> :
                        <>


                            <div className='pprofile1 mb-3'>
                                <h3 className='ViewUser'>User  Details</h3>
                                <div className='table_admin'>
                                    <div className='row mb-3'>
                                        <div className='col-12 col-sm-12 col-md-3'>
                                            <h5 className='headmain'> User Name</h5>
                                        </div>
                                        <div className='col-12 col-sm-12 col-md-9'>
                                            <h6 className='subhead'>{methodModel.capitalizeName(data?.user_id?.fullName)}</h6>
                                        </div>
                                    </div>

                                    <div className='row mb-3'>
                                        <div className='col-12 col-sm-12 col-md-3'>
                                            <h5 className='headmain'> User Email</h5>
                                        </div>
                                        <div className='col-12 col-sm-12 col-md-9'>
                                            <h6 className='subhead'>{data?.user_id?.email}</h6>
                                        </div>
                                    </div>
                                    <div className='row mb-3'>
                                        <div className='col-12 col-sm-12 col-md-3'>
                                            <h5 className='headmain'> User Phone</h5>
                                        </div>
                                        <div className='col-12 col-sm-12 col-md-9'>
                                            <h6 className='subhead'>{data?.user_id?.mobileNo}</h6>
                                        </div>
                                    </div>



                                </div>
                            </div>

                            <div className='pprofile1 mb-3'>

                                <h3 className='ViewUser'>Plan Details</h3>
                                <div className='table_admin'>
                                    <div className='row mb-3'>
                                        <div className='col-12 col-sm-12 col-md-3'>
                                            <h5 className='headmain'> Plan Name</h5>
                                        </div>
                                        <div className='col-12 col-sm-12 col-md-9'>
                                            <h6 className='subhead'>{data?.subscription_plan_id?.name}</h6>
                                        </div>
                                    </div>

                                    <div className='row mb-3'>
                                        <div className='col-12 col-sm-12 col-md-3'>
                                            <h5 className='headmain'>Price</h5>
                                        </div>
                                        <div className='col-12 col-sm-12 col-md-9'>
                                            <h6 className='subhead'>{data?.subscription_plan_id?.price}$</h6>
                                        </div>
                                    </div>

                                </div>

                            </div>


                            <div className='pprofile1 mb-3'>
                                <h3 className='ViewUser'>Payment Details</h3>
                                <div className='table_admin'>
                                    <div className='row mb-3'>
                                        <div className='col-12 col-sm-12 col-md-3'>
                                            <h5 className='headmain'>Payment Method</h5>
                                        </div>
                                        <div className='col-12 col-sm-12 col-md-9'>
                                            <h6 className='subhead'>{methodModel.capitalizeName(data?.payment_method)}</h6>
                                        </div>
                                    </div>
                                    <div className='row mb-3'>
                                        <div className='col-12 col-sm-12 col-md-3'>
                                            <h5 className='headmain'>Card Number</h5>
                                        </div>
                                        <div className='col-12 col-sm-12 col-md-9'>
                                            <h6 className='subhead'>******{data?.card_id?.last4}</h6>
                                        </div>
                                    </div>
                                    <div className='row mb-3'>
                                        <div className='col-12 col-sm-12 col-md-3'>
                                            <h5 className='headmain'>Card Type</h5>
                                        </div>
                                        <div className='col-12 col-sm-12 col-md-9'>
                                            <h6 className='subhead'>{data?.card_id?.brand}</h6>
                                        </div>
                                    </div>
                                    <div className='row mb-3'>
                                        <div className='col-12 col-sm-12 col-md-3'>
                                            <h5 className='headmain'>Expiration Date</h5>
                                        </div>
                                        <div className='col-12 col-sm-12 col-md-9'>
                                            <h6 className='subhead'>{data?.card_id?.exp_month}/{data?.card_id?.exp_year}</h6>
                                        </div>
                                    </div>
                                    <div className='row mb-3'>
                                        <div className='col-12 col-sm-12 col-md-3'>
                                            <h5 className='headmain'>Transaction Status</h5>
                                        </div>
                                        <div className='col-12 col-sm-12 col-md-9'>
                                            <h6 className='subhead'>{methodModel.capitalizeName(data?.transaction_status)}</h6>
                                        </div>
                                    </div>
                                    <div className='row mb-3'>
                                        <div className='col-12 col-sm-12 col-md-3'>
                                            <h5 className='headmain'>Transaction Id</h5>
                                        </div>
                                        <div className='col-12 col-sm-12 col-md-9'>
                                            <h6 className='subhead'>{data?.transaction_id}</h6>
                                        </div>
                                    </div>
                                    <div className='row mb-3'>
                                        <div className='col-12 col-sm-12 col-md-3'>
                                            <h5 className='headmain'>Transaction Date</h5>
                                        </div>
                                        <div className='col-12 col-sm-12 col-md-9'>
                                            <h6 className='subhead'>{dateFormat(data?.createdAt, 'dd-mmm-yyyy | h:MM TT')}</h6>
                                        </div>
                                    </div>



                                </div>
                            </div>

                        </>}



            </div>





        </Layout>
    )
}

export default ViewPayment
