const ApiKey = {
  api: 'https://api.incogtherapy.com/',
};
if (window.location.protocol != "https:") {
ApiKey.api="https://api.incogtherapy.com/"

}



export default ApiKey;
