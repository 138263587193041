import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../components/global/layout';
import ApiClient from '../../methods/api/apiClient';
import loader from '../../methods/loader';
import './profile.scss'
import { useSelector } from 'react-redux';
import methodModel from '../../methods/methods';


const Profile = () => {

  const user = useSelector((state) => state.user);
  const [data, setData] = useState('');
  console.log(user)
  const gallaryData = () => {
    loader(true)
    ApiClient.get(`admin/profile`).then(res => {
      if (res.success) {
        setData(res.data)
      }
      loader(false)

    })
  };

  useEffect(
    () => {
      if (user && user.loggedIn) {
        gallaryData();
      }
    },
    []
  );

  return (
    <Layout>
      <div className="d-flex justify-content-between align-items-center mb-3 main_title">
        <h3 className='hedding'>View User</h3>
        <Link to="/profile/edit" className="btn btn-primary">
          <i className="fa fa-edit" title='Edit Profile' />
        </Link>
      </div>
      <div className='pprofile1'>
        <div className="form-row">
          <div className="col-md-12 text-center mb-3">
            <label className="profileImageLabel">
              <img src={methodModel.userImg(user.image)} className="profileImage" />
            </label>
          </div>
          <div className="col-md-6">
            <label>Full Name</label>
            <p className="bg-light rounded px-3 py-2 fieldcls">{user.firstName} {user?.lastName ? user?.lastName : null}</p>
          </div>


          {/* <div className="col-md-6">
          <label>Role</label>
          <p className="bg-light rounded px-3 py-2">{data && data.role}</p>
        </div> */}

          <div className="col-md-6">
            <label>Email</label>
            <p className="bg-light rounded px-3 py-2 fieldcls">{user.email}</p>
          </div>


          <div className="col-md-6">
            <label>Mobile No</label>
            <p className="bg-light rounded px-3 py-2 fieldcls">{data.dialCode + user.mobileNo || "--"}</p>
          </div>
          <div className="col-md-6">
            <label>Role</label>
            <p className="bg-light rounded px-3 py-2 fieldcls">{data.role || "Admin"}</p>
          </div>
          <div className="col-md-6">
            <label>Address</label>
            <p className="bg-light rounded px-3 py-2 fieldcls">{data.address}</p>
          </div>
          <div className="col-md-6">
            <label>City</label>
            <p className="bg-light rounded px-3 py-2 fieldcls">{data.city}</p>
          </div>
          <div className="col-md-6">
            <label>State</label>
            <p className="bg-light rounded px-3 py-2 fieldcls">{data.state}</p>
          </div>
          <div className="col-md-6">
            <label>Company Name</label>
            <p className="bg-light rounded px-3 py-2 fieldcls">{data.companyName}</p>
          </div>
          
         
          <div className="col-md-6">
            <label>Fax Number</label>
            <p className="bg-light rounded px-3 py-2 fieldcls">{data.faxNo}</p>
          </div>
        
          {/* <div className="col-md-6">
            <label>Service Type</label>
           {data?.type_of_services?.map((itm)=>{
              return(
                <p className="bg-light rounded px-3 py-2 fieldcls">{itm}</p>
              )
            })}
          </div> */}
          
          <div className="col-md-6">
            <label>Specialities</label>
           {data?.specialities?.map((itm)=>{
              return(
                <p className="bg-light rounded px-3 py-2 fieldcls">{itm}</p>
              )
            })}
          </div>
          <div className="col-md-6">
            <label>Session Type</label>
           {data?.type_of_session?.map((itm)=>{
              return(
                <p className="bg-light rounded px-3 py-2 fieldcls">{itm}</p>
              )
            })}
          </div>
          <div className="col-md-6">
            <label>Service Type</label>
           {data?.type_of_services?.map((itm)=>{
              return(
                <p className="bg-light rounded px-3 py-2 fieldcls">{itm}</p>
              )
            })}
          </div>
         
          
          {/* <div className="col-md-12">
            <label>Created At</label>
            <p className="bg-light rounded px-3 py-2">{data && data.createdAt}</p>
          </div> */}
        </div>
      </div>

    </Layout>
  );
};

export default Profile;
