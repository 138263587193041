import React, { useState, useEffect } from "react";
import { ToastsStore } from "react-toasts";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { useSelector } from 'react-redux';
import methodModel from "../../methods/methods";
import { Editor } from "@tinymce/tinymce-react";

const AddEditPage = ({ formData, form, setform, modalClosed, setSubmitted, submitted, emailErr, setEmailErr, icErr, setIcErr }) => {
    const [loading, setLoading] = useState(false);
    const user = useSelector((state) => state.user);
    const formValidation = [
        { key: 'mobileNo', minLength: 9 },
        { key: 'ic_number', minLength: 6 },
        { key: 'dialCode', dialCode: true },
    ]

    const getError = (key) => {
        return methodModel.getError(key, form, formValidation)
    }
console.log(form)
    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitted(true)
        let invalid = methodModel.getFormError(formValidation, form)
        if (invalid || emailErr || icErr) return
        let method = 'post'
        let url = 'add/content'
        let value = {
            id:form?.id,
           title:form?.title,meta_title:form?.meta_title,meta_description:form?.meta_description,page_name:form.page_name,description:form?.description

        }
        // if (!value.ic_number) delete value.ic_number
        if (value.id) {
            method = 'put'
            url = 'content'
            delete value.password
        } else {
            delete value.id
        }

        loader(true)
        ApiClient.allApi(url, value, method).then(res => {
            if (res.success) {
                ToastsStore.success(res.message)
                document.getElementById('closeuserModal').click()
                modalClosed()
            }

            loader(false)
        })
    }

    const checkEmail = (e) => {
        setEmailErr('')
        setLoading(true)
        ApiClient.get('check/email', { email: e }, '', true).then(res => {
            if (!res.success) {

                if (res.error.message == 'Email already taken') {
                    if (formData.email != e) setEmailErr(res.error.message)
                }
            }
            setLoading(false)
        })
    }

    const checkIc = (e) => {
        setIcErr('')
        setLoading(true)
        ApiClient.get('check/icnumber', { ic_number: e }, '', true).then(res => {
            if (!res.success) {
                if (formData.ic_number != e) setIcErr(res.error.message)
            }
            setLoading(false)
        })
    }


  

    useEffect(
        () => {
            if (user && user.loggedIn) {

            }
        },
        []
    );

    return <>
        <a id="openuserModal" data-toggle="modal" data-target="#userModal"></a>
        <div className="modal fade" id="userModal" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-md">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{form && form.id ? 'Edit' : 'Add'} Content Page</h5>
                        <button type="button" id="closeuserModal" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="modal-body">
                            <div className="form-row">
                                <div className="col-md-6 mb-3">
                                    <label>Title<span className="star">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={form.title}
                                        onChange={e => setform({ ...form, title: e.target.value })}
                                        required
                                    />


                                </div>

                                <div className="col-md-6 mb-3">
                                    <label>Meta Title<span className="star">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={form.meta_title}
                                        onChange={e => setform({ ...form, meta_title: e.target.value })}
                                        required
                                    />


                                </div>




                                {form.role != 'Owner' ? <>
                                    {/* <div className="col-md-6 mb-3">
                                        <label>Category<span className="star">*</span></label>
                                        <select className="form-control"
                                            value={form && form.category}
                                            onChange={e => { setform({ ...form, category: e.target.value,}); }}
                                            // disabled={form.id ? true : false}
                                            required
                                        >
                                            <option value="">Select Category</option>
                                            <option value="Malaysian">Premium</option>
                                            <option value="Non-Malaysian">Advanced</option>
                                        </select>
                                    </div> */}


                                    <div className="col-md-6 mb-3">
                                        <label>Description<span className="star">*</span></label>
                                        <Editor
                                        apiKey='9d1tq05iap0fc1t1elmkniuvs8towqjzitfjo4e0rcwic2h6'
                                        value={form.description ? form.description : ''}
                                        onEditorChange={(e) => {
                                            setform({ ...form, description: e })
                                        }}
                                        required
                                        init={{
                                            height: 200,
                                            menubar: false,
                                            plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount'
                                            ],
                                            toolbar: 'undo redo | formatselect | ' +
                                                'bold italic backcolor | alignleft aligncenter ' +
                                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                                'removeformat | help',
                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                        }}
                                    />

                                      
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label>Meta Description<span className="star">*</span></label>
                                        <textarea
                                        className="form-control"
                                        value={form.meta_description}
                                        onChange={(e) => {
                                            setform({ ...form, meta_description: e.target.value })
                                        }}
                                        required
                                        // init={{
                                        //     height: 200,
                                        //     menubar: false,
                                        //     plugins: [
                                        //         'advlist autolink lists link image charmap print preview anchor',
                                        //         'searchreplace visualblocks code fullscreen',
                                        //         'insertdatetime media table paste code help wordcount'
                                        //     ],
                                        //     toolbar: 'undo redo | formatselect | ' +
                                        //         'bold italic backcolor | alignleft aligncenter ' +
                                        //         'alignright alignjustify | bullist numlist outdent indent | ' +
                                        //         'removeformat | help',
                                        //     content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                        // }}
                                    />
                                    </div>

                                  
                                    <div className="col-md-6 mb-3">
                                        <label>Page Name<span className="star">*</span></label>
                                        <input disabled={form?.id?true:false}
                                            type="text"
                                            className="form-control"
                                            value={form.page_name}
                                            onChange={e => setform({ ...form, page_name: e.target.value })}
                                            required
                                        />
                                    </div>
                                </> : <></>}



                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="submit" className="btn btn-primary" disabled={loading}>{form?.id ? 'Update' : 'Add'}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
}

export default AddEditPage