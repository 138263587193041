
import React, { useState, useEffect, useRef } from "react";
import { ToastsStore } from "react-toasts";

import Layout from '../../components/global/layout'
import { useSelector } from 'react-redux';


import { Link, useHistory, useParams } from "react-router-dom";

import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import methodModel from "../../methods/methods";
import stateModel from "../../models/states.model";
import { planType } from "../../models/type.model";

const PermissionView = () => {
    const [features, setFeatures] = useState({})
    const [currencys, setCurrencys] = useState([])

    const defaultvalue = () => {
        let keys = { ...planType }
        Object.keys(planType).map(itm => {
            keys[itm] = ''
        })
        keys.status = 'active'
        return keys
    }
    const { id, copy } = useParams()
    const [form, setform] = useState(planType);
    const [checkedItems, setCheckedItems] = useState([]);
    const [startIndex, setStartIndex] = useState(-1);
    const [enterIndex, setEnterIndex] = useState(-1);
    const [selectedItem, setSelectedItem] = useState('');
    const [pricing, setPricing] = useState([]);
    const history = useHistory()
    const [submitted, setSubmitted] = useState(false)
    const user = useSelector((state) => state.user);
    const dragItem = useRef();
    const dragItems = useRef();
    const dragOverItem = useRef();
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState([])
    let fetrs = [];
    useEffect(() => {
        setform({ ...form, feature: fetrs })

    }, [])
    const formValidation = [
        // { key: 'feature', required: true },
        { key: 'status', required: true },
        { key: 'recommended', required: true },
    ]
    const getData = (p = {}) => {
        setLoader(true)

        ApiClient.get('user/detail', { id: id }).then(res => {
            if (res.success) {

                setData(res.data)
                setform(res?.data)
            }
            setLoader(false)
        })
        if (id) {
            ApiClient.get('user/detail', { id: id }).then((res) => {
                if (res.success) {
                    setform(res?.data)
                    setFeatures(res?.permissions)
                }
            })
        }

    }

    useEffect(() => {
        getData()
    }, [])
    // const trailPeriodDays = [
    //   { id: 1, name: '1' },
    //   { id: 2, name: '2' },
    //   { id: 3, name: '3' },
    //   { id: 4, name: '4' },
    //   { id: 5, name: '5' },
    //   { id: 6, name: '6' },
    //   { id: 7, name: '7' },
    //   { id: 8, name: '8' },
    //   { id: 9, name: '9' },
    //   { id: 10, name: '10' },
    //   { id: 11, name: '11' },
    //   { id: 12, name: '12' },
    //   { id: 13, name: '13' },
    //   { id: 14, name: '14' },
    //   { id: 15, name: '15' },
    //   { id: 16, name: '16' },
    //   { id: 17, name: '17' },
    //   { id: 18, name: '18' },
    //   { id: 19, name: '19' },
    //   { id: 20, name: '20' },
    //   { id: 21, name: '21' },
    //   { id: 22, name: '22' },
    //   { id: 23, name: '23' },
    //   { id: 24, name: '24' },
    //   { id: 25, name: '25' },
    //   { id: 26, name: '26' },
    //   { id: 27, name: '27' },
    //   { id: 28, name: '28' },
    //   { id: 29, name: '29' },
    //   { id: 30, name: '30' },
    // ]

    // const selectfeatures = (value, key, index) => {
    //   if (checkedItems.includes(value)) {
    //     const updatedCheckedItems = checkedItems.filter(item => item !== value);
    //     setCheckedItems(updatedCheckedItems);
    //   }
    //   else {
    //     setCheckedItems([...checkedItems, value]);
    //   }

    //   let checked = features[key][index].checked
    //   features[key][index].checked = checked ? false : true
    // }

    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitted(true)


        let method = 'post'
        let url = 'plan'
        let value = {
            name: form.name, price: form.price, id: form.id, features: fetrs
        }

        if (value.id) {
            method = 'put'
            url = 'plan'
            delete value.password
        } else {
            delete value.id
        }

        // loader(true)
        ApiClient.allApi(url, value, method).then(res => {
            if (res.success) {
                ToastsStore.success(res.message)
                // document.getElementById('closeuserModal').click()
                // modalClosed()
                history.goBack()
            }

            // loader(false)
        })
    }
    console.log(form)



    return <>
        <Layout>
            <form onSubmit={handleSubmit}>
                <div className="pprofile1">
                    <h3 className="ViewUser mb-3"> View Permission</h3>
                    <div className="form-row">
                        <div className="col-md-6 mb-3">
                            <label>Name<span className="star">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                value={form.fullName}
                                disabled
                                onChange={e => {
                                    setform({ ...form, name: e.target.value })
                                    console.log(form)
                                }}
                                required
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>Name<span className="star">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                value={form.email}
                                disabled
                                onChange={e => {
                                    setform({ ...form, name: e.target.value })
                                    console.log(form)
                                }}
                                required
                            />
                        </div>

                        <div className="col-md-12 mb-3">
                            <hr className="borderHr" />
                            <h5 className="monthTerm">Permissions</h5>




                            <div className="row">
                                {features?.user_add ?
                                    <label className="ml-3" >Add User</label> : null
                                }

                            </div>
                            <div className="row">
                                {features?.user_edit ?
                                    <label className="ml-3" >Edit User</label> : null
                                }

                            </div>   <div className="row">
                                {features?.user_delete ?
                                    <label className="ml-3" >Delete User</label> : null
                                }

                            </div>   <div className="row">
                                {features?.user_get ?
                                    <label className="ml-3" >Read User</label> : null
                                }

                            </div>
                            <div className="row">
                                {features?.category_add ?
                                    <label className="ml-3" >Add Category</label> : null
                                }

                            </div>
                            <div className="row">
                                {features?.category_edit ?
                                    <label className="ml-3" >Edit Category</label> : null
                                }

                            </div> <div className="row">
                                {features?.category_delete ?
                                    <label className="ml-3" >Delete Category</label> : null
                                }

                            </div> <div className="row">
                                {features?.category_get ?
                                    <label className="ml-3" >Read Category</label> : null
                                }

                            </div>
                            <div className="row">
                                {features?.content_edit ?
                                    <label className="ml-3" >Edit Content</label> : null
                                }

                            </div>
                            <div className="row">
                                {features?.content_view ?
                                    <label className="ml-3" >View Content</label> : null
                                }

                            </div>

                        </div>

                    </div>

                        <div className="text-right">
                            <Link to="/permissions" className="btn btn-secondary discard mr-2">
                                Back
                            </Link>

                        </div>
                </div>
            </form>
        </Layout>
    </>
}

export default PermissionView