import React, { useEffect, useState } from 'react'
import Layout from '../../components/global/layout'
import AddEditFeatures from './AddEdit'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import AddEditPage from './AddEdit'
import ApiClient from '../../methods/api/apiClient'
import dateFormat, { masks } from "dateformat";
import './style.scss'
import { useSelector } from 'react-redux'
import methodModel from '../../methods/methods'
function ContentPage() {
    const history=useHistory()
    const [loaging, setLoader] = useState(true)
    const user = useSelector((state) => state.user)
    const searchState = useSelector((state) => state.search)
    const [filters, setFilter] = useState({ page: 1, count: 10, search: '', role: '', sortBy: '' })
    const [total, setTotal] = useState(0)
    const [form, setform] = useState({ title: '', meta_title: '', description: '', meta_description: '', page_name: '' })
    const [formData, setFormData] = useState({ email: '', ic_number: '' })
    const [submitted, setSubmitted] = useState(false)
    const [inviteForm, setInviteForm] = useState({ email: '' })
    const [emailErr, setEmailErr] = useState('')
    const [icErr, setIcErr] = useState('')
    const [data, setData] = useState([])
    const [tableCols, setTableCols] = useState([])
    const [sort, setsort] = useState({ name: 'title asc', email: 'slug asc', createdAt: '', updatedAt: '', status: '' })
    const [permissions, setpermissions] = useState({})

    const getdata = () => {
        ApiClient.get('user/detail', { id: user?.id }).then((res) => {
            if (res?.success) {
                console.log(res)
                setpermissions(res?.permissions)
            }
        })
    }
    useEffect(() => {
        getdata()
        console.log(permissions)
    }, [])
    useEffect(() => {
        if (user && user.loggedIn) {
            setFilter({ ...filters, search: searchState.data })
            getData({ search: searchState.data, page: 1 })
        }
    }, [searchState])

    const getData = (p = {}) => {
        setLoader(true)
        let filter = { ...filters, ...p }
        ApiClient.get('contents', filter).then(res => {
            if (res.success) {
                setData(res.data)
                setTotal(res.total)
            }
            setLoader(false)
        })


    }


    useEffect(() => {
        getData()
    }, [])



    const sortDataTable1 = (e) => {
        setFilter({ ...filters, sortBy: e })
        if (e == 'title asc' || e == 'title desc') setsort({ ...sort, title: e })
        if (e == 'meta_title asc' || e == 'meta_title desc') setsort({ ...sort, meta_title: e })
        if (e == 'createdAt asc' || e == 'createdAt desc') setsort({ ...sort, createdAt: e })
        if (e == 'page_name asc' || e == 'page_name desc') setsort({ ...sort, page_name: e })
        if (e == 'updatedAt asc' || e == 'updatedAt desc') setsort({ ...sort, updatedAt: e })
        if (e == 'active' || e == 'deactive') setsort({ ...sort, status: e })
        getData({ sortBy: e })
    }
    const sortDataTable2 = (e) => {
        setFilter({ ...filters, sortBy: e })

        if (e == 'active' || e == 'deactive') setsort({ ...sort, status: e })
        getData({ status: e })
    }

    const modalClosed = () => {
        setFilter({ ...filters, page: 1 })
        getData({ page: 1 })

    }

    const openModal = (itm = {}) => {
        setSubmitted(false)
        setEmailErr('')
        setIcErr('')
        setform({ role: 'user', firstName: '', lastName: '', email: '', ic_number: '', password: '', mobileNo: '', dialCode: '+60', nationality: '', ...itm })
        setFormData({ ...formData, ...itm })
        if (itm.id) {
            setform({ ...form, ...itm })
        }
        document.getElementById("openuserModal").click()
    }


    const ChnageStatus = (id, status) => {

        if (window.confirm(`Do You Want To ${status == 'active' ? 'Deactivate' : 'Activate'}`)) {

            ApiClient.put('change/status', { model: 'contentmanagement', id: id, status: status == 'active' ? 'deactive' : 'active' }).then((res) => {
                if (res.success) {

                    getData()
                }
            })
        }
    }


    const filter = (p = {}) => {
        setFilter({ ...filters, page: 1, ...p })
        getData({ ...p })
    }

    return (
        <>
            <Layout>
                <div className="d-flex justify-content-between mb-3 main_title">
                    <h3 className="hedding">
                        Content Page
                    </h3>

                    <article className="d-flex">

                        {/* <button className="btn btn-primary mr-2" onClick={() => openModal({ role: 'content' })}>
                            Add Page
                        </button> */}
                        {/* <div className="dropdown addDropdown mr-2">
                            <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Categories
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a className='dropdown-item' href="">Active</a>
                                <a className='dropdown-item' href="">Inactive</a>


                            </div>
                        </div> */}

                        <div className="dropdown addDropdown mr-2">
                            <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuColumns" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {filters.status ? <>{filters.status == 'active' ? 'Active' : 'Deactive'}</> : <>Status</>}
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuColumns">
                                <a className='dropdown-item' onClick={e => filter({ status: '' })}>All</a>
                                <a className='dropdown-item' onClick={e => filter({ status: 'active' })}>Active</a>
                                <a className='dropdown-item' onClick={e => filter({ status: 'deactive' })}>Inactive</a>
                            </div>
                        </div>


                    </article>


                </div>
                <div className="table-responsive">
                    <table className="table mb-0">
                        <thead className="theadclss">
                            <tr className="tblclas">

                                <th scope='col'>Title
                                    <i class="material-icons  pointer hjik mr-2" onClick={() => sortDataTable1(sort.title == 'title asc' ? 'title desc' : 'title asc')} title="Sort">heighticon</i>
                                </th>
                                {/* <th scope='col'>Meta Title
                                    <i class="material-icons  cursor:pointer hjik mr-2" onClick={() => sortDataTable1(sort.meta_title == 'meta_title asc' ? 'meta_title desc' : 'meta_title asc')} title="Sort">heighticon</i>
                                </th> */}
                                <th scope='col'>Page Name
                                    <i class="material-icons  pointer hjik mr-2" onClick={() => sortDataTable1(sort.page_name == 'page_name asc' ? 'page_name desc' : 'page_name asc')} title="Sort">heighticon</i>
                                </th>
                                <th scope='col'>Date Created
                                    <i class="material-icons  pointer hjik mr-2" onClick={() => sortDataTable1(sort.createdAt == 'createdAt asc' ? 'createdAt desc' : 'createdAt asc')} title="Sort">heighticon</i>
                                </th>
                                <th scope='col'>Date UpdatedAt
                                    <i class="material-icons  pointer hjik mr-2" onClick={() => sortDataTable1(sort.createdAt == 'UpdatedAt asc' ? 'UpdatedAt desc' : 'UpdatedAt asc')} title="Sort">heighticon</i>
                                </th>

                                <th scope='col'>Status
                                  
                                </th>
                                <th scope='col' className="nowrap">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!loaging && data && data.map((itm, i) => {
                                return <tr key={i}>

                                    <td key={itm.key} className={`nowrap`} onClick={()=>history.push('/contentpage/' + itm.page_name)}  style={{ cursor: 'pointer' }}>
                                        {methodModel.capitalizeName(itm.title)}
                                    </td>
                                    {/* <td key={itm.key} className={`nowrap`}>
                                        {itm.meta_title}
                                    </td> */}
                                    <td key={itm.key} className={`nowrap`}>
                                        {itm.page_name}
                                    </td>
                                    <td key={itm.key} className={`nowrap`}>
                                        {dateFormat(itm.createdAt, 'dd/mm/yyyy')}
                                    </td>
                                    <td key={itm.key} className={`nowrap`}>
                                        {dateFormat(itm.updatedAt, 'dd/mm/yyyy')}
                                    </td>
                                    <td>
                                        <div key={itm.key} onClick={() => ChnageStatus(itm.id, itm.status)} className={`${itm.status == 'active' ? 'activeItm' : 'deactiveItm'}`}>
                                            {itm.status}
                                        </div>
                                    </td>

                                    <td className="nowrap">
                                        {
                                            user.role == 'admin' || permissions?.content_get ? <>
                                                <Link className="linkclass mx-2" title="View" to={'/contentpage/' + itm.page_name}>
                                                    <i className="fa fa-eye"></i></Link>
                                            </> : null
                                        }
                                       
                                        {
                                            user.role == 'admin' || permissions?.content_edit ? <> 
                                                <a className="linkclass mx-2" title="Edit" onClick={() => openModal(itm)}><i className="fa fa-pen"></i></a>
                                            </> : null
                                        }
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>

                    {loaging ? <div className="text-center py-4">
                        <img src="/assets/img/loader.gif" className="pageLoader" />
                    </div> : <></>}
                </div>
                {!loaging && total == 0 ? <div className="py-3 text-center">No Data</div> : <></>}
                <AddEditPage formData={formData} form={form} submitted={submitted} emailErr={emailErr} setIcErr={setIcErr} icErr={icErr} setEmailErr={setEmailErr} setSubmitted={setSubmitted} setform={setform} modalClosed={modalClosed} />
            </Layout>
        </>
    )
}

export default ContentPage;
