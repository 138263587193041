import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ToastsStore } from 'react-toasts';
import ApiClient from '../../methods/api/apiClient';
import loader from '../../methods/loader';
import methodModel from '../../methods/methods';

const AddEditQuestion = ({ form, setform, modalClosed }) => {
  const user = useSelector((state) => state.user);
  const [categories, setCategories] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();

    let value = {
      id: form.id,
      question: form.question,
      about_question:form.about_question,
      options: form.options,
      order:form?.order,
      answer_type: form.answer_type,
      isSuicidalQuestion:form?.isSuicidalQuestion,
      isChildAbuse:form?.isChildAbuse,
      role: 'patient',
      // category: form?.category,
    };

    let method = 'post';
    if (value.id) {
      method = 'put';
    } else {
      delete value.id;
    }

    // if (form.options.length < 1) {
    //   ToastsStore.error('Atleast one options required');
    //   return;
    // }

    loader(true);
    ApiClient.allApi('question', value, method).then((res) => {
      if (res.success) {
        ToastsStore.success(res.message);
        document.getElementById('closeQuestionModal').click();

        setform({});
        modalClosed();
      }
      loader(false);
    });
  };

  const addOption = () => {
    let options = form.options;
    let id = new Date().getTime();
    let value = {
      id,
      option: '',
    };
    options.push(value);
    setform({ ...form, options });
  };

  useEffect(() => {
    console.log(form);
  }, [form]);

  const changeOption = (i, key, value) => {
    let options = form.options;
    options[i][key] = value;
    setform({ ...form, options });
  };

  const deleteOption = (index) => {
    let options = form.options;
    options = options.filter((itm, i) => i != index);
    setform({ ...form, options });
  };

  const getCategories = () => {
    ApiClient.get('categories', { page: 1, count: 100 }).then((res) => {
      if (res.success) {
        const fltr = res?.data.filter((itm) => itm.cat_type == 'Questions');
        setCategories(fltr);
      }
    });
  };

  useEffect(() => {
    if (user && user.loggedIn) {
      getCategories();
      // getLaguages()
    }
  }, []);

  return (
    <>
      <a
        id="openQuestionModal"
        data-toggle="modal"
        data-target="#questionModal"
      ></a>
      <div
        className="modal fade"
        id="questionModal"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                {form && form.id ? 'Edit' : 'Add'} Question
              </h5>
              <button
                type="button"
                id="closeQuestionModal"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                title="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="modal-body">
                <div className="form-row roleForm">
                  <div className="col-md-6 mb-3">
                    <label>
                      Question <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={form.question ? form.question : ''}
                      onChange={(e) =>
                        setform({ ...form, question: e.target.value })
                      }
                      required
                    />

                    {/* {languages && languages.map(itm => {
                                        return <div className="mt-3" key={itm.id}>
                                            <input type="text" className="form-control textcpitlize" placeholder={itm.name} value={form.questionTranslate ? form.questionTranslate[itm.code] ? form.questionTranslate[itm.code] : '' : ''} onChange={e => translate(e.target.value, itm, 'questionTranslate')} required />
                                        </div>
                                    })} */}
                  </div>

                  {/* <div className="col-md-6 mb-3">
                                    <label>Role <span className="text-danger">*</span></label>
                                    <select onChange={(e)=>{
                                        setform({...form,role:e.target.value})
                                    }} className="form-control" value={form?.role || ''}   required>
                                    <option value="" >Select Role</option>
                                        {
                                            ["patient","therapist"].map((itm)=>{
                                                return(
                                                    <option value={itm} >{itm}</option>   
                                                )
                                            })
                                        }
                                       
                                       

                                    </select>
                                </div> */}

                  <div className="col-md-6 mb-3">
                    <label>
                      Answer Type <span className="text-danger">*</span>
                    </label>
                    <select
                      onChange={(e) => {
                        setform({ ...form, answer_type: e.target.value });
                      }}
                      className="form-control"
                      value={form?.answer_type || ''}
                      required
                    >
                      <option value="">Select Answer Type</option>
                      {['single', 'multiple','dropdown',"text"].map((itm) => {
                        return <option value={itm}>{methodModel.capitalizeName(itm)}</option>;
                      })}
                    </select>
                  </div>
                  {/* <div className="col-md-6 mb-3">
                    <label>
                    Question Order <span className="text-danger">*</span>
                    </label>
                    <select
                      onChange={(e) => {
                        setform({ ...form, order: e.target.value });
                      }}
                      className="form-control"
                      value={form?.order || ''}
                      required
                    >
                      <option value="">Select Question Order</option>
                      {['single', 'multiple','dropdown'].map((itm) => {
                        return <option value={itm}>{itm}</option>;
                      })}
                    </select>
                  </div> */}

                 <div className="col-md-6 mb-3">
                    <label>
                    Question Order <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      value={form.question ? form.order : ''}
                      onChange={(e) =>
                        setform({ ...form, order: e.target.value })
                      }
                      required
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label>Question Type</label>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        name="questionType"
                        value="isSuicidalQuestion"
                        checked={form.isSuicidalQuestion}
                        onChange={() => {
                          if (!form.isSuicidalQuestion) {
                            setform({ ...form, isSuicidalQuestion: true, isChildAbuse: false });
                          } else {
                            // Uncheck if it's already checked
                            setform({ ...form, isSuicidalQuestion: false });
                          }
                        }}
                      />
                      <label className="form-check-label">Is Suicidal Question</label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        name="questionType"
                        value="isChildAbuse"
                        checked={form.isChildAbuse}
                        onChange={() => {
                          if (!form.isChildAbuse) {
                            setform({ ...form, isSuicidalQuestion: false, isChildAbuse: true });
                          } else {
                            // Uncheck if it's already checked
                            setform({ ...form, isChildAbuse: false });
                          }
                        }}
                      />
                      <label className="form-check-label">Is Child Abuse Question</label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label>
                     About Question <span className="text-danger">*</span>
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      value={form.about_question ? form.about_question : ''}
                      onChange={(e) =>
                        setform({ ...form, about_question: e.target.value })
                      }
                    />
                  </div>
                  {form.answer_type !== 'text' && <div className="col-md-12">
                    <label>Answers</label>
                    <div className="form-row">
                      {form.options &&
                        form.options.map((itm, i) => {
                          return (
                            <div className="col-md-12 mb-3" key={i}>
                              <article className="optionCard shadow p-3">
                                <div className="form-row">
                                  <div className="col-md-12 mb-3 text-right">
                                    <i
                                      className="fa fa-times deleteOption"
                                      title="Remove"
                                      onClick={() => deleteOption(i)}
                                    />
                                  </div>
                                  <div className="col-md-9 mb-3">
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={itm.option}
                                      onChange={(e) =>
                                        changeOption(
                                          i,
                                          'option',
                                          e.target.value
                                        )
                                      }
                                      placeholder={itm.placeholder}
                                      required
                                    />
                                    {/* {languages && languages.map(itm => {
                                                                return <div className="mt-3" key={itm.id}>
                                                                    <input type="text" className="form-control textcpitlize" placeholder={itm.name} value={form.questionTranslate ? form.questionTranslate[itm.code] ? form.questionTranslate[itm.code] : '' : ''} onChange={e => translate(e.target.value, itm, 'questionTranslate')} required />
                                                                </div>
                                                            })} */}
                                  </div>
                                </div>
                              </article>
                            </div>
                          );
                        })}
                      {form.options && form.options.length < 100 ? (
                        <div className="col-md-12 text-right">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => addOption()}
                          >
                            Add Option
                          </button>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    setform({});
                  }}
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" className="btn btn-primary">
                  {form && form.id ? 'Update' : 'Create'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEditQuestion;
