import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ToastsStore } from "react-toasts";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import methodModel from "../../methods/methods";
import { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
const AddEditFaq = ({ form, setform, modalClosed }) => {
    const user = useSelector(state => state.user)
    const [faqs, setFaqs] = useState([])
    const [languages, setLanguages] = useState([])
    const [category, setCategories] = useState([])
    const editorRef = useRef(null);
    const log = () => {
        if (editorRef.current) {
            console.log(editorRef.current.getContent());
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault()
        let method = 'post'
        let value = {
            question: form?.question,
            answer: form?.answer,
            category: form?.category

        }

        if (form.id) {
            method = 'put'
            value = {
                id: form?.id,
                answer: form?.answer,
                question: form?.question,
                category: form?.category
            }
        }

        loader(true)
        ApiClient.allApi('faq', value, method).then(res => {
            if (res.success) {
                ToastsStore.success(res.message)
                document.getElementById('closeFaqModal').click()
                modalClosed()
            }
            loader(false)
        })
    }


    const getFaqs = () => {
        ApiClient.get('faqs', { page: 1, count: 100 }).then(res => {
            if (res.success) {
                setFaqs(res.data)
            }
        })
    }

    const getCategories = (p = {}) => {
        // setLoader(true)
        let filter = { page: 1, count: 100, ...p }
        ApiClient.get('categories', filter).then(res => {
            if (res.success) {
                const fltr = res?.data.filter((itm) => itm.cat_type == 'FAQ')
                setCategories(fltr)
            }
            // setLoader(false)
        })
    }


    useEffect(() => {
        if (user && user.loggedIn) {
            getCategories()
            // getFaqs()
        }

    }, [])



    return <>
        <a id="openFaqModal" data-toggle="modal" data-target="#faqModal"></a>
        <div className="modal fade" id="faqModal" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-md">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{form && form.id ? 'Edit' : 'Add'} FAQ</h5>
                        <button type="button" id="closeFaqModal" className="close" data-dismiss="modal" aria-label="Close" title='Close'>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="modal-body">
                            <div className="form-row roleForm">
                                <div className="col-md-12 mb-3">
                                    <label onClick={log}>Question <span className="text-danger">*</span></label>
                                    <input type="text" disabled={methodModel.isTranslatePage()} className="form-control" value={form.question ? form.question : ''} onChange={e => {
                                        console.log(form)
                                        setform({ ...form, question: e.target.value })
                                    }} required />

                                    {languages && methodModel.isTranslatePage() && languages.map(itm => {
                                        return <div className="mt-3" key={itm.id}>
                                            <input type="text" className="form-control textcpitlize" placeholder={itm.name} value={form.questionTranslate ? form.questionTranslate[itm.code] ? form.questionTranslate[itm.code] : '' : ''} onChange={e => translate(e.target.value, itm, 'questionTranslate')} />
                                        </div>
                                    })}
                                </div>
                                <div className="col-md-5 mb-3">
                                    <label>Category <span className="text-danger">*</span></label>
                                    <select onChange={(e) => {
                                        setform({ ...form, category: e.target.value })
                                    }} className="form-control" value={form?.category} required>
                                        <option value='' >Select Category</option>
                                        {
                                            category.map((itm) => {
                                                return (
                                                    <option value={itm.name}>{itm.name}</option>
                                                )
                                            })
                                        }

                                    </select>
                                </div>

                                <div className="col-md-12 mb-3">
                                    <label>Answer <span className="text-danger">*</span></label>
                                    {/* <textarea className="form-control" value={form.answer ? form.answer : ''} onChange={e => setform({ ...form, answer: e.target.value })} required />
                                    {languages && methodModel.isTranslatePage() && languages.map(itm => {
                                        return <div className="mt-3" key={itm.id}>
                                            <textarea className="form-control textcpitlize" placeholder={itm.name} value={form.answereTranslate ? form.answereTranslate[itm.code] ? form.answereTranslate[itm.code] : '' : ''} onChange={e => translate(e.target.value, itm, 'answereTranslate')} />
                                        </div>
                                    })} */}
                                        <Editor
                                            apiKey='9d1tq05iap0fc1t1elmkniuvs8towqjzitfjo4e0rcwic2h6'
                                            value={form.answer ? form.answer : ''}
                                            onEditorChange={(e) => {
                                                setform({ ...form, answer: e })
                                            }}
                                            required
                                            init={{
                                                height: 200,
                                                menubar: false,
                                                plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount'
                                                ],
                                                toolbar: 'undo redo | formatselect | ' +
                                                    'bold italic backcolor | alignleft aligncenter ' +
                                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                                    'removeformat | help',
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                            }}
                                        />
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="submit" className="btn btn-primary">{form && form.id ? 'Update' : 'Add'}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
}

export default AddEditFaq