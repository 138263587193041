import React, { useEffect, useState } from 'react'
import Layout from '../../components/global/layout'

import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import ApiClient from '../../methods/api/apiClient'
import { useSelector } from 'react-redux'
import { ToastsStore } from 'react-toasts'
import Swal from 'sweetalert2'
import methodModel from '../../methods/methods'
import dateFormat, { masks } from "dateformat";
import Pagination from "react-pagination-js";
import axios from 'axios'
import ApiKey from '../../methods/ApiKey'
function Payment() {
    const [loaging, setLoader] = useState(true)
    const user = useSelector((state) => state.user)
    const Searchstate = useSelector((state) => state.search)
    const [form, setform] = useState({ role: 'user', firstName: '', addAvailability: false, lastName: '', email: '', ic_number: '', password: '', mobileNo: '', dialCode: '+60', nationality: '' })
    const [sort, setsort] = useState({ name: 'name asc', createdAt: '', updatedAt: '', status: '', category: '', payment_method: '' })
    const [formData, setFormData] = useState({ email: '', ic_number: '' })
    const [submitted, setSubmitted] = useState(false)
    const [inviteForm, setInviteForm] = useState({ email: '' })
    const [emailErr, setEmailErr] = useState('')
    const [icErr, setIcErr] = useState('')
    const history = useHistory()
    const [planName, setName] = useState('')
    const [plan, setPlan] = useState([])
    const [data, setData] = useState([])
    const [filters, setFilter] = useState({ page: 1, count: 5, search: '', sortBy: 'updatedAt', subscription_plan_id: "" })
    const [tableCols, setTableCols] = useState([])
    const [total, setTotal] = useState()

    const getData = (p = {}) => {
        setLoader(true)
        let filter = { ...filters, ...p }
        ApiClient.get('transaction/all', filter).then(res => {
            if (res.success) {
                setData(res.data)
                setTotal(res.total)
            }
            setLoader(false)
        })



    }

    const GetPlans = () => {
        ApiClient.get('plans').then((res) => {
            if (res.success) {
                setPlan(res?.data)
            }
        })
    }

    useEffect(() => {
        if (user && user.loggedIn) {
            setFilter({ ...filters, search: Searchstate.data })
            getData({ search: Searchstate.data, page: 1 })

        }
    }, [Searchstate])
    // const getData = () => {
    //     setLoader(true)
    //     ApiClient.get('features?page=1&count=10&search&sortBy&status&role_type=patient',).then((res) => {
    //         if (res.success) {
    //             setData(res.data)
    //             setLoader(false)
    //         }
    //     })
    // }
    useEffect(() => {
        getData()
        GetPlans()
    }, [])


    const modalClosed = () => {
        setFilter({ ...filters, page: 1 })
        getData({ page: 1 })
    }

    const DeleteFeature = (id) => {

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                // loader(true)
                ApiClient.delete('delete', { model: 'features', id: id }).then((res) => {
                    if (res.success) {
                        getData()
                        ToastsStore.success(res.message)
                        setLoader(false)
                    }
                    // loader(false)
                })
            }
        })
    }

    const openModal = (itm = {}) => {
        setSubmitted(false)
        console.log(itm)
        if (itm.id) {
            setform({ ...form, name: form.name, role_type: form.role_type })
        }
        setEmailErr('')
        setIcErr('')
        setform({ role_type: '', name: '', ...itm })
        setFormData({ ...formData, ...itm })

        document.getElementById("openuserModal").click()
    }



    const sortDataTable1 = (e) => {
        setFilter({ ...filters, sortBy: e })
        if (e == 'name asc' || e == 'name desc') setsort({ ...sort, name: e })
        if (e == 'email asc' || e == 'email desc') setsort({ ...sort, email: e })
        if (e == 'createdAt asc' || e == 'createdAt desc') setsort({ ...sort, createdAt: e })
        if (e == 'updatedAt asc' || e == 'updatedAt desc') setsort({ ...sort, updatedAt: e })
        if (e == 'approved' || e == 'pending') setsort({ ...sort, status: e })
        getData({ sortBy: e })
    }
    const sortDataTable2 = (e) => {
        setFilter({ ...filters, subscription_plan_id: e })

        // if (e == 'approved' || e == 'pending') setsort({ ...sort, status: e })
        getData({ subscription_plan_id: e })
    }
    const sortDataTablepAYMENT = (e) => {
        setFilter({ ...filters, payment_method: e })

        // if (e == 'approved' || e == 'pending') setsort({ ...sort, status: e })
        getData({ payment_method: e })
    }
    const sortDataTable3 = (e) => {
        setFilter({ ...filters, category: e })

        if (e == 'silver' || e == 'gold' || e == 'platinum') setsort({ ...sort, category: e })
        getData({ category: e })
    }


    const ChangeStatus = (id, status) => {
        if (window.confirm(`Do you want to ${status == 'accept' ? 'accept' : 'reject'}`)) {
            ApiClient.put('appointment', { id: id, status: status }).then((res) => {
                if (res.success) {
                    getData()
                }
            })
        }
    }
    const pageChange = (e) => {
        setFilter({ ...filters, page: e })
        getData({ page: e })
    }
    const handleCountChange = (count) => {
        setFilter({ ...filters, count: count, page: 1 });
        getData({ count: count, page: 1 });
    };

    const DownloadInvoice = async (id) => {
        const token = await localStorage.getItem("token");
        const req = await axios({
            method: "get",
            url: ` ${ApiKey.api}transaction/download-pdf?id=${id}`,
            responseType: "blob",
            body: { token: token }
        });
        var blob = new Blob([req.data], {
            type: req.headers["content-type"],
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `payment invoice.xlsx`;
        link.click();
    }
    return (
        <>
            <Layout>
                <div className="d-flex justify-content-between mb-3 main_title">
                    <h3 className="hedding">
                        Payments
                    </h3>

                    <article className="d-flex">




                        <div className="dropdown addDropdown mr-2">
                            <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuColumns" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Plan:
                                {
                                    !sort.subscription_plan_id ? ' All' : " " + planName
                                }
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuColumns">
                                <a className='dropdown-item' onClick={() => sortDataTable2(sort.subscription_plan_id = '')}>All</a>
                                {
                                    plan?.map((itm) => {
                                        return (
                                            <a className='dropdown-item' onClick={() => {
                                                setName(itm?.name)
                                                sortDataTable2(sort.subscription_plan_id = itm?.id)
                                            }}>{itm.name}</a>
                                        )
                                    })
                                }



                            </div>
                        </div>

                        <div className="dropdown addDropdown mr-2">
                            <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuColumns" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Payment Method:

                                {
                                    sort.payment_method || "All"
                                }
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuColumns">
                                <a className='dropdown-item' onClick={() => sortDataTablepAYMENT(sort.payment_method = '')}>All</a>

                                <a className='dropdown-item' onClick={() => {

                                    sortDataTablepAYMENT(sort.payment_method
                                        = 'card')
                                }}>Card</a>
                                <a className='dropdown-item' onClick={() => {

                                    sortDataTablepAYMENT(sort.payment_method
                                        = 'google pay')
                                }}>Google Pay</a>



                            </div>
                        </div>

                    </article>


                </div>
                <div className="table-responsive">
                    <table className="table mb-0">
                        <thead className="theadclss">
                            <tr className="tblclas">

                                <th scope='col'>Name
                                    <i class="material-icons  cursor:pointer hjik mr-2 pointer" onClick={() => sortDataTable1(sort.name == 'name asc' ? 'name desc' : 'name asc')} title="Sort">heighticon</i>
                                </th>
                                {/* <th scope='col'>Category
                                <i class="material-icons  cursor:pointer hjik mr-2" onClick={() => sortDataTable1(sort.email == 'email asc' ? 'email desc' : 'email asc')} title="Sort">heighticon</i>
                            </th> */}
                                <th scope='col'>Plan

                                </th>
                                <th scope='col'>Price

                                </th>
                                <th scope='col'>Date

                                </th>

                                {/* <th scope='col'>Status

                                </th> */}
                                <th scope='col' className="nowrap">Action</th>
                            </tr>
                        </thead>
                        <tbody>

                            {!loaging && data && data.map((itm, i) => {

                                return <tr key={i}>

                                    <td onClick={() => {
                                        history.push('payments/' + itm?._id)
                                    }} key={itm.key} className={`nowrap pointer`}>
                                        {methodModel?.capitalizeName(itm?.user_id_name)}
                                    </td>
                                    <td className={`nowrap pointer`}>{itm.subscription_plans_details?.name} </td>
                                    <td className={`nowrap pointer`}>{itm.subscription_plans_details?.price} USD </td>
                                    <td className={`nowrap pointer`}>{dateFormat(itm.date, "dd-mmm-yyyy")}</td>

                                    {/* <td>
                                        <div key={itm.key} onClick={() => {
                                            ChangeStatus(itm._id, itm.status)
                                        }} className={`${itm.status == 'approved' ? 'activeItm' : 'deactiveItm'} `}>
                                            {itm.status}
                                        </div>
                                    </td> */}

                                    <td className="nowrap">

                                        <Link to={'/payments/' + itm.id} className="linkclass mx-2" title="View" >
                                            <i className="fa fa-eye"></i></Link>
                                        <i onClick={() => DownloadInvoice(itm.id)} className="fa fa-download pointer" title='Invoice'></i>

                                    </td>

                                </tr>
                            })}
                        </tbody>
                    </table>


                    {loaging ? <div className="text-center py-4">
                        <img src="/assets/img/loader.gif" className="pageLoader" />
                    </div> : <></>}
                </div>
                {!loaging && data.length == 0 ? <div className="py-3 text-center">No Data</div> : <></>}
                {
                    !loaging && total > filters.count ? <div className='d-flex justify-content-between align-items-center flex-wrap'>
                        {/* <Pagination
                        activePage={filters.page}
                        itemsCountPerPage={filters.count}
                        totalItemsCount={total}
                        pageRangeDisplayed={5}
                        onChange={pageChange}
                    /> */}
                        <div className='d-flex align-items-center justify-content-center' >
                            <span>Show: </span>
                            <select
                                className="form-control ml-2"
                                onChange={(e) => handleCountChange(parseInt(e.target.value))}
                                value={filters.count}
                            >
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={30}>30</option>
                                <option value={40}>40</option>
                            </select>
                        </div>
                        <Pagination
                            currentPage={filters.page}
                            totalSize={total}
                            sizePerPage={filters.count}
                            changeCurrentPage={pageChange}
                        />
                    </div> : <></>
                }

            </Layout>
        </>
    )
}

export default Payment
