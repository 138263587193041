import React, { useEffect, useState } from 'react'
import Layout from '../../components/global/layout'
import AddEditFeatures from './AddEdit'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import ApiClient from '../../methods/api/apiClient'
import { useSelector } from 'react-redux'
import { ToastsStore } from 'react-toasts'
import Swal from 'sweetalert2'
import methodModel from '../../methods/methods'
import Pagination from "react-pagination-js";

function PlanFeatures() {
    const [loaging, setLoader] = useState(true)
    const user = useSelector((state) => state.user)
    const Searchstate = useSelector((state) => state.search)
    const [form, setform] = useState({ role: 'user', firstName: '', addAvailability: false, lastName: '', email: '', ic_number: '', password: '', mobileNo: '', dialCode: '+60', nationality: '' })
    const [sort, setsort] = useState({ name: 'name asc', createdAt: '', updatedAt: '', status: '', category: '' })
    const [formData, setFormData] = useState({ email: '', ic_number: '' })
    const [submitted, setSubmitted] = useState(false)
    const [inviteForm, setInviteForm] = useState({ email: '' })
    const [emailErr, setEmailErr] = useState('')
    const [icErr, setIcErr] = useState('')
    const history = useHistory()
    const [data, setData] = useState([])
    const [filters, setFilter] = useState({ page: 1, count: 10, search: '', sortBy: '', status: '', category: '' })
    const [tableCols, setTableCols] = useState([])
    const [total, setTotal] = useState()

    const getData = (p = {}) => {
        setLoader(true)
        let filter = { ...filters, ...p }
        ApiClient.get('features', filter).then(res => {
            if (res.success) {
                setData(res.data)
                setTotal(res.total)
            }
            setLoader(false)
        })


    }

    const pageChange = (e) => {
        setFilter({ ...filters, page: e })
        getData({ page: e })
    }

    const handleCountChange = (count) => {
        setFilter({ ...filters, count: count, page: 1 });
        getData({ count: count, page: 1 });
    };

    useEffect(() => {
        if (user && user.loggedIn) {
            setFilter({ ...filters, search: Searchstate.data })
            getData({ search: Searchstate.data, page: 1 })
        }
    }, [Searchstate])
    // const getData = () => {
    //     setLoader(true)
    //     ApiClient.get('features?page=1&count=10&search&sortBy&status&role_type=patient',).then((res) => {
    //         if (res.success) {
    //             setData(res.data)
    //             setLoader(false)
    //         }
    //     })
    // }
    useEffect(() => {
        getData()
    }, [])


    const modalClosed = () => {
        setFilter({ ...filters, page: 1 })
        getData({ page: 1 })
    }

    const DeleteFeature = (id) => {

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                // loader(true)
                ApiClient.delete('delete', { model: 'features', id: id }).then((res) => {
                    if (res.success) {
                        getData()
                        ToastsStore.success(res.message)
                        setLoader(false)
                    }
                    // loader(false)
                })
            }
        })
    }

    const openModal = (itm = {}) => {
        setSubmitted(false)
        console.log(itm)
        if (itm.id) {
            setform({ ...form, name: form.name, role_type: form.role_type })
        }
        setEmailErr('')
        setIcErr('')
        setform({ role_type: '', name: '', ...itm })
        setFormData({ ...formData, ...itm })

        document.getElementById("openuserModal").click()
    }



    const sortDataTable1 = (e) => {
        setFilter({ ...filters, sortBy: e })
        if (e == 'name asc' || e == 'name desc') setsort({ ...sort, name: e })
        if (e == 'email asc' || e == 'email desc') setsort({ ...sort, email: e })
        if (e == 'createdAt asc' || e == 'createdAt desc') setsort({ ...sort, createdAt: e })
        if (e == 'updatedAt asc' || e == 'updatedAt desc') setsort({ ...sort, updatedAt: e })
        if (e == 'active' || e == 'deactive') setsort({ ...sort, status: e })
        getData({ sortBy: e })
    }
    const sortDataTable2 = (e) => {
        setFilter({ ...filters, sortBy: e })

        if (e == 'active' || e == 'deactive') setsort({ ...sort, status: e })
        getData({ status: e })
    }

    const sortDataTable3 = (e) => {
        setFilter({ ...filters, category: e })

        if (e == 'silver' || e == 'gold' || e == 'platinum') setsort({ ...sort, category: e })
        getData({ category: e })
    }


    const ChangeStatus = (id, status) => {
        if (window.confirm(`Do you want to ${status == 'active' ? 'Deactivate' : 'Activate'}`)) {
            ApiClient.put('change/status', { model: 'features', id: id, status: status == 'active' ? 'deactive' : 'active' }).then((res) => {
                if (res.success) {
                    getData()
                }
            })
        }
    }
    return (
        <>
            <Layout>
                <div className="d-flex justify-content-between mb-3 main_title">
                    <h3 className="hedding">
                        Plan Feautes
                    </h3>

                    <article className="d-flex">

                        <button className="btn btn-primary mr-2" onClick={() => openModal({ role: 'user' })}>
                            Add Plan Features
                        </button>


                        <div className="dropdown addDropdown mr-2">
                            <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuColumns" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {
                                    !sort.status ? 'All' : `${sort.status == 'active' ? 'Active' : 'Inactive'}`
                                }
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuColumns">
                                <a className='dropdown-item' onClick={() => sortDataTable2(sort.status = '')}>All</a>
                                <a className='dropdown-item' onClick={() => sortDataTable2(sort.status = 'active')}>Active</a>
                                <a className='dropdown-item' onClick={() => sortDataTable2(sort.status = 'deactive')}>Inactive</a>
                            </div>
                        </div>


                    </article>


                </div>
                <div className="table-responsive">
                    <table className="table mb-0">
                        <thead className="theadclss">
                            <tr className="tblclas">

                                <th scope='col'>Name
                                    <i class="material-icons  cursor:pointer hjik mr-2" onClick={() => sortDataTable1(sort.name == 'name asc' ? 'name desc' : 'name asc')} title="Sort">heighticon</i>
                                </th>
                                {/* <th scope='col'>Category
                                <i class="material-icons  cursor:pointer hjik mr-2" onClick={() => sortDataTable1(sort.email == 'email asc' ? 'email desc' : 'email asc')} title="Sort">heighticon</i>
                            </th> */}

                                <th scope='col'>Status
                                    
                                </th>
                                <th scope='col' className="nowrap">Action</th>
                            </tr>
                        </thead>
                        <tbody>

                            {!loaging && data && data.map((itm, i) => {

                                return <tr key={i}>

                                    <td key={itm.key} className={`nowrap`}>
                                        {methodModel?.capitalizeName(itm?.name)}
                                    </td>



                                    <td>
                                        <div key={itm.key} onClick={() => {
                                            ChangeStatus(itm.id, itm.status)
                                        }} className={`${itm.status == 'active' ? 'activeItm' : 'deactiveItm'} `}>
                                            {itm.status}
                                        </div>
                                    </td>

                                    <td className="nowrap">

                                        <a className="linkclass mx-2" title="Edit" onClick={() => openModal(itm)}><i className="fa fa-pen"></i></a> |
                                        <a className="linkclass mx-2" title="Delete" onClick={() => DeleteFeature(itm.id)}><i className="fa fa-trash"></i></a>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>

                    {loaging ? <div className="text-center py-4">
                        <img src="/assets/img/loader.gif" className="pageLoader" />
                    </div> : <></>}
                    {
                    !loaging && total > filters.count ? <div className='d-flex justify-content-between align-items-center flex-wrap'>
                        <div className='d-flex align-items-center justify-content-center' >
                            <span>Show: </span>
                            <select
                                className="form-control ml-2"
                                onChange={(e) => handleCountChange(parseInt(e.target.value))}
                                value={filters.count}
                            >
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={30}>30</option>
                                <option value={40}>40</option>
                            </select>
                        </div>
                        <Pagination
                            currentPage={filters.page}
                            totalSize={total}
                            sizePerPage={filters.count}
                            changeCurrentPage={pageChange}
                        />
                    </div> : <></>
                }
                </div>
                {!loaging && data.length == 0 ? <div className="py-3 text-center">No Data</div> : <></>}
                <AddEditFeatures formData={formData} form={form} submitted={submitted} emailErr={emailErr} setIcErr={setIcErr} icErr={icErr} setEmailErr={setEmailErr} setSubmitted={setSubmitted} setform={setform} modalClosed={modalClosed} />
            </Layout>
        </>
    )
}

export default PlanFeatures
