import React, { useState, useEffect } from "react";
import { ToastsStore } from "react-toasts";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { useSelector } from 'react-redux';
import methodModel from "../../methods/methods";

const AddEditFeatures = ({ formData, form, setform, modalClosed, setSubmitted, submitted, emailErr, setEmailErr, icErr, setIcErr }) => {
    const [loading, setLoading] = useState(false);
    const user = useSelector((state) => state.user);
    const formValidation = [
        { key: 'mobileNo', minLength: 9 },
        { key: 'ic_number', minLength: 6 },
        { key: 'dialCode', dialCode: true },
    ]

    const getError = (key) => {
        return methodModel.getError(key, form, formValidation)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitted(true)
        let invalid = methodModel.getFormError(formValidation, form)
        if (invalid || emailErr || icErr) return
        let method = 'post'
        let url = 'feature'
        let value = {
           name:form.name,role_type:'user',id:form.id
        }
   
        if (value.id) {
            method = 'put'
            url = 'feature'
            delete value.password
        } else {
            delete value.id
        }

        loader(true)
        ApiClient.allApi(url, value, method).then(res => {
            if (res.success) {
                ToastsStore.success(res.message)
                document.getElementById('closeuserModal').click()
                modalClosed()
            }

            loader(false)
        })
    }

    const checkEmail = (e) => {
        setEmailErr('')
        setLoading(true)
        ApiClient.get('check/email', { email: e }, '', true).then(res => {
            if (!res.success) {

                if (res.error.message == 'Email already taken') {
                    if (formData.email != e) setEmailErr(res.error.message)
                }
            }
            setLoading(false)
        })
    }

    const checkIc = (e) => {
        setIcErr('')
        setLoading(true)
        ApiClient.get('check/icnumber', { ic_number: e }, '', true).then(res => {
            if (!res.success) {
                if (formData.ic_number != e) setIcErr(res.error.message)
            }
            setLoading(false)
        })
    }

    const DeleteFeature = (id)=>{
        ApiClient.delete('delete',{model:'features',id:id}).then((res)=>{
            if(res.success){
                
            }
        })
    }

    useEffect(
        () => {
            if (user && user.loggedIn) {

            }
        },
        []
    );

    return <>
        <a id="openuserModal" data-toggle="modal" data-target="#userModal"></a>
        <div className="modal fade" id="userModal" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-md">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{form && form.id ? 'Edit' : 'Add'} Plan Features</h5>
                        <button type="button" id="closeuserModal" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="modal-body">
                            <div className="form-row">
                                <div className="col-md-6 mb-3">
                                    <label>Name<span className="star">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={form.name}
                                        onChange={e => setform({ ...form, name: e.target.value })}
                                        required
                                    />


                                </div>






                                



                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="submit" className="btn btn-primary" disabled={loading}>Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
}

export default AddEditFeatures