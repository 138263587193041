import React, { useEffect, useState } from 'react'
import Layout from '../../components/global/layout'
import dateFormat, { masks } from "dateformat";
import DatePicker from 'react-multi-date-picker'
import TimePicker from 'react-time-picker';
import { useHistory } from 'react-router';
import ApiClient from '../../methods/api/apiClient';
import { useSelector } from 'react-redux';
import { ToastsStore } from 'react-toasts';
import CalendarCard from './Calender';

function CalenderView() {
    // const [form, setform] = useState({ date: [], time: [] });
    const history = useHistory()
    const [enalbe, setenable] = useState(true)
    const user = useSelector((state) => state.user)
    // const getData = () => {
    //     ApiClient.get('availability', { id: user.id }).then((res) => {
    //         if (res.success) {
    //             setform({ date: res?.data?.date, id: res?.data?.id })
    //         }
    //     })
    // }
    // useEffect(() => {
    //     getData()
    // }, [])

    const HandleSubmit = (e) => {
        e.preventDefault()
        let value = {

            date: form?.date,
            time: form?.time
        }
        let method = 'post'
        if (form.id) {
            method = 'put'
        } else {
            delete value.id
        }


        ApiClient.allApi('availability', value, method).then((res) => {
            if (res) {
                ToastsStore.success(res?.message)
                setenable(false)
            }
        })
    }
    const handleRolesPer = (check) => {
        let value = check ? true : false;

    };

    // useEffect(() => {

    //     console.log(form)

    // }, [form])
    return (

        <>
            <Layout>
               
                       <CalendarCard user={user} HandleSubmit={HandleSubmit}/>
            </Layout>
        </>
    )
}

export default CalenderView
