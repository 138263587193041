import React, { useEffect, useState } from 'react';
import './style.scss';
import { logout } from '../../../actions/user';
import { Link, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import img from './tachometer-alt-solid.svg'
import ApiClient from '../../../methods/api/apiClient';
import loader from '../../../methods/loader';
const Sidebar = () => {
  const user = useSelector(state => state.user)
  const [permissions, setpermissions] = useState({})
  const ListItemLink = ({ to, type = 'link', ...rest }) => {
    let url = location.href
    const host = location.host
    url = url.split(host)[1]
    return (<>
      {type == 'link' ? <li className={`nav-item ${to == url ? 'active' : ''}`}>
        {/* {...rest} */}
        <Link to={to} {...rest} className="nav-link hoverclass" />
      </li> : <li className={`nav-item main ${url.includes(to) ? 'active' : ''}`} {...rest}></li>}
    </>
    );
  };

  const matchUrl = (to, exact = false) => {
    let url = location.href
    const host = location.host
    url = url.split(host)[1]
    if (exact) return url == to
    if (!exact) return url.includes(to)
  }
  const dispatch = useDispatch()

  const getdata = () => {
    ApiClient.get('user/detail', { id: user?.id }).then((res) => {
      if (res?.success) {
        console.log(res)
        setpermissions(res?.permissions)
      }
    })
  }
  useEffect(() => {
    getdata()
    console.log(permissions)
  }, [])
  console.log(permissions)
  return (
    <ul className="nav flex-column">

      {/* <ListItemLink to="/dashboard">
        <i className="fa fa-list mr-2" aria-hidden="true"></i>
        <span>Dashboard</span>
      </ListItemLink> */}
      {user.role != 'translater' ? <>
        <ListItemLink to="/dashboard" type='main'>
          <Link to="/dashboard" className='nav-link hoverclass'>

            <img style={{ width: '20px' }} className='mr-2' src={img} alt="" />
            <span>Dashboard</span>
          </Link>


        </ListItemLink>
      </> : <></>
      }


      {user.role != 'translater' ? <>
        <ListItemLink to="/allusers" type='main'>
          <Link to="/allusers" className='nav-link hoverclass'>
            <i className="fa fa-user mr-2" aria-hidden="true"></i>
            <span>User Management</span>
          </Link>


        </ListItemLink>
      </> : <></>
      }


      {user.role != 'translater' ? <>
        <ListItemLink to="/subscription" type='main'>
          <Link to="/subscription/features" className='nav-link hoverclass'>
            <i className="fa fa-shopping-bag mr-2" aria-hidden="true"></i>
            <span>Subcription Plan</span>
          </Link>

          <div className='submenu'>
            <ListItemLink to="/subscription/features">
              <i className="fa fa-shopping-bag mr-2" aria-hidden="true"></i>
              <span>Plan Features</span>
            </ListItemLink>
            <ListItemLink to="/subscription/plans">
              <i className="fa fa-tasks mr-2" aria-hidden="true"></i>
              <span>Plan</span>
            </ListItemLink>

          </div>
        </ListItemLink>
      </> : <></>
      }
      {user.role != 'translater' ? <>
        <ListItemLink to="/categorytypes/category" type='main'>
          <Link to="/categorytypes/category" className='nav-link hoverclass'>
            <i className="fa fa-tasks mr-2" aria-hidden="true"></i>
            <span>Manage Category</span>
          </Link>

          {/* <div className='submenu'>
         
            <ListItemLink to="/categorytypes/category">
              <i className="fa fa-user mr-2" aria-hidden="true"></i>
              <span>Categories</span>
            </ListItemLink>
     
          
          </div> */}
        </ListItemLink>
      </> : <></>
      }

      {/* 
{user.role != 'translater' ? <>
        <ListItemLink to="/questions" type='main'>
          <Link to="/questions" className='nav-link hoverclass'>
            <i className="fa fa-user mr-2" aria-hidden="true"></i>
            <span>Manage Questions</span>
          </Link>

         
        </ListItemLink>
      </> : <></>
      } */}
      {/* {user.role != 'translater' ? <>
        <ListItemLink to="/settings/content/faq" type='main'>
          <Link to="/settings/content/faq" className='nav-link hoverclass'>
            <i className="fa fa-user mr-2" aria-hidden="true"></i>
            <span>Manage FAQ</span>
          </Link>

          <div className='submenu'>
      
         
          
          </div>
        </ListItemLink>
      </> : <></>
      } */}
      {user.role != 'translater' ? <>
        <ListItemLink to="/contentpage" type='main'>
          <Link to="/contentpage" className='nav-link hoverclass'>
            <i className="fa fa-book mr-2" aria-hidden="true"></i>
            <span>Content Management</span>
          </Link>


        </ListItemLink>
      </> : <></>
      }
      {user.role != 'translater' ? <>
        <ListItemLink to="/questions" type='main'>
          <Link to="/questions" className='nav-link hoverclass'>
            <i className="fa fa-question-circle mr-2" aria-hidden="true"></i>
            <span>Question Management</span>
          </Link>


        </ListItemLink>
      </> : <></>
      }
      {user.role != 'translater' ? <>
        <ListItemLink to="/FAQ" type='main'>
          <Link to="/FAQ" className='nav-link hoverclass'>
            <i className="fa fa-question mr-2" aria-hidden="true"></i>
            <span>FAQ Management</span>
          </Link>


        </ListItemLink>
      </> : <></>
      }

      {user.role != 'translater' ? <>
        <ListItemLink to="/appointment" type='main'>
          <Link to="/appointment" className='nav-link hoverclass'>
            <i className="fa fa-shopping-bag mr-2" aria-hidden="true"></i>
            <span>Appointments</span>
          </Link>

          <div className='submenu'>
          
            <ListItemLink to="/appointment/reschduled">
              <i className="fa fa-tasks mr-2" aria-hidden="true"></i>
              <span>Reschduled Appointment</span>
            </ListItemLink>
            <ListItemLink to="/appointment-reviews" type='main'>
          <Link to="/appointment-reviews" className='nav-link hoverclass'>
            <i className="fa fa-calendar mr-2" aria-hidden="true"></i>
            <span>Reviews</span>
          </Link>

        </ListItemLink>

          </div>
        </ListItemLink>
      </> : <></>
      }
          {user.role != 'translater' ? <>
       
      </> : <></>
      }
      
          {user.role != 'translater' ? <>
        <ListItemLink to="/payments" type='main'>
          <Link to="/payments" className='nav-link hoverclass'>
            <i className="fa fa-credit-card mr-2" aria-hidden="true"></i>
            <span>Payments</span>
          </Link>

        </ListItemLink>
      </> : <></>
      }
      
      {user.role != 'translater' ? <>
        <ListItemLink to="/availability" type='main'>
          <Link to="/availability" className='nav-link hoverclass'>
            <i className="fas fa-clock mr-2" aria-hidden="true"></i>
            <span>Availability</span>
          </Link>


        </ListItemLink>
      </> : <></>
      }
       {user.role != 'translater' ? <>
        <ListItemLink to="/testimonials" type='main'>
          <Link to="/testimonials" className='nav-link hoverclass'>
            <i className="fas fa-clock mr-2" aria-hidden="true"></i>
            <span>Testimonials</span>
          </Link>


        </ListItemLink>
      </> : <></>
      }
      {user.role != 'translater' ? <>
        <ListItemLink to="/profile" type='main'>
          <Link to="/profile" className='nav-link hoverclass'>
            <i className="fa fa-cog mr-2" aria-hidden="true"></i>
            <span>Settings</span>
          </Link>
    
          <div className='submenu' onClick={() => {
            dispatch(logout())
          }}>

            <ListItemLink to="">
              <i className="fa fa-user mr-2" aria-hidden="true"></i>
              <span>Log Out</span>
            </ListItemLink>


          </div>

        </ListItemLink>
      </> : <></>
      }
      {/* {user.role != 'translater' ? <>
        <ListItemLink to="/permissions" type='main'>
          <Link to="/permissions" className='nav-link hoverclass'>
            <i className="fa fa-user mr-2" aria-hidden="true"></i>
            <span>Permissions Mangement</span>
          </Link>

        
        </ListItemLink>
      </> : <></>
      } */}

      {/* <ListItemLink to="/settings" type='main'>
        <Link to="categorytypes" className='nav-link hoverclass'>
          <i className="fa fa-user mr-2" aria-hidden="true"></i>
          <span>Settings</span>
        </Link>
        <div className='submenu'>
        <li className={`nav-item main ${matchUrl('/settings/categorytype') ? 'active' : ''}`}>
            <Link to="/settings/categorytypes" className='nav-link hoverclass'>
              <i className="fa fa-tasks mr-2" aria-hidden="true"></i>
              <span>Assessment</span>
            </Link>
            </li>
        </div>
        <div className='submenu'>
          <li className={`nav-item main ${matchUrl('/settings/categorytype') ? 'active' : ''}`}>
            <Link to="/settings/categorytypes" className='nav-link hoverclass'>
              <i className="fa fa-tasks mr-2" aria-hidden="true"></i>
              <span>Assessment</span>
            </Link>
            <div className='submenu child2'>
              <li className={`nav-item main ${matchUrl('/settings/categorytypes') ? 'active' : ''}`}>
                <Link to="/settings/categorytypes" className='nav-link hoverclass'>
                  <i className="fa fa-tasks mr-2" aria-hidden="true"></i>
                  <span>Assessment Type</span>
                </Link>
              </li>
              <ListItemLink to="/settings/categorytype/questions">
                <i className="fa fa-question-circle mr-2" aria-hidden="true"></i>
                <span>Questions (previously Questions)</span>
              </ListItemLink>
            </div>
          </li>
          <li className={`nav-item main ${matchUrl('/settings/content') ? 'active' : ''}`}>
            <Link to="/settings/content" className='nav-link hoverclass'>
              <i className="fa fa-tasks mr-2" aria-hidden="true"></i>
              <span>Content</span>
            </Link>
            <div className='submenu child2'>
              <ListItemLink to="/settings/content">
                <i className="fa fa-tasks mr-2" aria-hidden="true"></i>
                <span>Content Management</span>
              </ListItemLink>

              <ListItemLink to="/settings/content/blogcategory">
                <i className="fa fa-tasks mr-2" aria-hidden="true"></i>
                <span>Blog category</span>
              </ListItemLink>

              <ListItemLink to="/settings/content/blogs">
                <i className="fa fa-tasks mr-2" aria-hidden="true"></i>
                <span>Blogs</span>
              </ListItemLink>
              <ListItemLink to="/settings/content/faq">
                <i className="fa fa-question-circle mr-2" aria-hidden="true"></i>
                <span>FAQ</span>
              </ListItemLink>
            </div>
          </li>
          <li className={`nav-item main ${matchUrl('/settings/appointment') ? 'active' : ''}`}>
            <Link to="/settings/appointment/reschedule-time" className='nav-link hoverclass'>
              <i className="fa fa-tasks mr-2" aria-hidden="true"></i>
              <span>Appointment Booking</span>
            </Link>
            <div className='submenu child2'>
              <ListItemLink to="/settings/appointment/reschedule-time">
                <i className="fa fa-cog mr-2" aria-hidden="true"></i>
                <span>Reschedule Time</span>
              </ListItemLink>
              <ListItemLink to="/settings/appointment/appointment-reminder">
                <i className="fa fa-clock mr-2" aria-hidden="true"></i>
                <span>Appointment Reminder</span>
              </ListItemLink>
              <ListItemLink to="/settings/appointment/contact-details">
                <i className="fa fa-envelope mr-2" aria-hidden="true"></i>
                <span>Contact details</span>
              </ListItemLink>
            </div>
          </li>

          <li className={`nav-item main ${matchUrl('/settings/casenote') ? 'active' : ''}`}>
            <Link to="/settings/casenote-fields" className='nav-link hoverclass'>
              <i className="fa fa-tasks mr-2" aria-hidden="true"></i>
              <span>Case Note</span>
            </Link>
            <div className='submenu child2'>
              <ListItemLink to="/settings/casenote-fields">
                <i className="fa fa-tasks mr-2" aria-hidden="true"></i>
                <span>Case Note Custom Field</span>
              </ListItemLink>
            </div>
          </li>


          <ListItemLink to="/settings/healthclinic">
            <i className="	fa fa-medkit mr-2"></i>
            <span>Health Clinic</span>
          </ListItemLink>
          <li className={`nav-item main ${matchUrl('/settings/translation') ? 'active' : ''}`}>
            <Link to="/settings/translation" className='nav-link hoverclass'>
              <i className="fa fa-language mr-2" aria-hidden="true"></i>
              <span>Translation</span>
            </Link>
          </li>
          <ListItemLink to="/settings/logo-management">
            <i className="fa fa-list mr-2" aria-hidden="true"></i>
            <span>Logo Management</span>
          </ListItemLink>
        </div>

      </ListItemLink> */}




    </ul >
  );
};

export default Sidebar;
