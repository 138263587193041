import React, { useEffect, useState } from 'react';
import moment from 'moment';
import './style.css';
import { Navbar, Dropdown, Button, Form, Col, Row, Modal } from "react-bootstrap";
import ApiClient from '../../methods/api/apiClient';
import loader from '../../methods/loader';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


function CalendarCard({ user, HandleSubmit }) {
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedPostDate, setSelectedPostDate] = useState(null);
    const [currentDate, setCurrentDate] = useState(moment());
    const [filteredData, setFilteredData] = useState([]);
    const [show, setShow] = useState(false);
    const [showput, setShowput] = useState(false);
    const [data, setData] = useState([])
    const history = useHistory()
    const [filterData, setFilterData] = useState([])
    const [id, setId] = useState("")
    const [ParticularData, setParticularData] = useState('')
    const [selectedSlots, setSelectedSlots] = useState(filterData ? filterData : []);
    useEffect(() => {
        const filter = data.filter(item => {
            if (item.date == selectedPostDate?.replace(/\//g, '-')) {
                return item
            }
        })

        setFilterData(filter?.[0]?.time || []);
        setId(filter?.[0]?._id)

    }, [selectedPostDate])

    useEffect(() => {
        setSelectedSlots(filterData)
    }, [filterData])

    console.log(selectedSlots, "selectedSlots")

    const transformedData = data.map(item => ({
        name: item?.name,
        date: item?.date?.replace(/\-/g, '/'),
        time: item?.time,
        status: item?.status
    }));

    const handleClose = () => {
        setShow(false)
        setSelectedSlots(filterData)
    };
    const handleShow = () => {
        setShow(true)
        setSelectedSlots(filterData)
    };
    const handlePutClose = () => setShowput(false);
    const handlePutShow = () => setShowput(true);
    const [dateData, setDateData] = useState(transformedData);

    useEffect(() => {
        setDateData(transformedData)
    }, [data])

    const timeSlots = [
        '09-10 AM',
        '10-11 AM',
        '11-12 AM',
        '12-01 PM',
        '14-15 PM',
        '15-16 PM',
        '16-17 PM',
        '18-19 PM',
    ];

    const sendAvaibility = {
        schedule: [
            {
                date: selectedPostDate?.replace(/\//g, '-'),
                time: selectedSlots
            }
        ]
    }

    const postData = () => {
        if (selectedSlots > 0) {
            return
        }
        ApiClient.post('availability', sendAvaibility).then((res) => {
            if (res.success) {
                handleClose()
                getData()
                // setData(res?.data)
                window.location.reload(true);
            }
        })
    }

    const updateData = {
        "id": id,
        "time": selectedSlots
    }

    const putData = () => {
        loader(true)
        ApiClient.put('availability', updateData).then((res) => {
            if (res.success) {
                handlePutClose()
                getData()
                window.location.reload(true);
                // setData(res?.data)
            }
            loader(false)
        })
    }

    const handleSummit = () => {
        if (selectedSlots > 0) {
            return
        }
        postData()
    }

    const handlePutSummit = () => {
        if (selectedSlots > 0) {
            return
        }
        putData()
    }

    const getData = () => {
        loader(true)
        ApiClient.get('admin/appointmentslist').then((res) => {
            if (res.success) {
                // console.log(res.data,"This is my data that we need ")
                setData(res?.data)
            }
            loader(false)

        })
    }

    useEffect(() => {
        getData()
    }, [])

    const handleCheckboxChange = (slot) => {
        console.log(slot, '==Slot');
        const isSelected = selectedSlots?.includes(slot);
        console.log(isSelected, '=IS Selevted');
        if (isSelected) {
            const updatedSlots = selectedSlots?.filter(selectedSlot => selectedSlot !== slot);
            setSelectedSlots(updatedSlots);
        } else {
            setSelectedSlots([...selectedSlots, slot]);
        }
    };


    const handleDateHover = (date) => {
        setSelectedDate(date);
    };

    const handleDateClick = (formattedDate, slots) => {
        if (slots.length > 0) {
            handleShow()
            setSelectedPostDate(formattedDate)
            setParticularData(slots)
        }
    }

    const nextMonth = () => {
        setCurrentDate(moment(currentDate).add(1, 'months'));
    };

    const prevMonth = () => {
        setCurrentDate(moment(currentDate).subtract(1, 'months'));
    };

    const today = moment(currentDate);
    const daysInMonth = today.daysInMonth();
    const firstDayOfMonth = moment(today).startOf('month').day();
    const monthYear = today.format('MMMM YYYY');

    const calendarDays = [];
    let dayCounter = 1;

    for (let i = 0; i < 6; i++) {
        const weekDays = [];

        for (let j = 0; j < 7; j++) {
            if ((i === 0 && j < firstDayOfMonth) || dayCounter > daysInMonth) {
                weekDays.push(<td key={`${i}-${j}`}></td>);
            } else {
                const currentDate = moment(today).date(dayCounter);
                let formattedDate = currentDate.format('YYYY/MM/DD');
                const slotsForDate = dateData.filter((data) => data.date === formattedDate);

                const slots = slotsForDate.map((slot) => ({
                    time: slot.time,
                    name: slot.name,
                    status: slot.status
                }));

                // const slots = slotsForDate ? [{ time: slotsForDate.time, name: slotsForDate.name,status: slotsForDate.status }] : [];

                let buttonClass = '';
                if (slots.length > 0) {
                    buttonClass = 'has-slots';
                } else {
                    buttonClass = 'no-slots';
                }

                // Compare the current date with the date being generated
                const isPastDate = currentDate.isBefore(moment(), 'day');

                weekDays.push(
                    <td key={`${i}-${j}`} className={`${selectedDate === formattedDate ? 'selected' : ''} ${buttonClass}`}
                        onClick={ () => handleDateClick(formattedDate, slots)}
                            // isPastDate || currentDate.isAfter(currentDate.clone().endOf('day'))? '' :
                        onMouseEnter={() => handleDateHover(formattedDate)}
                        onMouseLeave={() => handleDateHover(null)}
                    >
                        <button
                            // disabled={isPastDate || currentDate.isAfter(currentDate.clone().endOf('day'))}
                        >
                            {dayCounter}
                        </button>
                        {/* {selectedDate === formattedDate && (<>
                            {slots?.length > 0 && <div className="slots-popup">
                                <>
                                    <div className='w-100'>
                                        <p className='mb-2 fs16'>User Details</p>
                                    </div>
                                    {slots?.length > 0 ? (
                                        <ul className='slot_popsdata'>

                                            {slots?.map((slot, index) => (<>
                                                <li key={index}> Name: {slot.name}</li>
                                                <li key={index}>  Time:  {slot.time}</li>
                                                <li key={index}> Status: {slot.status}</li>
                                            </>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p></p>
                                    )}
                                </>
                            </div>}
                        </>
                        )} */}
                    </td>
                );
                dayCounter++;
            }
        }
        calendarDays.push(<tr key={i}>{weekDays}</tr>);
    }


    // const HandleCheckBox = (request) => {
    //     console.log(request, "This is the data that we want -----------")
    // }
    return (
        <>

            <div className="calendar-container">
                <div className=" pprofile1">
                    <div className="table-responsive mb-3">
                        <ul className="nav nav-tabs tabnoWrap">
                            <li className="nav-item">
                                <a className={`nav-link active `} >  Calender View</a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link  `} onClick={() => {
                                    history.push('/appointment')
                                }}>List View</a>

                            </li>
                        </ul>
                    </div>
                    <h4 className='ViewUser'>Calender View</h4>
                    <div className='row'>
                        <div className='col-12 col-sm-12 col-md-12'>
                            <div>
                                <div className="calendar-nav">
                                    <button className='btn btn-primary' onClick={prevMonth}> <i className='fa fa-angle-left'></i> Previous</button>
                                    <h6 className='m-0 colorblue'>{monthYear}</h6>
                                    <button className='btn btn-primary' onClick={nextMonth}>Next <i className='fa fa-angle-right'></i></button>
                                </div>
                                <table className='height300'>
                                    <thead>
                                        <tr>
                                            <th>Sun</th>
                                            <th>Mon</th>
                                            <th>Tue</th>
                                            <th>Wed</th>
                                            <th>Thu</th>
                                            <th>Fri</th>
                                            <th>Sat</th>
                                        </tr>
                                    </thead>
                                    <tbody>{calendarDays}</tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* modal */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>User Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                                <>
                                    {/* <div className='w-100'>
                                        <p className='mb-2 fs16'>User Details</p>
                                    </div> */}
                                    {ParticularData?.length > 0 ? (
                                        <div className='slot_popsdata row'>

                                            {ParticularData?.map((slot, index) => (<>
                                                <div className='col-sm-12 col-md-6'>
                                                        <div className='shdaw_wraper'>
                                                        <div className='flex_wrap' key={index}>
                                                            <span>Name:</span>

                                                            <span>{slot.name}</span>
                                                              
                                                             
                                                        </div>
                                                        <div className='flex_wrap' key={index}>

                                                        <span>Time:</span>

                                                        <span>{slot.time}</span>
                                                             
                                                              
                                                              </div>
                                                        <div className='flex_wrap' key={index}>

                                                        <span>Status:</span>

                                                        <span>{slot.status}</span>
                                                            
                                                             
                                                             </div>
                                                        </div>
                                                </div>
                                            </>
                                            ))}
                                        </div>
                                    ) : (
                                        <p></p>
                                    )}
                                </>
                        </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    {/* <Button variant="primary" onClick={handleSummit}>
                        Save Changes
                    </Button> */}
                </Modal.Footer>
            </Modal>

        </>
    );
}
export default CalendarCard;
