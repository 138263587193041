import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Layout from '../../components/global/layout';
import ApiClient from '../../methods/api/apiClient';
// import Pagination from "react-js-pagination";
import dateFormat, { masks } from "dateformat";
import Pagination from "react-pagination-js";
import './style.scss';
import { ToastsStore } from 'react-toasts';
import loader from '../../methods/loader';
import AddEditUser from './AddEditUser';
import InviteModal from './inviteModal';
import { Link } from 'react-router-dom';
import userTableModel from '../../models/userTable.model';
import rolesModel from "../../models/roles.model";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Swal from 'sweetalert2';
import methodModel from '../../methods/methods';
import { toast } from 'react-toastify';

const Allusers = (p) => {
    let user = useSelector(state => state.user)
    const searchState = useSelector((state) => state.search);
    const [filters, setFilter] = useState({ page: 1, count: 7, search: '', role: '', sortBy: '', status: '' })
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [sort, setsort] = useState({ name: 'fullName asc', email: 'email asc', total_team_amount: 'total_team_amount asc', role: '', createdAt: '', updatedAt: '', status: '',is_plan:'' })
    const history = useHistory()
    const [loaging, setLoader] = useState(true)
    const [form, setform] = useState({ role: 'user', firstName: '', lastName: '', email: '', password: '', mobileNo: '', dialCode: '+60', })
    const [formData, setFormData] = useState({ email: '' })
    const [submitted, setSubmitted] = useState(false)
    const [inviteForm, setInviteForm] = useState({ email: '' })
    const [emailErr, setEmailErr] = useState('')
    const [icErr, setIcErr] = useState('')
    const [tableCols, setTableCols] = useState([])
    const [permissions, setpermissions] = useState({})



    const getdata = () => {
        ApiClient.get('user/detail', { id: user?.id }).then((res) => {
            if (res?.success) {
                console.log(res)
                setpermissions(res?.permissions)
            }
        })
    }
    useEffect(() => {
        getdata()
        console.log(permissions)
    }, [])
    useEffect(() => {
       
        let cols = []
        for (let i = 0; i <= 4; i++) {
            cols.push(userTableModel.list[i])
        }
        console.log(user)
        setTableCols(cols)
    }, [])

    useEffect(() => {
        if (user && user.loggedIn) {
            setFilter({ ...filters, search: searchState.data })
            getData({ search: searchState.data, page: 1 })
        }
    }, [searchState])


    const uTableCols = () => {
        let exp = []
        if (tableCols) exp = tableCols
        let value = []
        userTableModel.list.map(itm => {
            if (itm != exp.find(it => it.key == itm.key)) {
                value.push(itm)
            }
        })

        return value
    }

    const addCol = (itm) => {
        setTableCols([...tableCols, itm])
    }


    const removeCol = (index) => {
        let exp = tableCols
        exp.splice(index, 1);
        setTableCols([...exp])
    }

    const getData = (p = {}) => {
        setLoader(true)
        let filter = { ...filters, ...p }
        ApiClient.get('users/list', filter).then(res => {
            if (res.success) {
                setData(res.data?.data)
                setTotal(res.data?.total)
            }
            setLoader(false)
        })


    }


    const clear = () => {
        setFilter({ ...filters, search: '', page: 1 })
        getData({ search: '', page: 1 })
    }

    const deleteItem = (id) => {

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                let url = 'admin/delete-user'
                let model = ""
                if (user?.role == 'sub_admin') {
                    url = "delete"
                    model = "users"
                }
                // loader(true)
                ApiClient.delete(url, { model: model, id: id }).then(res => {
                    if (res.success) {
                        ToastsStore.success(res.message)
                        clear()
                    }
                    // loader(false)
                })
            }
        })
    }

    const sortDataTable1 = (e) => {
        setFilter({ ...filters, sortBy: e })
        if (e == 'fullName asc' || e == 'fullName desc') setsort({ ...sort, name: e })
        if (e == 'email asc' || e == 'email desc') setsort({ ...sort, email: e })
        if (e == 'role asc' || e == 'role desc') setsort({ ...sort, role: e })
        if (e == 'createdAt asc' || e == 'createdAt desc') setsort({ ...sort, createdAt: e })
        if (e == 'updatedAt asc' || e == 'updatedAt desc') setsort({ ...sort, updatedAt: e })
        if (e == 'active' || e == 'deactive') setsort({ ...sort, status: e })
        getData({ sortBy: e })
    }
    const sortDataTable2 = (e) => {
        setFilter({ ...filters, sortBy: e })

        if (e == 'active' || e == 'deactive') setsort({ ...sort, status: e })
        getData({ status: e })
    }
    const sortByMembership = (e) => {
        // setFilter({ ...filters, sortBy: e })

        if (e == true || e == false) setsort({ ...sort, is_plan: e })
        getData({ is_plan: e })
    }
    const pageChange = (e) => {
        setFilter({ ...filters, page: e })
        getData({ page: e })
    }

    const statusChange = (e) => {
        setFilter({ ...filters, status: e })
        getData({ status: e })
    }

    const modalClosed = () => {
        setFilter({ ...filters, page: 1 })
        getData({ page: 1 })
    }


    const openModal = (itm = {}) => {
        setSubmitted(false)
        setEmailErr('')
        setIcErr('')
        setform({ role: 'user', firstName: '', lastName: '', email: '', password: '', mobileNo: '', dialCode: '+60', ...itm })
        setFormData({ ...formData, ...itm })
        if (itm.id) {
            setform({ ...form, ...itm, password: '********' })
        }
        document.getElementById("openuserModal").click()
    }

    const ChangeRole = (e) => {
        setFilter({ ...filters, role: e, page: 1 })
        getData({ role: e, page: 1 })
    }

    const invite = (role) => {
        document.getElementById("openInviteModal").click()
        setInviteForm({ email: '', role: role })
        setSubmitted(false)
        setEmailErr('')
    }

    const exportCsv = () => {
        loader(true)
        ApiClient.get('user/csv').then(res => {
            if (res.success) {
                let url = res.path
                let downloadAnchor = document.getElementById("downloadJS")
                downloadAnchor.href = url
                downloadAnchor.click()
            }
            loader(false)
        })
    }


    const ChangeStatus = (id, status) => {
        if (window.confirm(`Do you want to ${status == 'active' ? 'Deactivate' : 'Activate'}`)) {
            ApiClient.put('change/status', { model: 'users', id: id, status: status == 'active' ? 'deactive' : 'active' }).then((res) => {
                if (res.success) {
                    getData()
                }
            })
        }
    }



    const colClick = (col, itm) => {
        if (col.key == 'healthClinicId') {
        }
    }

    const handleCountChange = (count) => {
        setFilter({ ...filters, count: count, page: 1 });
        getData({ count: count, page: 1 });
    };

    const SendNotify = (id) => {
        ApiClient.get('reminder', { id: id }).then((res) => {
            if (res.success) {
                ToastsStore.success(res.message)
            }
        })
    }

    return (
        <Layout>
            <div className="d-flex justify-content-between mb-3 main_title">
                <h3 className="hedding">
                    All Users
                </h3>

                <article className="d-flex">
                    {/* <div className="dropdown addDropdown mr-2">
                        <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Invite
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            {user.role == 'Owner' && <a className="dropdown-item" onClick={() => invite('admin')}>Admin</a>}
                            {user.role == 'Owner' && <a className="dropdown-item" onClick={() => invite('Owner')}>Owner</a>}
                            <a className="dropdown-item" onClick={() => invite('Clinic Admin')}>Clinic Admin</a>
                            <a className="dropdown-item" onClick={() => invite('Counsellor')}>Counsellor</a>
                            <a className="dropdown-item" onClick={() => invite('user')}>User</a>
                            <a className="dropdown-item" onClick={() => invite('translater')}>Translater</a>
                        </div>
                    </div> */}
                    {
                        user.role == 'admin' || permissions?.user_add ? <button className="btn btn-primary mr-2" onClick={() => history.push('/allusers/adduser')}>
                            Add User
                        </button> : null
                    }
                   
                    <div className="dropdown addDropdown mr-2">
                        <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuColumns" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Status :
                            {
                                !filters.status ? 'All' : `${filters.status == 'active' ? 'Active' : 'Inactive'}`
                            }
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuColumns">
                            <a className='dropdown-item' onClick={() => statusChange('')}>All</a>
                            <a className='dropdown-item' onClick={() => statusChange('active')}>Active</a>
                            <a className='dropdown-item' onClick={() => statusChange('deactive')}>Inactive</a>
                        </div>
                    </div>



                    {/* <button onClick={exportCsv} className="btn btn-primary" type="button">
                        Export
                    </button> */}
                </article>


            </div>
            <div className="table-responsive">
                <table className="table mb-0">
                    <thead className="theadclss">
                        <tr className="tblclas">

                            <th scope='col'>Ful Name
                                <i class="material-icons  pointer hjik mr-2" onClick={() => sortDataTable1(sort.name == 'fullName asc' ? 'fullName desc' : 'fullName asc')} title="Sort">heighticon</i>
                            </th>
                            <th scope='col'>Email
                                <i class="material-icons  pointer hjik mr-2" onClick={() => sortDataTable1(sort.email == 'email asc' ? 'email desc' : 'email asc')} title="Sort">heighticon</i>
                            </th>
                            <th scope='col'>Role
                                <i class="material-icons  pointer hjik mr-2" onClick={() => filters.role == 'sub_admin' ? ChangeRole("patient") : ChangeRole("sub_admin")} title="Sort">heighticon</i>
                            </th>
                            <th scope='col'>Membership
                                <i class="material-icons  pointer hjik mr-2" onClick={() => sortByMembership(sort.is_plan == true ? false:true)} title="Sort">heighticon</i>

                            </th>
                            <th scope='col'>Date Created

                            </th>
                            {/* <th scope='col'>Date Updated
                                <i class="material-icons  cursor:pointer hjik mr-2" onClick={() => sortDataTable1(sort.updatedAt == 'updatedAt asc' ? 'updatedAt desc' : 'updatedAt asc')} title="Sort">heighticon</i>
                            </th> */}
                            <th scope='col'>Status

                            </th>
                            <th scope='col' className="nowrap">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!loaging && data && data.map((itm, i) => {
                            return <tr  key={i}>

                                <td onClick={() => {
                                    if (user.role == 'admin' || permissions?.user_get) {


                                        history.push('/allusers/detail/' + itm._id)
                                    } else return null
                                }} key={itm.key} className={`nowrap cursor-pointer`}>
                                    {methodModel?.capitalizeName(itm.fullName)}
                                </td>
                                <td key={itm.key} className={`nowrap`}>
                                    {itm.email}
                                </td>
                                <td key={itm.key} className={`nowrap`}>
                                    {rolesModel.name(itm.role)}
                                </td>
                                <td key={itm.key} className={`nowrap`}>

                                    <div key={itm.key} className={`nowrap ${itm?.is_plan ? "paid" : "unpaid"}`}>
                                        {itm?.is_plan ? "Paid" : "Unpaid"}




                                    </div>
                                </td>
                                <td key={itm.key} className={`nowrap`}>
                                    {dateFormat(itm.createdAt, 'dd/mm/yy')}
                                </td>
                                {/* <td key={itm.key} className={`nowrap`}>
                                    {dateFormat(itm.updatedAt, 'dd/mm/yy')}
                                </td> */}
                                <td>
                                    <div key={itm.key} onClick={() => {
                                        ChangeStatus(itm.id, itm.status)
                                    }} className={`${itm.status == 'active' ? 'activeItm' : 'deactiveItm'} `}>
                                        {itm.status}
                                    </div>
                                </td>

                                <td className="nowrap">

                                    {
                                        user?.role == 'admin' || permissions?.user_get ?
                                            <Link className="linkclass mx-2" title="View" to={'allusers/detail/' + itm.id}>
                                                <i className="fa fa-eye"></i></Link> : null
                                    }
                                    {
                                        user.role == 'admin' || permissions?.user_edit ? <>
                                            <a className="linkclass mx-2" title="Edit" onClick={() => history.push('/allusers/edituser/' + itm
                                                .id)}><i className="fa fa-pen"></i></a>
                                        </> : null
                                    }
                                    {
                                        user?.role == 'admin' || permissions.user_delete ? <>
                                            <a className="linkclass mx-2" title="Delete" onClick={() => deleteItem(itm.id)}><i className="fa fa-trash"></i></a>

                                        </> : null}
                                        {
                                        !itm?.is_plan &&
                                        <i onClick={() => {
                                            SendNotify(itm.id)
                                          
                                        }} class="fa fa-bell ml-2 pointer" title="Notify" aria-hidden="true"></i>
                                    }
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>

                {loaging ? <div className="text-center py-4">
                    <img src="/assets/img/loader.gif" className="pageLoader" />
                </div> : <></>}
                {!loaging && total == 0 ? <div className="py-3 text-center">No Data</div> : <></>}
            </div>


            {
                !loaging && total > filters.count ? <div className='d-flex justify-content-between align-items-center flex-wrap'>
                    {/* <Pagination
                        activePage={filters.page}
                        itemsCountPerPage={filters.count}
                        totalItemsCount={total}
                        pageRangeDisplayed={5}
                        onChange={pageChange}
                    /> */}
                    <div className='d-flex align-items-center justify-content-center' >
                        <span>Show: </span>
                        <select
                            className="form-control ml-2"
                            onChange={(e) => handleCountChange(parseInt(e.target.value))}
                            value={filters.count}
                        >
                            <option value={7}>7</option>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                            <option value={40}>40</option>
                        </select>
                    </div>
                    <Pagination
                        currentPage={filters.page}
                        totalSize={total}
                        sizePerPage={filters.count}
                        changeCurrentPage={pageChange}
                    />
                </div> : <></>
            }

            <AddEditUser formData={formData} form={form} submitted={submitted} emailErr={emailErr} setIcErr={setIcErr} icErr={icErr} setEmailErr={setEmailErr} setSubmitted={setSubmitted} setform={setform} modalClosed={modalClosed} />
            <InviteModal form={inviteForm} setform={setInviteForm} submitted={submitted} emailErr={emailErr} setEmailErr={setEmailErr} setSubmitted={setSubmitted} modalClosed={modalClosed} />
        </Layout >
    );
};

export default Allusers;
