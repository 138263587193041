import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../../components/global/layout';
import ApiClient from '../../../methods/api/apiClient';
import loader from '../../../methods/loader';
import './profile.scss'
import { useSelector } from 'react-redux';
import methodModel from '../../../methods/methods';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';


const Profiledetail = () => {
  const { id } = useParams()
  const history = useHistory()
  const user = useSelector((state) => state.user);
  const [data, setData] = useState('');
  const [permission, setPermission] = useState()
  const [question, setquestions] = useState([])
  console.log(user)
  const gallaryData = () => {
    loader(true)
    ApiClient.get(`user/detail`, { id: id }).then(res => {
      if (res.success) {
        setData(res.data)
        setPermission(res)
      }
      loader(false)

    })
  };

  useEffect(() => {
  
    ApiClient.get('userQuestion/listing', { user_id: id }).then((res) => {
      if (res.success) {
        console.log(res)
        setquestions(res?.data)
      }
    })
  }, [])

  useEffect(
    () => {
      if (user && user.loggedIn) {
        gallaryData();
      }
    },
    []
  );

  return (
    <Layout>

      <div className="d-flex justify-content-between align-items-center main_title mb-3">
        <h3 className='hedding'>View User</h3>
        <div className='text-right'><Link to="/allusers"><i className="fa fa-arrow-left" title='Back' aria-hidden="true"></i></Link></div>
        {/* <Link to="/profile/edit" className="btn btn-primary">
          <i className="fa fa-edit" title='Edit Profile' />
        </Link> */}
      </div>

      <div className='pprofile1'>
        <div className="form-row position-relative">
          <div className='edit_delete_wrap '>
            {
              user.role == 'admin' || user?.permissions?.user_edit ? <>
                <a className="linkclass mx-2" title="Edit" onClick={() => history.push('/allusers/edituser/' + data
                  .id)}><i className="fa fa-pen  mr-2"></i>Edit</a>
              </> : null
            }
            {/* {
              user?.role == 'admin' || permissions.user_delete ? <>
                <a className="linkclass mx-2" title="Delete" onClick={() => deleteItem(data.id)}><i className="fa fa-trash"></i></a>

              </> : null} */}
          </div>

          <div className="col-md-12 text-center mb-3">
            <label className="profileImageLabel">
              <img src={methodModel.userImg(data.image)} className="profileImage" />
            </label>
          </div>

          <div className="col-md-6">
            <label>Full Name</label>
            <p className="bg-light rounded px-3 py-2 fieldcls">{data.fullName}</p>
          </div>

          <div className="col-md-6">
            <label>Email</label>
            <p className="bg-light rounded px-3 py-2 fieldcls">{data.email}</p>
          </div>


          {data.role == 'user' && <div className="col-md-6">
            <label>Mobile No</label>
            <p className="bg-light rounded px-3 py-2 fieldcls">{data.dialCode + data.mobileNo || "--"}</p>
          </div>}
          <div className="col-md-6">
            <label>Role</label>
            <p className="bg-light rounded px-3 py-2 fieldcls">{data.role || "--"}</p>
          </div>
          <div className="col-md-6">
            <label>Mobile No</label>
            <p className="bg-light rounded px-3 py-2 fieldcls">{data.dialCode + data.mobileNo || "--"}</p>
          </div>
            {
              data?.reason?
          <div className="col-md-6">
              <label>What Brings You Here</label>
              <p className="bg-light rounded px-3 py-2 fieldcls">{data.reason || "--"}</p>
              </div>:null
            }
          <div className='col-md-12 ViewUser'>
            <label>Questions</label>
          </div>
          {
            question.map((itm) => {
              return (
                <div className="col-md-6">


                  <p className="bg-light rounded px-3 py-2 fieldcls">Ques: {itm?.user_question}</p>

                  {itm?.user_answer?.map((item) => {
                    return (
                      <p className="bg-light rounded px-3 py-2 fieldcls">Ans: {item?.option}</p>
                    )
                  })}
                </div>
              )
            })
          }



          {data.role == 'sub_admin' ?
            <div className="col-md-12 mb-3">
              <h5 className="mb-0 mt-4">
                <b>Permissions</b>
              </h5>
              {/* roles */}
              {/* 1st */}

              <div class="table-responsive">
                <div class="table_section tablepadding">
                  <table class="table table-striped">
                    <thead class="table_head roleTable">
                      <tr class="heading_row">
                        <th scope="col" class="table_data"></th>
                        {/* <th scope="col" class="table_data">
                          <input
                            type="checkbox"
                            className="mr-2"
                            onChange={(e) => HandleAll(e.target.checked)}
                            checked={isAllChecked()}
                          />
                          All
                        </th> */}
                        <th scope="col" class="table_data">
                          {/* <input
                      type="checkbox" className='mr-2'  onChange={e=>HandleAllRead(e.target.checked)} checked={form.permissions.readAdmins&&form.permissions.readBooking&&form.permissions.readCategory&&form.permissions.readCities&&form.permissions.readContent&&form.permissions.readCountries&&form.permissions.readCountries&&form.permissions.readCoupons&&form.permissions.readCurrency&&form.permissions.readCustomer&&form.permissions.readDashboard&&form.permissions.readEmailTemplate&&form.permissions.readPlan&&form.permissions.readPlanFeatures&&form.permissions.readRegion&&form.permissions.readResellerCategory&&form.permissions.readRole&&form.permissions.readTypes&&form.permissions.readContent}/> */}
                          Read
                        </th>
                        <th scope="col" class="table_data">
                          {/* <input
                      type="checkbox" className='mr-2'  onChange={e=>HandleAllAdd(e.target.checked)} checked={form.permissions.addAdmins&&form.permissions.addBooking&&form.permissions.addCategory&&form.permissions.addCities&&form.permissions.addContent&&form.permissions.addCountries&&form.permissions.addCoupons&&form.permissions.addCurrency&&form.permissions.addCustomer&&form.permissions.addEmailTemplate&&form.permissions.addPlan&&form.permissions.addPlanFeatures&&form.permissions.addRegion&&form.permissions.addResellerCategory&&form.permissions.addRole&&form.permissions.addTypes&&form.permissions.addCategory} /> */}
                          Add
                        </th>
                        <th scope="col" class="table_data">
                          {/* <input
                      type="checkbox" className='mr-2'  onChange={e=>HandleallEdit(e.target.checked)} checked={form.permissions.editAdmins&&form.permissions.editBooking&&form.permissions.editCategory&&form.permissions.editCities&&form.permissions.editContinents&&form.permissions.editCountries&&form.permissions.editCoupons&&form.permissions.editCurrency&&form.permissions.editCustomer&&form.permissions.editEmailTemplate&&form.permissions.editPlan&&form.permissions.editPlanFeatures&&form.permissions.editRegion&&form.permissions.editResellerCategory&&form.permissions.editRole&&form.permissions.editTypes} /> */}
                          Edit
                        </th>
                        <th scope="col" class="table_data">
                          {/* <input
                      type="checkbox" className='mr-2'  onChange={e=>HandleAllDelete(e.target.checked)} checked={form.permissions.deleteAdmins&&form.permissions.deleteBooking&&form.permissions.deleteCategory&&form.permissions.deleteCities&&form.permissions.deleteContinents&&form.permissions.deleteCountries&&form.permissions.deleteCoupons&&form.permissions.deleteCurrency&&form.permissions.deleteCustomer&&form.permissions.deletePlan&&form.permissions.deletePlanFeatures&&form.permissions.deleteRegion&&form.permissions.deleteResellerCategory&&form.permissions.deleteRole&&form.permissions.deleteTypes} /> */}
                          Delete
                        </th>
                        {/* <th scope="col" class="table_data"> <input
                      type="checkbox" className='mr-2'  onChange={e=>HandleRefreshAll(e.target.checked)} checked={form.permissions.refreshBooking}/>Refresh</th> */}
                      </tr>
                    </thead>
                    <tbody className="roleTable">
                      <tr>
                        <td>
                          <b>Manage Category</b>
                        </td>
                        <td>
                          <label></label>
                          {permission?.permissions?.category_get ? <i class="fas fa-check-circle icon-boolean"></i> : <i class="fas fa-times-circle icon-boolean"></i>}
                        </td>
                        <td>
                          <label>
                            {permission?.permissions?.category_add ? <i class="fas fa-check-circle icon-boolean"></i> : <i class="fas fa-times-circle icon-boolean"></i>}
                          </label>
                        </td>
                        <td>
                          <label>
                            {permission?.permissions?.category_edit ? <i class="fas fa-check-circle icon-boolean"></i> : <i class="fas fa-times-circle icon-boolean"></i>}
                          </label>
                        </td>
                        <td>
                          <label>
                            {permission?.permissions?.category_delete ? <i class="fas fa-check-circle icon-boolean"></i> : <i class="fas fa-times-circle icon-boolean"></i>}
                          </label>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <b>User Management</b>
                        </td>
                        <td>
                          <label>
                            {permission?.permissions?.user_get ? <i class="fas fa-check-circle icon-boolean"></i> : <i class="fas fa-times-circle icon-boolean"></i>}

                          </label>
                        </td>
                        <td>
                          <label>
                            {permission?.permissions?.user_add ? <i class="fas fa-check-circle icon-boolean"></i> : <i class="fas fa-times-circle icon-boolean"></i>}

                          </label>
                        </td>
                        <td>
                          <label>
                            {permission?.permissions?.user_edit ? <i class="fas fa-check-circle icon-boolean"></i> : <i class="fas fa-times-circle icon-boolean"></i>}

                          </label>
                        </td>
                        <td>
                          <label>
                            {permission?.permissions?.user_delete ? <i class="fas fa-check-circle icon-boolean"></i> : <i class="fas fa-times-circle icon-boolean"></i>}

                          </label>
                        </td>
                        <td></td>
                      </tr>

                      <tr>
                        <td>
                          <b>Content Management</b>
                        </td>
                        <td>
                          <label>
                            {permission?.permissions?.content_get ? <i class="fas fa-check-circle icon-boolean"></i> : <i class="fas fa-times-circle icon-boolean"></i>}
                          </label>
                        </td>
                        <td>
                          {permission?.permissions?.content_edit ? <i class="fas fa-check-circle icon-boolean"></i> : <i class="fas fa-times-circle icon-boolean"></i>}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <b>FAQ Management</b>
                        </td>
                        <td>
                          <label></label>
                          {permission?.permissions?.faq_add ? <i class="fas fa-check-circle icon-boolean"></i> : <i class="fas fa-times-circle icon-boolean"></i>}

                        </td>
                        <td>
                          <label>

                          </label>
                          {permission?.permissions?.faq_edit ? <i class="fas fa-check-circle icon-boolean"></i> : <i class="fas fa-times-circle icon-boolean"></i>}

                        </td>
                        <td>
                          <label>

                          </label>
                          {permission?.permissions?.faq_delete ? <i class="fas fa-check-circle icon-boolean"></i> : <i class="fas fa-times-circle icon-boolean"></i>}
                        </td>
                        <td>
                          {permission?.permissions?.faq_get ? <i class="fas fa-check-circle icon-boolean"></i> : <i class="fas fa-times-circle icon-boolean"></i>}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Question Management</b>
                        </td>
                        <td>
                          {permission?.permissions?.question_get ? <i class="fas fa-check-circle icon-boolean"></i> : <i class="fas fa-times-circle icon-boolean"></i>}
                        </td>
                        <td>
                          {permission?.permissions?.question_add ? <i class="fas fa-check-circle icon-boolean"></i> : <i class="fas fa-times-circle icon-boolean"></i>}
                        </td>
                        <td>
                          {permission?.permissions?.question_delete ? <i class="fas fa-check-circle icon-boolean"></i> : <i class="fas fa-times-circle icon-boolean"></i>}
                        </td>
                        <td>
                          {permission?.permissions?.question_edit ? <i class="fas fa-check-circle icon-boolean"></i> : <i class="fas fa-times-circle icon-boolean"></i>}
                        </td>
                        <td></td>
                      </tr> </tbody>
                  </table>
                </div>
              </div>
            </div> : <></>}

          {/* <div className="col-md-12">
            <label>Created At</label>
            <p className="bg-light rounded px-3 py-2">{data && data.createdAt}</p>
          </div> */}
        </div>
      </div>

    </Layout>
  );
};

export default Profiledetail;


// export default Profiledetail;
