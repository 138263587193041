import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Layout from '../../components/global/layout';
import ApiClient from '../../methods/api/apiClient';
import datepipeModel from '../../models/datepipemodel';
// import Pagination from "react-js-pagination";
import Pagination from "react-pagination-js";
import './style.scss';
import AddEditQuestion from './AddEditQuestion';
import { ToastsStore } from 'react-toasts';
import ViewQuestion from './ViewQuestion';
import loader from '../../methods/loader';
import methodModel from '../../methods/methods';
import Swal from 'sweetalert2';

const Questions = (p) => {
    let user = useSelector(state => state.user)
    const [filters, setFilter] = useState({ page: 1, count: 10, search: '', category: '',sortBy:'order asc' })
    const [data, setData] = useState([])
    const [sort, setsort] = useState({ question: 'question asc', email: 'email asc', 'category asc': "", total_team_amount: 'total_team_amount asc', role: '', createdAt: '', updatedAt: '', status: ''})
    const [total, setTotal] = useState(0)
    const [loading, setLoader] = useState(true)
    const [type, setType] = useState()
    const [form, setform] = useState({isSuicidalQuestion:false,isChildAbuse:false})
    const searchState = useSelector((state) => state.search);
    const [category, setCategories] = useState([])
    const [permissions, setpermissions] = useState({})

    const getdata = () => {
        ApiClient.get('user/detail', { id: user?.id }).then((res) => {
            if (res?.success) {
                console.log(res)
                setpermissions(res?.permissions)
            }
        })
    }
    useEffect(() => {
        getdata()
        console.log(permissions)
    }, [])
    useEffect(() => {
        if (user && user.loggedIn) {
            setFilter({ ...filters, search: searchState.data })
            getData({ search: searchState.data, page: 1 })
            // getTypes()
        }
    }, [searchState])

    const getData = (p = {}) => {
        setLoader(true)
        let filter = { ...filters, ...p }
        ApiClient.get('all/questions/list', filter).then(res => {
            if (res.success) {
                setData(res.data?.data)
                setTotal(res?.data?.total_count)
            }
            setLoader(false)
        })
    }
    const ChangeStatus = (id, status) => {
        if (window.confirm(`Do you want to ${status == 'active' ? 'Deactivate' : 'Activate'}`)) {
            ApiClient.put('change/status', { model: 'questionnaire', id: id, status: status == 'active' ? 'deactive' : 'active' }).then((res) => {
                if (res.success) {
                    getData()
                }
            })
        }
    }
    const sortDataTable1 = (e) => {
        setFilter({ ...filters, sortBy: e })
        if (e == 'question asc' || e == 'question desc') setsort({ ...sort, question: e })
        if (e == 'email asc' || e == 'email desc') setsort({ ...sort, email: e })
        if (e == 'category asc' || e == 'category desc') setsort({ ...sort, category: e })
        if (e == 'createdAt asc' || e == 'createdAt desc') setsort({ ...sort, createdAt: e })
        if (e == 'updatedAt asc' || e == 'updatedAt desc') setsort({ ...sort, updatedAt: e })
        if (e == 'active' || e == 'deactive') setsort({ ...sort, status: e })
        getData({ sortBy: e })
    }
    const sortDataTable2 = (e) => {
        setFilter({ ...filters, sortBy: e })

        if (e == 'active' || e == 'deactive') setsort({ ...sort, status: e })
        getData({ status: e })
    }

    const pageChange = (e) => {
        setFilter({ ...filters, page: e })
        getData({ page: e })
    }

    const statusChange = (e) => {
        setFilter({ ...filters, status: e })
        getData({ status: e })
    }

    const openModal = (itm = '') => {
        let id = new Date().getTime()
        let options = [
            {
                option: '',

            },
            {
                option: '',

            },
            {
                option: '',

            },
            {
                option: '',

            }
        ]


        let ratio = [
            { min: 0, max: 4, level_risk: 'Normal' },
            { min: 5, max: 6, level_risk: 'Mild' },
            { min: 7, max: 10, level_risk: 'Moderate' },
            { min: 11, max: 13, level_risk: "Severe" },
            { min: 14, max: 14, level_risk: "Extremely severe" },
        ]

        setform({ question: '', options: options, category: '' })
        if (itm) {
            setform({
                ...form, ...itm, type: itm.type?.id,
            })
        }
        document.getElementById("openQuestionModal").click()
    }

    const openViewModal = (itm = '') => {
        setform({ ...form, ...itm , questionType:"isSuicidalQuestion"})
        document.getElementById("openViewQuestionModal").click()
    }

    const clear = () => {
        setFilter({ ...filters, search: '', page: 1 })
        getData({ search: '', page: 1 })
    }

    const deleteItem = (id) => {

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                // loader(true)
                ApiClient.delete('question', { id: id }).then(res => {
                    if (res.success) {
                        ToastsStore.success(res.message)
                        clear()
                    }
                    // loader(false)
                })
            }
        })
    }

    const ChangeFilter = (e) => {
        setFilter({ ...filters, category: e, page: 1 })
        getData({ category: e, page: 1 })
    }

    const ChangeType = (e) => {
        setFilter({ ...filters, type: e, page: 1, })
        getData({ type: e, page: 1 })
    }

    const modalClosed = () => {
        setFilter({ ...filters, page: 1 })
        setform({})
        getData()
    }
    const getCategories = () => {
        ApiClient.get('categories', { page: 1, count: 100 }).then(res => {
            if (res.success) {
                const fltr = res?.data.filter((itm) => itm.cat_type == 'Questions')
                setCategories(fltr)
            }
        })
    }
    useEffect(() => {
        getCategories()
    }, [])

    const handleCountChange = (count) => {
        setFilter({ ...filters, count: count, page: 1 });
        getData({ count: count, page: 1 });
      };

    return (
        <Layout>


            <div className='d-flex justify-content-between mb-3 main_title'>
                <h3 className="hedding">
                    Questions
                </h3>

                <div className="mb-3 d-flex justify-content-end">
                    {/* <div className="dropdown addDropdown mr-2">
                        <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Category: {filters.category ? filters.category : "All"}
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a className={filters.category == '' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeFilter('')}>All</a>

                            {
                                category.map((itm) => {
                                    return (


                                        <a className={filters.role == 'sub_ad' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeFilter(itm.name)} >{itm.name}</a>
                                    )
                                })
                            }

                        </div>
                    </div> */}
                
                    {
                        user.role == 'admin' || permissions?.question_add ?
                            <button className="btn btn-primary" onClick={() => openModal()}>Add Question</button> : null
                    }
                    <div className="dropdown addDropdown ml-2">
                        <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuColumns" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {
                                !filters.status ? 'All' : `${filters.status == 'active' ? 'Active' : 'Inactive'}`
                            }
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuColumns">
                            <a className='dropdown-item' onClick={() => statusChange('')}>All</a>
                            <a className='dropdown-item' onClick={() => statusChange('active')}>Active</a>
                            <a className='dropdown-item' onClick={() => statusChange('deactive')}>Inactive</a>
                        </div>
                    </div>
                </div>
            </div>



            <div className="table-responsive">
                <table className="table mb-0">
                    <thead className="theadclss">
                        <tr className="tblclas">

                            <th scope='col'>Question
                                <i class="material-icons  pointer hjik mr-2" onClick={() => sortDataTable1(sort.question == 'question asc' ? 'question desc' : 'question asc')} title="Sort">heighticon</i>
                            </th>
                            <th scope='col'>Role
                                <i class="material-icons  pointer hjik mr-2" onClick={() => sortDataTable1(sort.role == 'role asc' ? 'role desc' : 'role asc')} title="Sort">heighticon</i>
                            </th>
                            {/* <th scope='col'>Date & Time
                                <i class="material-icons  cursor:pointer hjik mr-2" onClick={() => sortDataTable1(sort.createdAt == 'createdAt asc' ? 'createdAt desc' : 'createdAt asc')} title="Sort">heighticon</i>
                            </th> */}
                            <th scope="col">Status </th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!loading && data && data.map((itm, i) => {
                            return <tr key={i}>
                                <td><span className=' tuncketcls' onClick={() => openViewModal(itm)} style={{ cursor: 'pointer' }}>{itm.question}</span></td>
                                <td><span className=' tuncketcls'>{itm.role}</span></td>
                                {/* <td>{datepipeModel.date(itm.createdAt)} | {datepipeModel.time(itm.createdAt)}</td> */}
                                <td>

                                    <div key={itm.key} onClick={() => {
                                        ChangeStatus(itm.id, itm.status)
                                    }} className={`${itm.status == 'active' ? 'activeItm' : 'deactiveItm'} `}>
                                        {itm.status}
                                    </div>
                                </td>
                                <td>
                                    {
                                      user.role == 'admin' ||  permissions?.question_get ?
                                            <>
                                            <a className="linkclass mx-2" onClick={() => openViewModal(itm)} title="View"><i className="fa fa-eye" aria-hidden="true"></i></a> </>: null
                                    }
                                    
                                    {
                                        user.role == 'admin' || permissions?.question_edit ? <>
                                            
                                            <a className="linkclass mx-2" onClick={() => openModal(itm)} title="Edit"><i className="fas fa-pen"></i></a></> : null
                                    }
                                   
                                    
                                            <a className="linkclass mx-2" onClick={() => deleteItem(itm.id)} title="Delete"><i className="fa fa-trash" aria-hidden="true"></i></a>
                                    
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>

                {loading ? <div className="text-center py-4">
                    <img src="/assets/img/loader.gif" className="pageLoader" />
                </div> : <></>}
            </div>

            {!loading && total == 0 ? <div className="py-3 text-center">No Data</div> : <></>}

         
            {
                !loading && total > filters.count ? <div className='d-flex justify-content-between align-items-center flex-wrap'>
                    {/* <Pagination
                        activePage={filters.page}
                        itemsCountPerPage={filters.count}
                        totalItemsCount={total}
                        pageRangeDisplayed={5}
                        onChange={pageChange}
                    /> */}
                    <div className='d-flex align-items-center justify-content-center' >
                <span>Show: </span>
                <select
                    className="form-control ml-2"
                    onChange={(e) => handleCountChange(parseInt(e.target.value))}
                    value={filters.count}
                >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={40}>40</option>
                </select>
            </div>
                    <Pagination
                        currentPage={filters.page}
                        totalSize={total}
                        sizePerPage={filters.count}
                        changeCurrentPage={pageChange}
                    />
                </div> : <></>
            }
            <AddEditQuestion form={form} setform={setform} modalClosed={modalClosed} />
            <ViewQuestion form={form} setform={setform} modalClosed={modalClosed} />
        </Layout>
    );
};







export default Questions;
