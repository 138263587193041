import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Layout from '../../components/global/layout';
import ApiClient from '../../methods/api/apiClient';
import datepipeModel from '../../models/datepipemodel';
import Pagination from "react-pagination-js";
import './style.scss';
import AddEdit from './AddEdit';
import { useHistory } from 'react-router';
import TranslationTabs from '../Translation/TranslationTabs';
import ViewModal from './View'
import modalModel from '../../models/modal.model';
import methodModel from '../../methods/methods';

const Feedbacks = (p) => {
    let user = useSelector(state => state.user)
    const [filters, setFilter] = useState({ page: 1, count: 5, search: '' })
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [loading, setLoader] = useState(true)
    const [form, setform] = useState({})
    const searchState = useSelector((state) => state.search);

    useEffect(() => {
        if (user && user.loggedIn) {
            setFilter({ ...filters, search: searchState.data })
            getData({ search: searchState.data, page: 1 })
        }
    }, [searchState])

    const getData = (p = {}) => {
        setLoader(true)
        let filter = { ...filters, ...p }
        ApiClient.get('contact/us', filter).then(res => {
            if (res.success) {
                setData(res.data)
                setTotal(res.total)
            }
            setLoader(false)
        })
    }

    const pageChange = (e) => {
        setFilter({ ...filters, page: e })
        getData({ page: e })
    }


    const modalClosed = () => {
        setFilter({ ...filters, page: 1 })
        getData()
    }

    const view = (itm) => {
        setform(itm)
        modalModel.open('viewModal')
    }

    const handleCountChange = (count) => {
        setFilter({ ...filters, count: count, page: 1 });
        getData({ count: count, page: 1 });
      };

    return (
        <Layout>
            <TranslationTabs page="categorytype" />
            <div className="Assessment mb-3 d-flex justify-content-between">
                <h3 className="hedding">
                    Feedbacks from Users
                </h3>
            </div>

            <div className="table-responsive">
                <table className="table mb-0">
                    <thead className="theadclss">
                        <tr className="tblclas">
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Mobile No</th>
                            <th scope="col">Reason</th>
                            <th scope="col">Date & time</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {!loading && data && data.map((itm, i) => {
                            return <tr key={i}>
                                <td>{methodModel.capitalizeName(itm.name)}</td>
                                <td>{itm.email}</td>
                                <td>{itm.mobileNo}</td>
                                <td>{itm.reason}</td>
                                <td>{datepipeModel.date(itm.createdAt)} | {datepipeModel.time(itm.createdAt)}</td>
                                <td>
                                    <a className="linkclass mx-2" onClick={() => view(itm)}><i title="View" className="fas fa-eye"></i></a>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>

                {loading ? <div className="text-center py-4">
                    <img src="/assets/img/loader.gif" className="pageLoader" />
                </div> : <></>}
            </div>

            {!loading && total == 0 ? <div className="py-3 text-center">No Data</div> : <></>}

           
            {
                !loading && total > filters.count ? <div className='d-flex justify-content-between align-items-center flex-wrap'>
                    {/* <Pagination
                        activePage={filters.page}
                        itemsCountPerPage={filters.count}
                        totalItemsCount={total}
                        pageRangeDisplayed={5}
                        onChange={pageChange}
                    /> */}
                    <div className='d-flex align-items-center justify-content-center' >
                <span>Show: </span>
                <select
                    className="form-control ml-2"
                    onChange={(e) => handleCountChange(parseInt(e.target.value))}
                    value={filters.count}
                >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={40}>40</option>
                </select>
            </div>
                    <Pagination
                        currentPage={filters.page}
                        totalSize={total}
                        sizePerPage={filters.count}
                        changeCurrentPage={pageChange}
                    />
                </div> : <></>
            }
            <AddEdit form={form} setform={setform} modalClosed={modalClosed} />
            <ViewModal form={form} />
        </Layout>
    );
};
export default Feedbacks;
