import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Layout from '../../components/global/layout';
import ApiClient from '../../methods/api/apiClient';
// import Pagination from "react-js-pagination";
import Pagination from "react-pagination-js";
import './style.scss';
import AddEditFaq from './AddEditFaq';
import { ToastsStore } from 'react-toasts';
import loader from '../../methods/loader';
import ViewFaq from './ViewFaq';
import TranslationTabs from '../Translation/TranslationTabs';
import methodModel from '../../methods/methods';
import Swal from 'sweetalert2';

const Faq = (p) => {
    let user = useSelector(state => state.user)
    const [filters, setFilter] = useState({ page: 1, count: 100, search: '', cat_type: '' })
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [loading, setLoader] = useState(true)
    const [sort, setsort] = useState({ question: 'question asc', answer: '', updatedAt: '', status: '', category: '', price: '' })
    const [form, setform] = useState({ question: '', answer: '' })
    const searchState = useSelector((state) => state.search);
    const [permissions, setpermissions] = useState({})
    const [category, setCategories] = useState([])
    const getdata = () => {
        ApiClient.get('user/detail', { id: user?.id }).then((res) => {
            if (res?.success) {
                console.log(res)
                setpermissions(res?.permissions)
            }
        })
    }
    useEffect(() => {
        getdata()
        console.log(permissions)
    }, [])


    const dragItem = useRef();
    const dragOverItem = useRef();

    const dragStart = (e, position) => {
        dragItem.current = position;
    };

    const dragEnter = (e, position) => {
        console.log("e", e)
        console.log("position", position)
        dragOverItem.current = position;
    };

    const drop = () => {
        const copyListItems = [...data];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        setData(copyListItems);

        ApiClient.put('faq/order', {
            faqs: copyListItems.map((itm, i) => {
                return { ...itm, order: i + 1 }
            })
        }).then(res => {
            ToastsStore.success(res.message)
        })
    };


    useEffect(() => {
        if (user && user.loggedIn) {
            setFilter({ ...filters, search: searchState.data })
            getData({ search: searchState.data, page: 1 })

        }
    }, [searchState])

    const sortDataTable1 = (e) => {
        setFilter({ ...filters, sortBy: e })
        if (e == 'question asc' || e == 'question desc') setsort({ ...sort, question: e })
        if (e == 'answer asc' || e == 'answer desc') setsort({ ...sort, answer: e })
        if (e == 'createdAt asc' || e == 'createdAt desc') setsort({ ...sort, createdAt: e })
        if (e == 'updatedAt asc' || e == 'updatedAt desc') setsort({ ...sort, updatedAt: e })
        if (e == 'active' || e == 'deactive') setsort({ ...sort, status: e })
        if (e == 'price asc' || e == 'price desc') setsort({ ...sort, price: e })
        getData({ sortBy: e })
    }

    const sortDataTable2 = (e) => {
        setFilter({ ...filters, sortBy: e })

        if (e == 'active' || e == 'deactive') setsort({ ...sort, status: e })
        getData({ status: e })
    }
    const getData = (p = {}) => {
        setLoader(true)
        let filter = { ...filters, ...p }
        ApiClient.get('all/faq', filter).then(res => {
            if (res.success) {
                setData(res?.data?.data)
                setTotal(res?.data?.data?.length)
                setLoader(false)

            }
        })
    }

    const handleCountChange = (count) => {
        setFilter({ ...filters, count: count, page: 1 });
        getData({ count: count, page: 1 });
      };


    const pageChange = (e) => {
        setFilter({ ...filters, page: e })
        getData({ page: e })
    }

    const statusChange = (e) => {
        setFilter({ ...filters, status: e })
        getData({ status: e })
    }

    const openModal = (itm = '') => {
        let id = new Date().getTime()

        setform({ question: '', answer: '' })
        if (itm) {
            setform({
                ...form, ...itm, question: itm.question, answer: itm.answer, category: itm.category
            })
        }
        document.getElementById('openFaqModal').click()
    }
    console.log(form)
    const openViewModal = (itm = '') => {
        setform({ ...form, ...itm })
        document.getElementById('openViewFaqModal').click()
    }

    const clear = () => {
        setFilter({ ...filters, search: '', page: 1 })
        getData({ search: '', page: 1 })
    }


    const deleteItem = (id) => {

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                // loader(true)
                ApiClient.delete('faq', { id: id }).then(res => {
                    if (res.success) {
                        ToastsStore.success(res.message)
                        clear()
                    }
                    // loader(false)
                })
            }
        })
    }

    const changeStatus = (id, status) => {
        if (window.confirm(`Do you want to ${status == 'active' ? 'Deactivate' : 'Activate'}`)) {


            ApiClient.put('change/status', { model: 'faq', id: id, status: status == 'active' ? 'deactive' : 'active' }).then((res) => {
                if (res.success) {
                    ToastsStore.success(res.message)
                    getData()
                }
            })
        }
    }


    const modalClosed = () => {
        setFilter({ ...filters, page: 1 })
        getData()
    }
    const getCategories = () => {
        ApiClient.get('categories', { page: 1, count: 100 }).then(res => {
            if (res.success) {
                const fltr = res?.data.filter((itm) => itm.cat_type == 'FAQ')
                setCategories(fltr)
            }
        })
    }
    useEffect(() => {
        getCategories()
    }, [])
    const ChangeFilter = (e) => {
        setFilter({ ...filters, category: e, page: 1 })
        getData({ category: e, page: 1 })
    }
    return (
        <Layout>
            <TranslationTabs page="faq" />

            <div className='d-flex justify-content-between mb-3 main_title'>
                <h3 className="hedding">
                    FAQ
                </h3>
                <div className="mb-3 d-flex justify-content-end">

                    {
                        user.role == 'admin' || permissions?.faq_add ?
                            <button className="btn btn-primary" onClick={() => openModal()}>Add FAQ</button> : null
                    }
                    <div className="dropdown addDropdown ml-2">
                        <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Category: {filters.category ? filters.category : "All"}
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a className={filters.category == '' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeFilter('')}>All</a>

                            {
                                category.map((itm) => {
                                    return (


                                        <a className={filters.role == 'sub_ad' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeFilter(itm.name)} >{itm.name}</a>
                                    )
                                })
                            }

                        </div>
                    </div>
                    <div className="dropdown addDropdown ml-2">
                        <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuColumns" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {
                                !filters.status ? 'All' : `${filters.status == 'active' ? 'Active' : 'Inactive'}`
                            }
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuColumns">
                            <a className='dropdown-item' onClick={() => statusChange('')}>All</a>
                            <a className='dropdown-item' onClick={() => statusChange('active')}>Active</a>
                            <a className='dropdown-item' onClick={() => statusChange('deactive')}>Inactive</a>
                        </div>
                    </div>
                </div>
            </div>


            <div className="table-responsive">
                <table className="table mb-0">
                    <thead className="theadclss">
                        <tr className="tblclas">

                            <th scope='col'>Question
                                <i class="material-icons  pointer hjik mr-2" onClick={() => sortDataTable1(sort.question == 'question asc' ? 'question desc' : 'question asc')} title="Sort">heighticon</i>
                            </th>
                            {/* <th scope='col'>Answer
                                <i class="material-icons  cursor:pointer hjik mr-2" onClick={() => sortDataTable1(sort.answer == 'answer asc' ? 'answer desc' : 'answer asc')} title="Sort">heighticon</i>
                            </th> */}
                            <th scope='col'>Category
                                <i class="material-icons  pointer hjik mr-2" onClick={() => sortDataTable1(sort.category == 'category asc' ? 'category desc' : 'answer asc')} title="Sort">heighticon</i>
                            </th>
                            <th scope='col'>Status
                               
                            </th>
                            <th scope='col' className="nowrap">Action</th>
                        </tr>
                    </thead>
                    <tbody>

                        {data && data.map((itm, i) => {
                            return <tr key={i}>

                                <td key={itm.key} className={`nowrap`} onClick={() => openViewModal(itm)} style={{ cursor: 'pointer' }}>
                                    {itm.question.substr(0, 20) + '...'}
                                </td>
                                {/* <td key={itm.key} className={`nowrap`}>
                                    {itm?.answer.substr(0, 20)?.replaceAll('<p>', " ")?.replaceAll('</p>', " ") || "--"}
                                </td> */}
                                <td key={itm.key} className={`nowrap`}>
                                    {itm?.category || "--"}
                                </td>
                                <td > <div key={itm.key} onClick={() => {
                                    changeStatus(itm.id, itm.status)
                                }} className={`${itm.status == 'active' ? 'activeItm' : 'deactiveItm'} `}>
                                    {itm.status}
                                </div></td>


                                <td className="nowrap">
                                    {
                                        user?.role == "admin" || permissions?.faq_get ?
                                            <>
                                                <a className="linkclass mx-2" title="View" onClick={() => openViewModal(itm)}>
                                                    <i className="fa fa-eye"></i></a> </> : null
                                    }
                                   
                                    {
                                        user.role == 'admin' || permissions?.faq_edit ? <>

                                            <a className="linkclass mx-2" title="Edit" onClick={() => openModal(itm)}><i className="fa fa-pen"></i></a></> : null
                                    }
                                    
                                    {
                                        user.role == 'admin' || permissions?.faq_delete ? <>
                                            <a className="linkclass mx-2" title="Delete" onClick={() => deleteItem(itm.id)}><i className="fa fa-trash"></i></a></>
                                            : null
                                    }
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>

                {loading ? <div className="text-center py-4">
                    <img src="/assets/img/loader.gif" className="pageLoader" />
                </div> : <></>}
            </div>

            {!loading && total == 0 ? <div className="py-3 text-center">No Data</div> : <></>}

          
            {
                !loading && total > filters.count ? <div className='d-flex justify-content-between align-items-center flex-wrap'>
                    {/* <Pagination
                        activePage={filters.page}
                        itemsCountPerPage={filters.count}
                        totalItemsCount={total}
                        pageRangeDisplayed={5}
                        onChange={pageChange}
                    /> */}
                    <div className='d-flex align-items-center justify-content-center' >
                <span>Show: </span>
                <select
                    className="form-control ml-2"
                    onChange={(e) => handleCountChange(parseInt(e.target.value))}
                    value={filters.count}
                >
                    <option value={10}>100</option>
                    <option value={20}>200</option>
                    <option value={30}>300</option>
                    <option value={40}>400</option>
                </select>
            </div>
                    <Pagination
                        currentPage={filters.page}
                        totalSize={total}
                        sizePerPage={filters.count}
                        changeCurrentPage={pageChange}
                    />
                </div> : <></>
            }
            <AddEditFaq form={form} setform={setform} modalClosed={modalClosed} />
            <ViewFaq form={form} setform={setform} modalClosed={modalClosed} />
        </Layout>
    );
};


export default Faq;
