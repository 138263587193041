import React, { useEffect, useState } from 'react'
import Layout from '../../components/global/layout'
import AddEditFeatures from './AddEdit'
import { Link, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import ApiClient from '../../methods/api/apiClient'
import { useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import Pagination from "react-pagination-js";
import methodModel from '../../methods/methods'
import loader from '../../methods/loader'
import { ToastsStore } from 'react-toasts'

function Plans() {
    const [loaging, setLoader] = useState(false)
    const history = useHistory()
    const user = useSelector((state) => state.user)
    const searchState = useSelector((state) => state.search)
    const [form, setform] = useState({ role: 'user', firstName: '', addAvailability: false, lastName: '', email: '', ic_number: '', password: '', mobileNo: '', dialCode: '+60', nationality: '' })
    const [formData, setFormData] = useState({ email: '', ic_number: '' })
    const [sort, setsort] = useState({ name: 'name asc', createdAt: '', updatedAt: '', status: '', category: '', price: '' })
    const [submitted, setSubmitted] = useState(false)
    const [inviteForm, setInviteForm] = useState({ email: '' })
    const [emailErr, setEmailErr] = useState('')
    const { id } = useParams()
    const [icErr, setIcErr] = useState('')
    const [data, setData] = useState([])
    const [tableCols, setTableCols] = useState([])
    const [total, setTotal] = useState(0)
    const [filters, setFilter] = useState({ page: 1, count: 10, search: '', sortBy: '', status: '', category: '' })
    const getData = (p = {}) => {
        setLoader(true)
        let filter = { ...filters, ...p }
        ApiClient.get('plans', filter,).then(res => {
            if (res.success) {
                setData(res.data)
                setTotal(res.total)
            }
            setLoader(false)
        })
    }

    const handleCountChange = (count) => {
        setFilter({ ...filters, count: count, page: 1 });
        getData({ count: count, page: 1 });
    };

    const pageChange = (e) => {
        setFilter({ ...filters, page: e })
        getData({ page: e })
    }

    useEffect(() => {
        if (user && user.loggedIn) {
            setFilter({ ...filters, search: searchState.data })
            getData({ search: searchState.data, page: 1 })
        }
    }, [searchState])

    const ChangeStatus = (id, status) => {
        if (window.confirm(`Do you want to ${status == 'active' ? 'Deactivate' : 'Activate'}`)) {
            ApiClient.put('change/status', { model: 'plans', id: id, status: status == 'active' ? 'deactive' : 'active' }).then((res) => {
                if (res.success) {
                    getData()
                }
            })
        }
    }


    const sortDataTable1 = (e) => {
        setFilter({ ...filters, sortBy: e })
        if (e == 'name asc' || e == 'name desc') setsort({ ...sort, name: e })
        if (e == 'email asc' || e == 'email desc') setsort({ ...sort, email: e })
        if (e == 'createdAt asc' || e == 'createdAt desc') setsort({ ...sort, createdAt: e })
        if (e == 'updatedAt asc' || e == 'updatedAt desc') setsort({ ...sort, updatedAt: e })
        if (e == 'active' || e == 'deactive') setsort({ ...sort, status: e })
        if (e == 'price asc' || e == 'price desc') setsort({ ...sort, price: e })
        getData({ sortBy: e })
    }
    const sortDataTable2 = (e) => {
        setFilter({ ...filters, sortBy: e })

        if (e == 'active' || e == 'deactive') setsort({ ...sort, status: e })
        getData({ status: e })
    }
    useEffect(() => {
        getData()
    }, [])

    const deleteItem = (id) => {

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                loader(true)
                ApiClient.delete('plan', { id: id }).then(res => {
                    if (res.success) {
                        ToastsStore.success(res.message)
                        getData()
                    }
                    loader(false)
                })
            }
        })
    }

    const modalClosed = () => {
        setFilter({ ...filters, page: 1 })
        getData({ page: 1 })
    }

    const openModal = (itm = {}) => {
        setSubmitted(false)
        setEmailErr('')
        setIcErr('')
        setform({ role: 'user', firstName: '', lastName: '', email: '', ic_number: '', password: '', mobileNo: '', dialCode: '+60', nationality: '', ...itm })
        setFormData({ ...formData, ...itm })
        if (itm.id) {
            setform({ ...form, ...itm, password: '********' })
        }
        document.getElementById("openuserModal").click()
    }
    return (
        <>
            <Layout>
                <div className="d-flex justify-content-between mb-3 main_title">
                    <h3 className="hedding">
                        Plan
                    </h3>

                    <article className="d-flex">

                        <button className="btn btn-primary mr-2" onClick={() => history.push('/subscription/addplan')}>
                            Add Plan
                        </button>


                        <div className="dropdown addDropdown mr-2">
                            <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuColumns" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {
                                    !sort.status ? 'All' : `${sort.status == 'active' ? 'Active' : 'Inactive'}`
                                }
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuColumns">
                                <a className='dropdown-item' onClick={() => sortDataTable2(sort.status = '')}>All</a>
                                <a className='dropdown-item' onClick={() => sortDataTable2(sort.status = 'active')}>Active</a>
                                <a className='dropdown-item' onClick={() => sortDataTable2(sort.status = 'deactive')}>Inactive</a>
                            </div>
                        </div>


                    </article>


                </div>
                <div className="table-responsive">
                    <table className="table mb-0">
                        <thead className="theadclss">
                            <tr className="tblclas">

                                <th scope='col'>Name
                                    <i class="material-icons  cursor:pointer hjik mr-2" onClick={() => sortDataTable1(sort.name == 'name asc' ? 'name desc' : 'name asc')} title="Sort">heighticon</i>
                                </th>
                                <th scope='col'>Price
                                    <i class="material-icons  cursor:pointer hjik mr-2" onClick={() => sortDataTable1(sort.price == 'price asc' ? 'price desc' : 'price asc')} title="Sort">heighticon</i>
                                </th>

                                <th scope='col'>Status

                                </th>
                                <th scope='col' className="nowrap">Action</th>
                            </tr>
                        </thead>
                        <tbody>

                            {!loaging && data && data.map((itm, i) => {
                                return <tr key={i}>

                                    <td onClick={() => history.push('/subscription/viewplan/' + itm.id)} key={itm.key} className={`nowrap cursor-pointer`}>
                                        {methodModel.capitalizeName(itm.name)}
                                    </td>
                                    <td key={itm.key} className={`nowrap`}>
                                        {itm?.price || "--"}
                                    </td>

                                    <td>
                                        <div key={itm.key} onClick={() => {
                                            ChangeStatus(itm.id, itm.status)
                                        }} className={`${itm.status == 'active' ? 'activeItm' : 'deactiveItm'}`}>
                                            {itm.status}
                                        </div>
                                    </td>

                                    <td className="nowrap">
                                        <Link className="linkclass mx-2" title="View" to={'/subscription/viewplan/' + itm.id}>
                                            <i className="fa fa-eye"></i></Link> |
                                        <a className="linkclass mx-2" title="Edit" onClick={() => history.push('/subscription/editplan/' + itm.id)}><i className="fa fa-pen"></i></a>
                                        <a className="linkclass mx-2" title="Delete" onClick={() => deleteItem(itm.id)}><i className="fa fa-trash"></i></a>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>

                    {loaging ? <div className="text-center py-4">
                        <img src="/assets/img/loader.gif" className="pageLoader" />
                    </div> : <></>}
                </div>
                {
                    !loaging && total > filters.count ? <div className='d-flex justify-content-between align-items-center flex-wrap'>
                        <div className='d-flex align-items-center justify-content-center' >
                            <span>Show: </span>
                            <select
                                className="form-control ml-2"
                                onChange={(e) => handleCountChange(parseInt(e.target.value))}
                                value={filters.count}
                            >
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={30}>30</option>
                                <option value={40}>40</option>
                            </select>
                        </div>
                        <Pagination
                            currentPage={filters.page}
                            totalSize={total}
                            sizePerPage={filters.count}
                            changeCurrentPage={pageChange}
                        />
                    </div> : <></>
                }
                {!loaging && total == 0 ? <div className="py-3 text-center">No Data</div> : <></>}
                <AddEditFeatures formData={formData} form={form} submitted={submitted} emailErr={emailErr} setIcErr={setIcErr} icErr={icErr} setEmailErr={setEmailErr} setSubmitted={setSubmitted} setform={setform} modalClosed={modalClosed} />
            </Layout>
        </>
    )
}

export default Plans