import React, { useEffect } from 'react';
import './style.scss';
import { useHistory } from 'react-router-dom';
import Sidebar from '../sidebar';
import Header from '../header';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import ApiClient from '../../../methods/api/apiClient';
import ApiKey from '../../../methods/ApiKey';
import { ToastsStore } from 'react-toasts';
import Img from '../sidebar/logo-blue.png'
import Logo from './logo-main.png'

const Layout = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  let user = useSelector(state => state.user)
  const searchState = useSelector((state) => state.search);
  const history = useHistory();
  const [form, setForm] = useState({ baseImg: '', logo: '' })
  const [loading, setLoader] = useState(false)

  useEffect(() => {
    if (user && !user.loggedIn) {
      history.push('/login');
    } else {
      // getData()
    }
  }, [searchState])

  const logo = () => {
    let value = '/assets/img/mindalogo.png'
    let logo = localStorage.getItem('logo')
    if (form.logo) logo = form.logo

    if (logo) value = ApiKey.api + 'images/assets/' + logo
    return value
  }

  const uploadImage = (e) => {
    setForm({ ...form, baseImg: e.target.value })
    let files = e.target.files
    let file = files.item(0)
    setLoader(true)
    ApiClient.postFormData('image/upload?modelName=assets', { file: file, modelName: 'assets' }).then(res => {
      if (res.success) {
        let image = res.data.fullpath
        setForm({ ...form, logo: image, baseImg: '' })

        updateLogo({ logo: image })
      } else {
        setForm({ ...form, baseImg: '' })
      }
      setLoader(false)
    })
  }

  const updateLogo = (p = {}) => {
    ApiClient.post('logo', { ...p, id: 1 }).then(res => {
      if (res.success) {
        // ToastsStore.success(res.message)
      }
    })
  }

  const getData = () => {
    ApiClient.get('logo').then(res => {
      if (res.success) {
        setForm({ ...form, logo: res.data.logo })
        localStorage.setItem('logo', res.data.logo)
      }
    })
  }

  return (
    <>
      <Header isOpen={isOpen} setIsOpen={setIsOpen} />
      <div className="main-wrapper d-flex">
        <div className="main-sidebar d-none d-md-block">
          <div className="sidebar-brand text-center">
            <label className='editLogo'>
              <img style={{ borderRadius: '' }} src={!isOpen ? Img : Logo} className="logocls" />
              {/* <input
                id="bannerImage"
                type="file"
                className="d-none"
                accept="image/*"
                disabled={loading}
                value={form.baseImg ? form.baseImg : ''}
                onChange={(e) => { uploadImage(e); }}
              />
              <i className='fa fa-pen'></i> */}
            </label>

            {loading ? <div className='text-success'>Loading...</div> : <></>}

          </div>
          <Sidebar />
        </div>
        <main className="main">
          <div className="mainarea">{children}</div>
        </main>
      </div>
    </>
  );
};
export default Layout;
