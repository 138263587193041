import React, { useEffect, useState } from 'react'
import Layout from '../../components/global/layout'
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import ApiClient from '../../methods/api/apiClient'
import dateFormat from 'dateformat'
import { toast } from 'react-toastify'
import { ToastsStore } from 'react-toasts'

function ViewReview() {
  const { id } = useParams()
  const [data, setdata] = useState([])
  const [loader, setloader] = useState(true)
  const[time,setTime] = useState('15-12-2023,11')

  const Getdata = () => {
    setloader(true)
    ApiClient.get('rating', { id: id }).then((res) => {
      if (res?.success) {
        setdata(res?.data)
        let AppointDate = dateFormat(res?.data?.date, "dd-mm-yyyy")
        let AppointHr = parseInt(res?.data?.time?.split("-")[0])
        let finalTime = AppointDate + "," + AppointHr
        // console.log(finalTime)
        // setTime(finalTime)
        console.log(time)
        // let date = dateFormat(res?.data?.date,"dd-mm-yyyy")
        // let ExractTime = parseInt(res?.data?.time?.split("-"))
        // let JoinDate = date+","+ExractTime
        // setTime(dateFormat(JoinDate,"dd-mm-yyyy,HH"))
        // console.log(time)
        
      }
    })
    setTimeout(() => {


      setloader(false)
    }, 500);
  }

  useEffect(() => {
    Getdata()
  }, [])

let CurrentTime = dateFormat(Date.now(),"dd-mm-yyyy,HH")
  const ChangeStatus = (id, status) => {
    if (window.confirm(`Do you want to ${status == 'accept' ? 'accept' : 'reject'}`)) {
      ApiClient.put('appointment', { id: id, status: status }).then((res) => {
        if (res.success) {
          Getdata()
          ToastsStore.success(res.message)
        }
      })
    }
  }
  const ChangeAppointStatus = (id, status) => {
    if (window.confirm(`Are you sure that this Appointment is ${status == 'done' ? 'Done' : 'Missed'}`)) {
      ApiClient.put('appointment', { id: id, appointment_status: status }).then((res) => {
        if (res.success) {
          Getdata()
          ToastsStore.success(res.message)
        }
      })
    }
  }
  return (
    <Layout>
      <div className='text-right mb-3'><Link to="/appointment-reviews"><i className="fa fa-arrow-left" title='Back' aria-hidden="true"></i></Link></div>
      <div className=" pprofile1">
        <div className='d-flex justify-content-between bottomborder align-items-center'>
          <h3 className='heading_border'>View  Review</h3>
       
        </div>

        {
          loader ? <div className="text-center py-4">
            <img src="/assets/img/loader.gif" className="pageLoader" />
          </div> :

            <div className='table_admin'>
              <div className='row mb-3'>
                <div className='col-12 col-sm-12 col-md-3'>
                  <h5 className='headmain'>Rating</h5>
                </div>
                <div className='col-12 col-sm-12 col-md-9'>
                  <h6 className='subhead'>{data?.rating}</h6>
                </div>
              </div>

              <div className='row mb-3'>
                <div className='col-12 col-sm-12 col-md-3'>
                  <h5 className='headmain'>Review</h5>
                </div>
                <div className='col-12 col-sm-12 col-md-9'>
                  <h6 className='subhead'>{data?.review}</h6>
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-12 col-sm-12 col-md-3'>
                  <h5 className='headmain'>Date</h5>
                </div>
                <div className='col-12 col-sm-12 col-md-9'>
                  <h6 className='subhead'>{dateFormat(data?.createdAt,"h:MM TT dd-mmm-yyyy  ")}</h6>
                </div>
              </div>
              <div className='d-flex justify-content-between bottomborder align-items-center'>
                <h3 className='heading_border'>Patient Detail</h3>
              </div>
              <div className='row mb-3'>
                <div className='col-12 col-sm-12 col-md-3'>
                  <h5 className='headmain'>User Name</h5>
                </div>
                <div className='col-12 col-sm-12 col-md-9'>
                  <h6 className='subhead'>{data?.user_details?.fullName}</h6>
                </div>
              </div>

              <div className='row mb-3'>
                <div className='col-12 col-sm-12 col-md-3'>
                  <h5 className='headmain'>E-mail</h5>
                </div>
                <div className='col-12 col-sm-12 col-md-9'>
                  <h6 className='subhead'>{data?.user_details?.email}</h6>
                </div>
              </div>
            
            </div>}



      </div>





    </Layout>
  )
}

export default ViewReview
