
import React, { useState, useEffect, useRef } from "react";
import { ToastsStore } from "react-toasts";

import Layout from '../../components/global/layout'
import { useSelector } from 'react-redux';


import { Link, useHistory, useParams } from "react-router-dom";



import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import methodModel from "../../methods/methods";
import stateModel from "../../models/states.model";
import { planType } from "../../models/type.model";
import ApiKey from "../../methods/ApiKey";

const AddEditPlan = () => {
  const [features, setFeatures] = useState([])
  const [planfeature, setPlanFeature] = useState([])
  const deformfeatutresjson = planfeature.map((itm) => {
    return { name: itm.name, isChecked: false }
  })
  const [checkboxes, setCheckboxes] = useState(deformfeatutresjson);

  useEffect(() => {
    setCheckboxes(deformfeatutresjson)
  }, [planfeature])

  const defaultvalue = () => {
    let keys = { ...planType }
    Object.keys(planType).map(itm => {
      keys[itm] = ''
    })
    keys.status = 'active'
    return keys
  }
  const { id, copy } = useParams()
  const [form, setform] = useState(planType);
  const [checkedItems, setCheckedItems] = useState([]);
  const [startIndex, setStartIndex] = useState(-1);
  const [enterIndex, setEnterIndex] = useState(-1);
  const [selectedItem, setSelectedItem] = useState('');
  const [pricing, setPricing] = useState([]);
  const history = useHistory()
  const [submitted, setSubmitted] = useState(false)
  const user = useSelector((state) => state.user);
  const dragItem = useRef();
  const dragItems = useRef();
  const dragOverItem = useRef();
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([])
  let fetrs = [];
  useEffect(() => {
    setform({ ...form, feature: fetrs })

  }, [])

  const uploadImage = (e) => {
    setform({ ...form, baseImg: e.target.value })
    let files = e.target.files
    let file = files.item(0)
    setLoader(true)
    ApiClient.postFormData('upload/image?modelName=users', { file: file, modelName: 'users' }).then(res => {
      if (res.success) {
        let image = res.data?.fullpath
        setform({ ...form, image: image, baseImg: '' })
      } else {
        setform({ ...form, baseImg: '' })
      }
      setLoader(false)
    })
  }

  console.log(deformfeatutresjson, "pppppppppp")



  const formValidation = [
    // { key: 'feature', required: true },
    { key: 'status', required: true },
    { key: 'recommended', required: true },
  ]
  const getData = (p = {}) => {
    setLoader(true)

    ApiClient.get('features').then(res => {
      if (res.success) {
        console.log(res.data, "resssss")
        const fltr = res?.data?.filter((itm) => itm?.status == 'active')
        setPlanFeature(fltr)
        setData(fltr)
        console.log(features)
      }
      setLoader(false)
    })
    if (id) {
      ApiClient.get('plan', { id: id }).then((res) => {
        if (res.success) {
          setform(res?.data)
          fetrs = res?.data?.features
          console.log(fetrs, "fetrsssssssss")
          setCheckboxes(res?.data?.features)
          setFeatures(res?.data?.features)
          setform({ ...form, feature: res?.data?.features, name: res?.data?.name, price: res?.data?.price, id: res?.data?.id, image: res?.data?.image, description: res?.data?.description })
        }
      })
    }

  }

  useEffect(() => {
    getData()
  }, [])
  console.log(form?.feature?.includes((item) => item.name == "SMS"))

  const handleSubmit = (e) => {
    e.preventDefault()
    setSubmitted(true)


    let method = 'post'
    let url = 'plan'
    let value = {
      name: form.name, price: form.price, id: form.id, features: checkboxes, image: form?.image, description: form?.description
    }

    if (value.id) {
      method = 'put'
      url = 'plan'
      delete value.password
    } else {
      delete value.id
    }

    // loader(true)
    ApiClient.allApi(url, value, method).then(res => {
      if (res.success) {
        ToastsStore.success(res.message)
        // document.getElementById('closeuserModal').click()
        // modalClosed()
        history.goBack()
      }

      // loader(false)
    })
  }

  const handleCheckboxChange = (index) => {
    const updatedCheckboxes = checkboxes.map((checkbox, i) => {
      if (i === index) {
        return { ...checkbox, isChecked: !checkbox.isChecked };
      }
      return checkbox;
    });

    setCheckboxes(updatedCheckboxes);
  };



  useEffect(() => {

    if (!id) {

    }

  }, [id])

  return <>
    <Layout>
      <form onSubmit={handleSubmit}>
        <div className="pprofile1">
          <h3 className="ViewUser mb-3">{form && form.id ? 'Edit' : 'Add'} Plan</h3>
          <div className="form-row">
            <div className="col-md-6 mb-3">
              <label>Name<span className="star">*</span></label>
              <input
                type="text"
                className="form-control"
                value={form.name}
                onChange={e => {
                  setform({ ...form, name: e.target.value })
                  console.log(form)
                }}
                required
              />
            </div>

            <div className="col-md-6 mb-3">
              <label>Price<span className="star">*</span></label>
              <input
                type="number"
                className="form-control"
                value={form.price}
                onChange={e => {
                  console.log(features)
                  setform({ ...form, price: e.target.value })
                  console.log(form)
                }}
                required
              />
            </div>

            <div className="col-md-12 mb-3">
              <label>Description<span className="star">*</span></label>
              <textarea
                type="text"
                className="form-control"
                value={form?.description}
                onChange={e => {
                  setform({ ...form, description: e.target.value })
                }}
                required
              />
            </div>
            <div className='profile_btn'>
              {
                form?.image &&
                <img style={{ width: '109%', height: '144px' }} src={`${ApiKey?.api}images/users/` + form?.image} alt="" />
              }
              <div>
                <label className="btn btn-primary edit ml-3">
                  <input
                    id="bannerImage"
                    type="file"
                    className="d-none"
                    accept="image/*"
                    value={form.baseImg ? form.baseImg : ''}
                    onChange={(e) => { uploadImage(e); }}
                  />{form.image ? 'Change' : 'Upload'} Image</label>
              </div>
              <div>
                {form.image ? <label className="btn btn-primary  delete ml-3" onClick={e => setform({ ...form, image: "" })}>Remove Image</label> : <></>}
              </div>
            </div>
            <div className="col-md-12 mb-3">
              <hr className="borderHr" />
              <h5 className="monthTerm">Features<span className="star">*</span></h5>
              {
                checkboxes?.map((itm, index) => {
                  return (


                    <div className="row">
                      <label className="ml-3" >{itm?.name}</label>
                      <label>
                        <input className="ml-3"
                          type="checkbox"
                          checked={itm?.isChecked}
                          onChange={() => handleCheckboxChange(index)}
                        // checked={itm?.isChecked}
                        // onChange={(e) => {
                        //   if (e.target.checked) {
                        //     fetrs.push(itm.name)
                        //     console.log(features)
                        //  setFeatures([...features,{name:itm.name,isChecked:true}])
                        //  setform({...form,feature:features})


                        //   } else {
                        //     const fltr = features.filter((item) => item.name != itm.name)
                        //     setFeatures(fltr)
                        //     setform({...form,feature:fltr})
                        //   }
                        // }}
                        />
                      </label>

                    </div>
                  )
                })
              }
            </div>


          </div>
          <div className="text-right">
            <Link to="/subscription/plans" className="btn btn-secondary discard mr-2">Back</Link>
            <button type="submit" className="btn btn-primary">Save</button>
          </div>
        </div>
      </form>
    </Layout>
  </>
}

export default AddEditPlan