import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ApiClient from "../../methods/api/apiClient";
import './style.css'
import loader from "../../methods/loader";
import Layout from "../../components/global/layout";

export default function zoomVideo() {
  const user = useSelector(state => state.user)
  const Queryparams = new URLSearchParams(window.location.search);
  const code = Queryparams.get(`code`);
  const userid = Queryparams.get('userid');
  const [URL, setURL] = useState("");
  //  We need to make this function for Getting the access_token
  const GetToken = (accesscode) => {
    loader(true);
    ApiClient.get(`generate/token?code=${accesscode}`).then(res => {
      const data = res?.data;
      CreateMeeting(data?.access_token);
      loader(false);
    })
  }

  //  THis Function we need for Creating Meeting 
  const CreateMeeting = (token) => {
    const meetingwithid = localStorage.getItem("meetingwith");
    loader(true);
    const Payload = {
      "token":token,
      "type":1,
      "topic":"Appointment meeting",
      "description":"This meeting is related to Therapy",
      "sendToLinkId":meetingwithid
    }
    ApiClient.post(`create/meeting`, Payload).then(res => {
      if (res.success) {
        const data = res.data;
        setURL(data.meeting_link);
        const WithRoomid = localStorage.getItem("RoomidWithCall");
        const WithChatid = localStorage.getItem("meetingwith");
        const WithProjectId = localStorage.getItem("ProjectWithId");

        const AnotherMessagePayload = {
          "room_id": WithRoomid,
          "type": "TEXT",
          "sender": user.id,
          "content": `${data.join_url}`,
          "message_type": "ZoomMeeting",
          "project_id": WithProjectId,
        }
        // ConnectSocket.emit(`send-message`,AnotherMessagePayload)
      }
    })
  }

  //  For Calling this Function when the Page Mount and If Code exits in Query Params
  useEffect(() => {
    if (code) { GetToken(code) }
  }, [])
  return (
    <div>
      <Layout>
        <div className='backcls'>
          <div className='main12'>
            <div className="">
              <button className={`btn btn-primary ${URL == "" ? "" : ""}`} title={URL == "" ? "Redirection is not valid" : "start call"}  ><a target='_parent' className="text-white" href={URL == "" ? "#" : URL} > Join Meeting</a> </button>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}