import { io } from "socket.io-client";

let SocketURL="https://chat.incogtherapy.com/"
// const SocketURL="https://virtualchat.jcsoftwaresolution.in/"
let ConnectSocket=io('https://therapychat.jcsoftwaresolution.in/');

if(window.location.protocol!='https:'){
    SocketURL="https://chat.incogtherapy.com/"
    ConnectSocket=io('https://chat.incogtherapy.com/');
}

ConnectSocket.connect();

export {SocketURL,ConnectSocket};
