import React, { useEffect, useState } from 'react'
import Layout from '../../components/global/layout'
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import ApiClient from '../../methods/api/apiClient'
import dateFormat from 'dateformat'
import { toast } from 'react-toastify'
import { ToastsStore } from 'react-toasts'
import methodModel from '../../methods/methods'

function ViewAppointment() {
  const { id } = useParams()
  const [form, setform] = useState({})
  const Queryparams = new URLSearchParams(window.location.search);
  const reId = Queryparams.get(`reId`);
  const [data, setdata] = useState([])
  const [loader, setloader] = useState(true)
  const [time, setTime] = useState('15-12-2023,11')
  const [resheduled, setResheduleddata] = useState({})

  const HandleSubmit = (e) => {
    e.preventDefault()
    ApiClient.post('reschedule', form).then((res) => {
      if (res?.success) {
        ToastsStore.success(res.message)
        document.getElementById('CloseModel').click()
        setform({ date: '', time: '' })
        ApiClient.put('appointment', { id: form?.appointment_id, is_rescheduled: true }).then((res) => {
          console.log("is_rescheduled is true")
        })


      } else {
        document.getElementById('CloseModel').click()
        setform({ date: '', time: '' })
      }
    })
  }

  const GetResheduleddata = () => {
    setloader(true)
    ApiClient.get('reschedule', { id: reId }).then((res) => {
      if (res?.success) {
        setResheduleddata(res?.data)
        let AppointDate = dateFormat(res?.data?.date, "dd-mm-yyyy")
        let AppointHr = parseInt(res?.data?.time?.split("-")[0])
        let finalTime = AppointDate + "," + AppointHr
        // console.log(finalTime)
        // setTime(finalTime)
        console.log(time)
        // let date = dateFormat(res?.data?.date,"dd-mm-yyyy")
        // let ExractTime = parseInt(res?.data?.time?.split("-"))
        // let JoinDate = date+","+ExractTime
        // setTime(dateFormat(JoinDate,"dd-mm-yyyy,HH"))
        // console.log(time)

      }
    })
    setTimeout(() => {
      setloader(false)
    }, 500);
  }
  console.log(resheduled)
  const Getdata = () => {
    setloader(true)
    ApiClient.get('appointment', { id: id }).then((res) => {
      if (res?.success) {
        setdata(res?.data)
        let AppointDate = dateFormat(res?.data?.date, "dd-mm-yyyy")
        let AppointHr = parseInt(res?.data?.time?.split("-")[0])
        let finalTime = AppointDate + "," + AppointHr
        // console.log(finalTime)
        // setTime(finalTime)
        console.log(time)
        // let date = dateFormat(res?.data?.date,"dd-mm-yyyy")
        // let ExractTime = parseInt(res?.data?.time?.split("-"))
        // let JoinDate = date+","+ExractTime
        // setTime(dateFormat(JoinDate,"dd-mm-yyyy,HH"))
        // console.log(time)
        setloader(false)
      }else{
        setloader(false)
      }
    })
    // setTimeout(() => {
    
    // }, 2000);
  }

  useEffect(() => {
    if (reId) {
      GetResheduleddata()
    }
    Getdata()
  }, [])

  let CurrentTime = dateFormat(Date.now(), "dd-mm-yyyy,HH")


  const ChangeStatus = (id, status) => {
    if (window.confirm(`Do you want to ${status == 'accept' ? 'accept' : 'reject'}`)) {
      setloader(true)
      ApiClient.put('appointment', { id: id, status: status }).then((res) => {
        if (res.success) {
          Getdata()
          ToastsStore.success(res.message)
          setloader(false)
        }
      })
    }
  }
  const ChangeAppointStatus = (id, status) => {

    if (window.confirm(`Are you sure that this Appointment is ${status == 'done' ? 'Done' : 'Missed'}`)) {
      setloader(true)

      ApiClient.put('appointment', { id: id, appointment_status: status }).then((res) => {
        if (res.success) {
          Getdata()
          ToastsStore.success(res.message)
          setloader(false)
        }
      })
    }
  }
  return (
    <Layout>
      <div className='text-right mb-3'><Link to={`${reId ? "/appointment/reschduled" : "/appointment"}`}><i className="fa fa-arrow-left" title='Back' aria-hidden="true"></i></Link></div>
      <div className=" pprofile1">
     {!loader && data?.id ? <div className='d-flex justify-content-between bottomborder align-items-center'>
          <h3 className='heading_border'>View  Appoinment</h3>
          {
            data?.status == 'pending' ?
              <div className='btn_ace_reec'>
                <button className='btn btn-primary mr-3' onClick={() => {
                  ChangeStatus(data?.id, 'accept')
                }}>Accept</button>
                <button className='btn btn-primary rejected' onClick={() => {
                  ChangeStatus(data?.id, 'reject')
                }}>Reject</button>
              </div> : <>
              {/* dateFormat(data.date, "dd-mmm-yyyy") !== dateFormat(Date.now(), "dd-mmm-yyyy") || dateFormat(data.date, "dd-mmm-yyyy") < dateFormat(Date.now(), "dd-mmm-yyyy") || */}
              {/* !dateFormat(Date.now(), "dd-mmm-yyyy") > dateFormat(data?.date, "dd-mmm-yyyy") */}
                {
                 data.appointment_status == 'done' || data.appointment_status == 'missed' || data.appointment_status == 'terminated' ? null :
                    <div className='btn_ace_reec'>
                      <button className='btn btn-primary mr-3' onClick={() => {
                        ChangeAppointStatus(data?.id, 'done')
                      }}>Completed</button>
                      <button className='btn btn-primary rejected' onClick={() => {
                        ChangeAppointStatus(data?.id, 'missed')
                      }}>Missed</button>
                    </div>
                }
                {
                  data?.appointment_status == 'missed' && !reId ? <div className='btn_ace_reec'>
                    <button className='btn btn-primary mr-3' onClick={() => {
                      document.getElementById('OpenModel').click()
                      setform({ ...form, appointment_id: data?.id, user_id: data?.user_id })
                    }}>Reschedule</button>

                  </div> : null
                }
                {
                  !data?.meeting_status && !data?.appointment_status == 'done' ? <div className='btn_ace_reec'>
                    <button className='btn btn-primary mr-3' onClick={() => {
                      ChangeAppointStatus(data?.id, 'done')
                    }}>Completed</button>
                    {/* <button className='btn btn-primary rejected' onClick={() => {
    ChangeAppointStatus(data?.id, 'missed')
  }}>Missed</button> */}
                  </div> : null
                }
              </>

          }
          {/* {
                  data?.meeting_status == 'pending' ? <div className='btn_ace_reec'>
                    <button className='btn btn-primary mr-3' onClick={() => {
                      ChangeAppointStatus(data?.id, 'done')
                    }}>Completed</button>
                    <button className='btn btn-primary rejected' onClick={() => {
                      ChangeAppointStatus(data?.id, 'missed')
                    }}>Missed</button>
                  </div> : null
                } */}
        </div>:
        null}

        {
          loader ? <div className="text-center py-4">
            <img src="/assets/img/loader.gif" className="pageLoader" />
          </div> :

            <div className='table_admin'>
              <div className='row mb-3'>
                <div className='col-12 col-sm-12 col-md-3'>
                  <h5 className='headmain'>Name</h5>
                </div>
                <div className='col-12 col-sm-12 col-md-9'>
                  <h6 className='subhead'>{methodModel.capitalizeName(data?.name)}</h6>
                </div>
              </div>

              <div className='row mb-3'>
                <div className='col-12 col-sm-12 col-md-3'>
                  <h5 className='headmain'>Email</h5>
                </div>
                <div className='col-12 col-sm-12 col-md-9'>
                  <h6 className='subhead'>{data?.email}</h6>
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-12 col-sm-12 col-md-3'>
                  <h5 className='headmain'>Phone</h5>
                </div>
                <div className='col-12 col-sm-12 col-md-9'>
                  <h6 className='subhead'>{data?.phone || "--"}</h6>
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-12 col-sm-12 col-md-3'>
                  <h5 className='headmain'>Appointment Date</h5>
                </div>
                <div className='col-12 col-sm-12 col-md-9'>
                  <h6 className='subhead'>{dateFormat(data?.date, 'dd-mmm-yyyy ')}</h6>
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-12 col-sm-12 col-md-3'>
                  <h5 className='headmain'>Appointment Time</h5>
                </div>
                <div className='col-12 col-sm-12 col-md-9'>
                  <h6 className='subhead'>{data?.time}</h6>
                </div>
              </div>

              {
                data?.appointment_status ?
                  <div className='row mb-3'>
                    <div className='col-12 col-sm-12 col-md-3'>
                      <h5 className='headmain'>Appointment Status</h5>
                    </div>
                    <div className={`col-12 col-sm-12 col-md-9 `}>
                      <h6 className={` ${data?.appointment_status + 2} `}>{methodModel.capitalizeName(data?.appointment_status)}</h6>
                    </div>
                  </div> : null
              }
              {
                data?.reason ?
                  <div className='row mb-3'>
                    <div className='col-12 col-sm-12 col-md-3'>
                      <h5 className='headmain'>Reason</h5>
                    </div>
                    <div className='col-12 col-sm-12 col-md-9'>
                      <h6 className='subhead '>{data?.reason}</h6>
                    </div>
                  </div> : null
              }


              <div className='row mb-3'>
                <div className='col-12 col-sm-12 col-md-3'>
                  <h5 className='headmain'>Status</h5>
                </div>
                <div className='col-12 col-sm-12 col-md-9'>
                  <h6 style={{ color: 'black' }} className={`subhead  ${data?.status + 1}`}>{methodModel.capitalizeName(data?.status)}</h6>
                </div>
              </div>

              <div className='row mb-3'>
                <div className='col-12 col-sm-12 col-md-3'>
                  <h5 className='headmain'>Description</h5>
                </div>
                <div className='col-12 col-sm-12 col-md-9'>
                  <h6 className='subhead'>{data?.description}</h6>
                </div>
              </div>
            </div>}
        {resheduled && reId ? <> <h3 className='heading_border'>Resheduled Appointment Detail</h3>
          <div className='row mb-3'>
            <div className='col-12 col-sm-12 col-md-3'>
              <h5 className='headmain'>Meeting Status</h5>
            </div>
            <div className='col-12 col-sm-12 col-md-9'>
              <h6 className='subhead'>{resheduled?.meeting_status}</h6>
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-12 col-sm-12 col-md-3'>
              <h5 className='headmain'>Appointment Date</h5>
            </div>
            <div className='col-12 col-sm-12 col-md-9'>
              <h6 className='subhead'>{dateFormat(resheduled?.date, 'dd-mmm-yyyy ')}</h6>
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-12 col-sm-12 col-md-3'>
              <h5 className='headmain'>Appointment Time</h5>
            </div>
            <div className='col-12 col-sm-12 col-md-9'>
              <h6 className='subhead'>{resheduled?.time}</h6>
            </div>
          </div></> : null}
      </div>
      <button type="button" id='OpenModel' class="btn btn-primary d-none" data-toggle="modal" data-target="#exampleModal">
        Launch demo modal
      </button>


      <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <form onSubmit={HandleSubmit}>


              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Reschedule Appointment</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">

                <div className="col-md-12 mb-3">
                  <label>
                    Select Date<span className="star">*</span>
                  </label>
                  <input
                    type="date"
                    min={new Date().toISOString().split('T')[0]}
                    className="form-control"
                    value={form.date}
                    onChange={(e) => setform({ ...form, date: e.target.value })}
                    required
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <label>Time Slot<span className="star">*</span></label>
                  <select className="form-control"
                    value={form.time}

                    onChange={e => { setform({ ...form, time: e.target.value, }); }}

                    required
                  >
                    <option value="">Select Time</option>
                    <option value="9-10 AM">9-10 AM</option>
                    <option value="10-11 AM">10-11 AM</option>
                    <option value="11-12 PM">11-12 PM</option>
                    <option value="13-14 PM">13-14 PM</option>
                    <option value="14-15 PM">14-15 PM</option>
                    <option value="15-16 PM">15-16 PM</option>

                    <option value="17-18 PM">17-18 PM</option>
                    <option value="18-19 PM">18-19 PM</option>
                    <option value="19-20 PM">19-20 PM</option>
                    <option value="20-21 PM">20-21 PM</option>
                    <option value="21-22 PM">21-22 PM</option>
                    <option value="22-23 PM">22-23 PM</option>
                    <option value="23-24 AM">23-24 AM</option>
                  </select>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" id='CloseModel' class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="submit" class="btn btn-primary">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ViewAppointment
